<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close :title="type == 1?'批量创建':'批量选择'" v-model="dialogVisible" width="25%" append-to-body>

    <subject-list v-if="type == 2" v-model:subjectId="temp.subjectId" v-model:fzhsItemId="temp.fzhsId" :codes="$findCode(codes)"></subject-list>

    <subject-list-all v-if="type == 1" v-model:subjectId="temp.subjectId" :codes="$findCode(codes)"></subject-list-all>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button @click="cancel" size="small" v-if="fixed == 'fixed'">暂不创建</el-button>
        <qzf-button :loading="loading" type="primary" @success="handleAddSure" size="small">确 定</qzf-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { bcCreateCorpAndSubject } from "@/api/invoice.js"

export default {
  data () {
    return {
      loading: false,
      dialogVisible: false,
      type: 1,//1 批量创建 2批量选择
      temp: {
        subjectId: 0,
        fzhsId: 0,
      },
      codes: [],
      form: {},
      fixed:''
    }
  },
  methods: {
    // items 包含 corpName 发票id  type 1 批量创建 2批量选择  nature 1客户 2供应商
    init(items, type, codes, nature,fixed){
      this.form = {
        items: items,
      }
      this.temp.subjectId = 0
      this.temp.fzhsId = 0
      this.type = type
      this.codes = codes
      if(fixed){
        this.fixed = fixed
      }else{
        this.fixed = ''
      }
      if(type == 1){//批量创建 默认选上科目
        let list = this.$subjectFilterCode([], 2)
        let code = this.$findCode(['code1122'])
        if(nature == 2){
          code = this.$findCode(['code2202'])
        }
        let subject = []
        if(code && code.length != 0){
          subject = list.filter(v=>{return code[0] == v.code})
        }
        if(subject.length != 0){
          this.temp.subjectId = subject[0].id
        }
      }

      this.dialogVisible = true
    },
    handleAddSure(){
      if(this.temp.subjectId == 0){
        this.$qzfMessage("请选择科目", 1)
        return
      }
      if(this.type == 2){//批量选择的不需要调用接口
        let callList = this.form.items.map(v=>{
          return {
            id: v.id,
            subjectId: this.temp.subjectId,
            fzhsId: this.temp.fzhsId,
          }
        })
        this.$emit("success", callList)
        this.dialogVisible = false
        return
      }
      this.form.subjectId = this.temp.subjectId
      this.form.fzhsId = this.temp.fzhsId
      this.loading = true
      bcCreateCorpAndSubject(this.form).then(res=>{
        this.loading = false
        this.dialogVisible = false
        if(res.data.msg != "success"){
          return
        }
        this.$store.dispatch("commons/getSubjects")
        this.$store.dispatch("commons/getBusinessType")
        this.$emit("success", res.data.data.list)
      })
      // this.$emit("success", this.temp)
      // this.dialogVisible = false
    },
    cancel(){
      this.dialogVisible = false
      this.$emit("cancel")
    }
  }
}
</script>