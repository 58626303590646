
<template>
  <div style="position: relative;" v-loading="loading1">
    <div style="position:absolute;right:17px;z-index:999;top:3px">
      <qzf-video vid="63dd60f5887e3ef401e21c90442b63f4_6"></qzf-video>
      <el-button type="text" @click="sumsAlert" :disabled="!$buttonStatus('fp_jx_cktj')">点击查看统计</el-button>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="全部发票" :name="3"></el-tab-pane>
      <el-tab-pane label="已补充" :name="1"></el-tab-pane>
      <el-tab-pane label="未补充" :name="2"></el-tab-pane>
      <el-tab-pane label="发票仓库" :name="4"></el-tab-pane>
      <el-tab-pane label="未认证发票" :name="5">
        <incomeWzz ref="incomeWzz"/>
      </el-tab-pane>
      <div v-if="activeName == 1 || activeName == 2 || activeName == 3 || activeName == 4">

    
      <div class="fl">
        <el-select @change="invoiceTypeChange" filterable v-model="listQuery.invoiceType" placeholder="请选择发票类型" size="small" style="width:150px;margin-left:0" clearable multiple collapse-tags class="selects">
          <el-option
            v-for="item in optionsIn"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input v-model="listQuery.fphm" placeholder="请输入销方名称/发票号码" @keyup.enter="getList" style="width:150px" size="small" clearable></el-input>
        <el-button style="margin-right: 5px;" size="small" @click="getList" type="primary">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="width:605px" class="styleForm" label-width="85px" :inline="true" size="small">
            <el-form-item label="开票日期：">
              <el-date-picker
                v-model="listQuery.rq"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width:185px"
              />
            </el-form-item>
            <el-form-item label="税率：" prop="taxId">
              <selectTax v-model:taxRate="listQuery.taxRate" style="width:185px;margin:0"></selectTax>
            </el-form-item>
            <el-form-item label="商品编码：" prop="taxId">
              <ssflbm v-model:ssflbm="listQuery.ssflbm" style="width:185px;margin:0"></ssflbm>
            </el-form-item>
            <el-form-item label="发票来源：">
              <el-select v-model="listQuery.upload" placeholder="请选择来源" style="width:185px;margin:0">
                <el-option v-for="item in fromOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="单据标注：">
              <el-select v-model="listQuery.autoLabel" placeholder="请选择单据标注" style="width:185px;margin:0" filterable clearable>
                <el-option v-for="item in labelOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标注：">
              <el-select v-model="listQuery.hasAutoLabel" placeholder="请选择标注" style="width:185px;margin:0" filterable>
                <el-option v-for="item in bzOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="费用报销：">
              <el-input v-model="listQuery.autoCorpLabel" placeholder="请输入费用报销人"  style="width:185px" clearable></el-input>
            </el-form-item>
            <el-form-item label="到票状态：">
              <el-radio-group v-model="listQuery.dpzt">
                <el-radio :label="2">全部</el-radio>
                <el-radio :label="0">已到票</el-radio>
                <el-radio :label="1">未到票</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="凭证状态：" v-if="!this.listQuery.ware">
              <el-radio-group v-model="listQuery.voucher">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已生成</el-radio>
                <el-radio :label="2">未生成</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="结算状态：">
              <el-radio-group v-model="listQuery.js">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已结算</el-radio>
                <el-radio :label="2">未结算</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="认证状态：" v-if="!(listQuery.invoiceType.length == 1 && (listQuery.invoiceType.includes('2')  || listQuery.invoiceType.includes('5'))) && !(listQuery.invoiceType.length == 2 && (listQuery.invoiceType.includes('2')  && listQuery.invoiceType.includes('5')))">
              <el-radio-group v-model="listQuery.rzzt">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已认证</el-radio>
                <el-radio :label="2">未认证</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="正负发票：">
              <el-radio-group v-model="listQuery.negative">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">正数发票</el-radio>
                <el-radio :label="2">负数发票</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="开票项目：">
              <el-input v-model="listQuery.name" placeholder="请输入开票项目"  style="width:185px;margin:0"></el-input>
            </el-form-item>
            <el-form-item label="补充项目：">
              <el-input v-model="listQuery.itemName" placeholder="请输入补充项目"  style="width:185px;margin:0"></el-input>
            </el-form-item>
            <el-form-item label="凭证号：">
              <el-input v-model="listQuery.voucherNo" placeholder="请输入凭证号"  style="width:185px;margin:0"></el-input>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input v-model="listQuery.remark" placeholder="请输入备注"  style="width:185px;margin:0"></el-input>
            </el-form-item>
          </el-form>
        </search>
        <span style="margin-left: 6px;color: red;font-size: 12px;" v-if="activeName == '4'">温馨提示：本栏目用于存储暂不入账的票据</span>
      </div>
      <qzf-button button_code="fp_jx_plsc" size="small" type="danger" @success="delALLInvoiceWare()" class="fr" style="margin-left:10px"  v-if="this.listQuery.ware" :loading="loading"  plain >
          <el-icon><Delete /></el-icon><span  > 删除</span>
        </qzf-button>
      <div class="fr" v-if="this.listQuery.ware" style="margin-bottom:10px">
        <qzf-button size="small" type="primary" icon="DArrowLeft" @success="moveIn" plain   button_code="fp_jx_zydjxfp"
        >转移到进项发票</qzf-button
      >
      </div>
      
      <div class="fr" style="margin-bottom:10px" v-if="!this.listQuery.ware">
        <span v-if="autoBcStatus"
            style="
              font-size: 13px;
              color:#e6a23c;
              margin-right: 5px;
            "
            ><i class="iconfont icon-sanjiaotanhao" style="color:#e6a23c;margin-right:4px"></i>{{this.autoBcType}}发票自动补充中！</span
          >
        <el-button v-if="$buttonStatus('fp_jx_ai_bc')" :disabled="autoBcStatus" :loading="this.buttonLoading" type="success" size="small" @click="inSetting" style="margin-right:5px"><img style="margin-left:-7px;margin-top:2px;width:21px;height:14px;margin-right:3px" src="@/assets/ai3d.png" alt=""> 自动补充</el-button>
        <el-dropdown split-button type="primary" @click="addIncome" size="small"
          style="" :hide-on-click="false" v-if="$buttonStatus('fp_jx_tj')">
          <i class="iconfont icon-jiahao"></i> 添加
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <qzf-button size="small" text @success="openCom">进项导入</qzf-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <qzf-button size="small" text @success="collect">单张采集</qzf-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        
        <el-dropdown size="small" style="margin:0 5px;" :hide-on-click="false">
          <el-button size="small" type="primary" plain>
            批量修改<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu style="text-align:center">
              <el-dropdown-item command="c" @click="batchSetting" v-if="$buttonStatus('fp_jx_plszwrz')">
                <span >改为未认证</span>
              </el-dropdown-item>
              <el-dropdown-item command="k" @click="batchSettingYrz" v-if="$buttonStatus('fp_jx_plszwyrz')">
                <span >改为已认证</span>
              </el-dropdown-item>
              <el-dropdown-item command="d" @click="batchDrawback" v-if="$buttonStatus('fp_jx_plsfts')">
                <span >设置是否退税</span>
              </el-dropdown-item>

              <el-dropdown-item command="h" @click="batchInvoiceType" v-if="$buttonStatus('fp_jx_plxgpmlx')">
                <span >修改发票类型</span>
              </el-dropdown-item>
              <el-dropdown-item command="i" @click="invoiceSort()" v-if="$buttonStatus('fp_jx_fpjlpx')">
                <span >修改发票顺序</span>
              </el-dropdown-item>
              <el-dropdown-item @click="clearZk()" v-if="$buttonStatus('fp_jx_qczkxx')">
                <span >清除折扣信息</span>
              </el-dropdown-item>    
               <el-dropdown-item command="z" @click="clearInfo" v-if="$buttonStatus('fp_jx_qcfpbcxx')">
                <span >清除补充信息</span>
              </el-dropdown-item>
              <el-dropdown-item @click="editDpStatus()" v-if="$buttonStatus('fp_jx_plxgdpzt')">
                <span >修改到票状态</span>
              </el-dropdown-item>
              <el-dropdown-item @click="editDkStatus()" v-if="$buttonStatus('fp_jx_plxgdpzt')">
                <span >修改抵扣状态</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button button_code="fp_jx_plbc" type="primary" @success="handleBuchongstatus()" size="small" :disabled="autoBcStatus">
          <el-icon><Memo /></el-icon><span  > 批量补充</span>
        </qzf-button>
        <el-button size="small" type="primary" plain @click="handleCommand" :disabled="!$buttonStatus('fp_jx_yjscpz')" class="operation_btn">生成凭证</el-button>
        <qzf-button button_code="fp_jx_plsc" size="small" type="danger" @success="delALL()" :loading="loading"  plain class="operation_btn">
          <el-icon><Delete /></el-icon><span  > 删除</span>
        </qzf-button>
        <el-dropdown size="small" style="margin-left:5px;" :hide-on-click="false">
          <el-button size="small" type="primary" plain>
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu style="text-align:center">
              <el-dropdown-item command="b" @click="bqfp" v-if="$buttonStatus('fp_jx_bqfp')">
                <span >补全明细</span>
              </el-dropdown-item>
              <el-dropdown-item command="f" @click="singleDayin()" v-if="$buttonStatus('fp_jx_dycgmx')">
                <span >打印采购明细</span>
              </el-dropdown-item>
              <el-dropdown-item command="w" @click="moveWare()" v-if="$buttonStatus('fp_jx_zydfpck')">
                <span >转移到发票仓库</span>
              </el-dropdown-item>
              <el-dropdown-item @click="batchSettle()" v-if="$buttonStatus('fp_jx_pljs')">
                <span >批量结算</span>
              </el-dropdown-item>
              <el-dropdown-item command="z" @click="batchDownload()" v-if="$buttonStatus('fp_jx_zydfpck')">
                <span >批量下载发票影像</span>
              </el-dropdown-item>
              <el-dropdown-item command="e" @click="exportIncome" v-if="$buttonStatus('fp_jx_dc')">
                <span >导出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="top_title clearfix">
        <div class="top-nav right_box fl">
          <el-row>
          <el-col :span="3">
            <div style="display: flex;align-items: center;padding-left:10px;">
              <input type="checkbox" :checked="isAllSelected" :style="{marginLeft:0}" @click="allSelect"/>
              <div class="box-content" style="border-left:none;width: 80%;">业务类型</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="box-content">开票项目</div>
          </el-col>
          <el-col :span="6">
            <div class="box-content">补充项目</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">数量</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">金额  <el-icon class="opera_icon" @click="sortChange"><DCaret /></el-icon></div>
          </el-col>
          <el-col :span="1">
            <div class="box-content">税率</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">税额</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">价税合计</div>
          </el-col>
          </el-row>
        </div>
      </div>

      <div v-loading="loadingBig"> 
        <el-scrollbar :style="{height:contentStyleObj}" id="tableLazyLoadIn"> 
        <el-empty :image-size="150" v-if="this.list.length == 0"></el-empty>
        <div class="content_center clearfix" style="margin-bottom:10px;" v-for="(item,index) in list" :key="index">
          <div class="content-nav right_box fl">
            <el-row class="content-top">
              <el-col :span="24" class="box-content clearfix">
                <ul class="top_font clearfix">
                  <li style="padding-left: 10px;" @click="singleSelect(item,index)">
                    <input type="checkbox" :checked="item.checked" :style="{marginLeft:0,marginTop:'10px'}"/>
                  </li>
                  <li style="width:42px" v-if="item.invoiceType  != '2' && item.invoiceType  != '5'">
                    <span style="color:#008000" v-if="item.rzzt == 1">已认证</span>
                    <span style="color:#e6a23c" v-else>未认证</span>
                  </li>
                  <li style="width:75px;padding-left: 0;">
                    <span style="color:green" v-if="item.invoiceType == '1'">（专）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '2'">（普）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '3'">（农(9%)）</span>
                    <span style="color:green" v-else-if="item.invoiceType == '4'">（电专）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '5'">（电普）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '6'">（海关）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '7'">（其他）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '8'">（农(10%)）</span>
                    <span style="color:green" v-else-if="item.invoiceType == '11'">（费用）</span>
                  </li>
                  <el-tooltip effect="dark" :content="item.fphm" placement="top" :disabled="!item.fphm">
                    <li style="width:215px;" class="ccyc">发票号码:
                      <span style="color:green" v-if="item.invoiceType == '1' || item.invoiceType == '4'">{{item.fphm}}</span>
                      <span style="color:#d56400" v-if="item.invoiceType == '2' || item.invoiceType == '5'">{{item.fphm}}</span>
                      <span style="color:#d56400" v-if="item.invoiceType == '3' || item.invoiceType == '6' || item.invoiceType == '7' || item.invoiceType == '8'">{{item.fphm}}</span>
                    </li>
                  </el-tooltip>
                  
                  <el-tooltip effect="dark" :content="item.corpName" placement="top" :disabled="!item.corpName">
                    <li style="width:220px" class="ccyc">销方:
                      <span>{{item.corpName}}</span>
                    </li>
                  </el-tooltip>
                  <li style="width:136px">开票日期:
                    <span>{{$parseTime(item.invoiceDate, "{y}-{m}-{d}")}}</span>
                  </li>
                  <li style="width:100px" class="ccyc">结算状态:
                    <span v-if="item.settlementStatus == 1" style="color:green">已结算</span>
                    <span v-else style="color:red">未结算</span>
                  </li>
                  <li style="width:92px" v-if="this.listQuery.ware">账期:
                    <span> {{ item.period }}</span>
                  </li>
                  <li style="width:85px" v-if="!this.listQuery.ware">凭证:
                    <span style="color:var(--themeColor,#17a2b8);text-decoration:underline;cursor:pointer;font-weight:600" v-if="item.voucherNo" @click="getHandlePingzheng(item.voucherId)">{{item.voucherNo}}</span>
                    <span style="color:var(--themeColor,#17a2b8);cursor:pointer;" v-else-if="$buttonStatus('fp_jx_yjscpz')" @click="showVoucher(item)" >凭证预览</span>
                  </li>
                  <li style="width:94px" v-if="!this.listQuery.ware">到票状态:
                    <el-switch v-model="item.dpzt" size="small" :active-value="0" :inactive-value="1" @change="changeDpzt(item)"/>
                  </li>
                  <li v-if="item.autoLabel || item.autoOtherName">
                    <el-tooltip effect="dark" placement="top">
                      <template #content> 
                        <p>标签：{{ item.autoLabel }}</p>
                        <p>报销方名称：{{ item.autoOtherName }}</p>
                      </template>
                     <span class="xgmStyle" style="font-size:11px;cursor: pointer;padding:0px">标注</span>
                    </el-tooltip>
                  </li>
                </ul>
                <div class="top_right_btns" v-if="!this.listQuery.ware">
                  <span v-if="item.upload == 3" class="ybStyle" style="font-size:11px;padding:0px;position: relative;top:-2px">采</span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="top"
                    v-if="item.remark"
                  >
                  <template #content> 采集备注：<span v-html="item.remark"></span></template>

                  <span class="xgmStyle" style="font-size:11px;cursor: pointer;padding:0px;position: relative;top:-2px">备注</span>
                  </el-tooltip>
                 <!--  <el-tooltip content="预览发票" placement="top" effect="dark" v-if="item.invoiceType != 1 && item.invoiceType != 2">
                    <el-button type="text" size="small" style="padding:0px;padding-bottom: 6px;" :disabled="!item.ctpImg || !item.ctpImg.match('/mnt')" @click="checkImg(item)">
                      <i class="iconfont icon-picture" style="color: rgb(13, 197, 13);font-size: 16px;" v-if="item.ctpImg && item.ctpImg.match('/mnt')"></i>
                      <i class="iconfont icon-picture" style="font-size: 16px;" v-else></i>
                    </el-button>
                  </el-tooltip> -->
                  <el-tooltip placement="top" effect="dark" v-if="$buttonStatus('fp_jx_ckfp') && item.ctpPdf">
                    <template #content> {{ item.ctpPdf && !item.pdfStatus ? '已封存，请前往发票影像恢复' : '查看发票影像' }}</template>
                    <el-button type="text" size="small" style="padding:0px;padding-bottom:5px;margin-left: 0;" :disabled="item.ctpPdf && !item.pdfStatus" @click="checkCollectInvoice(item)">
                      <i class="iconfont icon-pdf" style="color: rgb(13, 197, 13);font-size: 16px;" v-if="item.ctpPdf && item.pdfStatus"></i>
                      <i class="iconfont icon-pdf" style="color:#999;font-size: 16px;" v-else></i>
                    </el-button>
                  </el-tooltip>
                 <!--  <el-tooltip content="查看发票影像" placement="top" effect="dark" v-if="$buttonStatus('fp_jx_ckfp')">
                    <el-button type="text" size="small" style="padding:0px;padding-bottom:5px;margin-left: 0;" :disabled="!item.image || item.image.match('error')" @click="checkCollectInvoice(item)">
                      <i class="iconfont icon-pdf" style="color: rgb(13, 197, 13);font-size: 16px;" v-if="item.image && !item.image.match('error')"></i>
                      <i class="iconfont icon-pdf" style="font-size: 16px;" v-else></i>
                    </el-button>
                  </el-tooltip> -->
                  <el-tooltip content="编辑" placement="top" v-if="$buttonStatus('fp_jx_bj')" effect="dark">
                    <i @click="goInfo(item)" class="iconfont icon-bianji" style="color:var(--themeColor,#17a2b8)"></i>
                  </el-tooltip>
                  <el-tooltip content="删除" placement="top" v-if="$buttonStatus('fp_jx_plsc')" effect="dark">
                    <i @click="delInvoice(item)" class="iconfont icon-shanchu" style="color:var(--themeColor,#17a2b8)"></i>
                  </el-tooltip>
                </div>
                <div class="top_right_btns" v-else>
                  <el-tooltip content="删除" placement="top" effect="dark">
                    <i @click="delInvoiceWare(item)" class="iconfont icon-shanchu" style="color:var(--themeColor,#17a2b8)"></i>
                  </el-tooltip>
                </div>
              </el-col>
            </el-row>
            <div class="center_big clearfix">
              <el-button class="fold_icon" v-if="item.items?.length > 4 && !item.showStatus" @click="item.showStatus = true" size="small">展开全部 <el-icon><ArrowDown /></el-icon></el-button>
              <el-button class="fold_icon" v-if="item.showStatus" @click="item.showStatus = false" size="small">收起全部 <el-icon><ArrowUp /></el-icon></el-button>
              <div class="content" v-for="(itemw,index2) in item.items" :key="index2">
                <el-row class="for-content" v-if="(index2 < 4 && !item.showStatus) || item.showStatus">
                  <el-col :span="3">
                    <div class="box-content">{{itemw.businessName}}</div>
                  </el-col>
                  <el-col :span="6">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <template #content>
                        <div>{{itemw.name}}{{ itemw.remark?'('+itemw.remark+')' : '' }}</div>
                      </template>
                      <div class="box-content ccyc" style="text-align: left;">{{itemw.name}}{{ itemw.remark?'('+itemw.remark+')' : '' }}</div>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6">
                      <div class="box-content ccyc" style="text-align: left;">{{itemw.itemName}}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.count}}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.amount}}</div>
                  </el-col>
                  <el-col :span="1">
                    <div class="box-content">{{itemw.taxRate}}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.tax}}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.total}}</div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <el-row class="bottom-content ">
              <el-col :span="17">
                <div class="box-content">合计</div>
              </el-col>

              <el-col :span="2">
                <div class="box-content">{{item.sumAmount}}</div>
              </el-col>
              <el-col :span="1">
                <div class="box-content"></div>
              </el-col>
              <el-col :span="2">
                <div class="box-content">{{item.sumTax}}</div>
              </el-col>
              <el-col :span="2">
                <div class="box-content">{{item.sumTotal}}</div>
              </el-col>
            </el-row>
          </div>
        </div>
       </el-scrollbar>
         
      </div>
    <div class="bottom_style">
      <div class="bottom_button" style="float:left" v-if="this.selects.length != 0">
        <i class="iconfont icon-gantanhao" style="color:#e6a23c;margin-right:5px;font-size:13px"></i>统计： &nbsp;发票份数：{{ this.selects.length }}份 &nbsp;金额：{{ selectAmount }} &nbsp; 税额：{{ selectTax }} &nbsp;价税合计：{{ selectTaxAmount }}
      </div>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>
     </div>
    </el-tabs>

    <el-dialog :close-on-click-modal="false" destroy-on-close v-model="centerDialogVisible" title="提示" width="30%" center>
      <span>
        <selectInvoiceType v-model:invoiceType="batchInvoiceTypes" type="in"></selectInvoiceType>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="batchChangeInvoiceType" :loading="batchChangeInvoiceTypeLoading">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>

    <qzf-import-back :hideButton="true" ref="importBck" @success="getList()" from="进项"></qzf-import-back>
    <voucher-show ref="voucherShow" from="1" @success="getList"></voucher-show>
    <inbc @success="inbcSuccess" ref="inbc"></inbc>
    <collectSingle ref="collectSingle" @success="getList()" type="in"></collectSingle>
    <changeSortList ref="changeSortList" @success="changeSort"></changeSortList>
    <updateDpStatus ref="updateDpStatus" type="in" @success="getList()"/>
    <invoiceSettle ref="invoiceSettle" type="in" @success="getList()"/>
    <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogDkVisible" title="批量修改抵扣状态" width="400px">
     <el-form>
      <el-form-item label="抵扣状态">
        <el-radio-group v-model="dkType">
          <el-radio label="bqdk">本期抵扣</el-radio>
          <el-radio label="bydk">不予抵扣</el-radio>
        </el-radio-group>
      </el-form-item>
     </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDkVisible = false" size="small">取消</el-button>
          <el-button type="primary" @click="batchChangeDkStatus" :loading="dkLoading" size="small">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <dayinDetail ref="dayinDetail"/>
  <oneKeyVoucherHabbit ref="oneKeyVoucherHabbit" @success="getList"></oneKeyVoucherHabbit>
  <checkPdf ref="checkPdf"/>
  <checkImg ref="checkImg" />
  <autoSetting ref="autoSetting" @success="getNewList" @success2="changeBtnLoading"></autoSetting>
  <inStatistic ref="inStatistic" />
  <invoiceCtp ref="invoiceCtp" />
</template>

<script>
import ssflbm from '@/components/ssflbm/ssflbm.vue'
import selectTax from '@/components/Screening/selectTax.vue'
import inbc from './invoiceIn/inbc.vue' 
import selectInvoiceType from "./selectInvoiceType.vue"
import collectSingle from "./collectSingle.vue"
import changeSortList from "./components/changeSortList.vue"
import { inInvoiceList , delInInvoice ,inOutSum, invoiceCollectionBcIn ,saveWrz ,invoiceBcStatus ,invoiceDpzt ,inInvoiceMigrateWare ,clearInInvoice,voucherClearZk ,delInInvoiceWare ,getAutoBcStatus} from '@/api/invoice.js'
import updateDpStatus from './components/updateDpStatus.vue'
import invoiceSettle from './components/invoiceSettle.vue'
import { exportReceiptOfReceipts } from "@/api/export"
import {invoiceDownload} from '@/api/printSet'
import incomeWzz from './incomeWzz.vue'
import dayinDetail from './components/dayinDetail.vue'
import oneKeyVoucherHabbit from "../../bookkeeps/components/oneKeyVoucherHabbit.vue"
import checkPdf from './components/checkPdf.vue'
import checkImg from './components/checkImg.vue'
import autoSetting from '../../autoHome/components/autoSetting.vue'
import { IN_INVOICE_OPTION } from "@/utils/commonData"
import inStatistic from './components/inStatistic.vue'
import invoiceCtp from './components/invoiceCtp.vue'
export default {
  name:'income',
  components: {
    inbc,
    ssflbm,
    selectInvoiceType,
    selectTax,
    collectSingle,
    changeSortList,
    updateDpStatus,
    invoiceSettle,
    incomeWzz,
    dayinDetail,
    oneKeyVoucherHabbit,
    checkPdf,
    checkImg,
    autoSetting,
    inStatistic,
    invoiceCtp
  },
  data() {
    return {
      selects: [],
      listQuery: {
        page: 1,
        limit: 20,
        bc: 3,
        taxRate:1,
        rq:[],
        ssflbm:'',
        upload:0,
        voucher:0,
        js:0,
        fphm:'',
        corpName:'',
        ware:false,
        rzzt:0,
        dpzt:2,
        negative:0,
        voucherNo:'',
        hasAutoLabel:null,
        invoiceType: [],
      },
      activeName:3,
      list:[],
      total: 0,
      contentStyleObj:{}, //高度变化
      inputQuery: "",
      fromOptions:[
        {
          value:0,
          label:'全部'
        },
        {
          value:1,
          label:'手动添加'
        },
        {
          value:2,
          label:'导入'
        },
        {
          value:3,
          label:'采集'
        },
      ],
      labelOptions:[
        {
          value:'现金结算',
          label:'现金结算'
        },
        {
          value:'银行结算',
          label:'银行结算'
        },
        {
          value:'其他应付结算',
          label:'其他应付结算'
        },
        {
          value:'应付结算',
          label:'应付结算'
        }, {
          value:'预付结算',
          label:'预付结算'
        },
        {
          value:'固定资产',
          label:'固定资产'
        },
        {
          value:'成本',
          label:'成本'
        },
        {
          value:'费用',
          label:'费用'
        },
        {
          value:'原材料',
          label:'原材料'
        },
        {
          value:'工程物资',
          label:'工程物资'
        },
        {
          value:'库存商品',
          label:'库存商品'
        },
        {
          value:'费用报销',
          label:'费用报销'
        },
      ],
      bzOptions:[
        {
          value:0,
          label:'全部'
        },
        {
          value:1,
          label:'是'
        },
        {
          value:2,
          label:'否'
        },
      ],
      loading:false,
      loadingBig: false,
      loading1: false,
      centerDialogVisible:false,
      batchInvoiceTypes:'',
      invoiceTypeSelects2:[],
      dialogVisible:false,
      str:'',
      beginPeriod:'',
      endPeriod:'',
      batchChangeInvoiceTypeLoading:false,
      optionsIn:IN_INVOICE_OPTION,
      selectAmount:0,
      selectTax:0,
      selectTaxAmount:0,
      dialogDkVisible:false,
      dkLoading:false,
      dkType:"",
      totalPage:0,
      pageSize:30,
      tableData:[],
      currentPage:1,
      allChecked:false,
      comId: this.$store.getters["user/comInfo"].comId * 1,
      orgId: this.$store.getters["user/user"].orgId,
      buttonLoading:false,
      pageStatus: true,
      autoBcStatus:false,
      autoBcType:"",
      descStatus:false,
    }
  },
  computed: {
    // 是否全选
    isAllSelected() {
      if(this.list.length == 0){
        return false
      }
      return this.tableData.every(el => {
        return el.checked;
      });
    },
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created () {
    this.getList()
    this.init()
    this.contentStyleObj=this.$getHeight(317)
  },
  mounted(){
    this.initBus()
    this.initBcStatus()
  },
  methods:{
    initBcStatus(){
      this.$bus.off("autoBcStatusUpdate")
      this.$bus.on("autoBcStatusUpdate", (val) => {
        getAutoBcStatus({type:'in'}).then(res=>{
          this.autoBcStatus = res.data.data.autoBc
          this.autoBcType = res.data.data.autoBcType
        })
      });
    },
    initBus(){
      this.$bus.on("refreshTask", (val) => {
        if(!this.pageStatus){
          return
        }
        if(this.list.length == 0){
          return
        }
        if(this.autoBcStatus){
          getAutoBcStatus({type:'in'}).then(res => {
            this.autoBcStatus = res.data.data.autoBc
            this.autoBcType = res.data.data.autoBcType
            if(!this.autoBcStatus){
              this.$qzfMessage('自动补充完成！')
              this.$bus.emit('autoBcStatusUpdate')
              this.getNewList()
            }
          })
        }
      });
    },
    init(flag){
      if(!flag){
        invoiceBcStatus({type:'in'}).then(res => {
          if(res.data.data.status){
            this.$notify({
              title: '警告',
              message: '进项包含未补充完整发票',
              duration:5000,
              type: 'warning',
              offset: 0,
            });
          }
          if(res.data.data.cjStatus == 2){
            this.$notify({
              title: '警告',
              message: '进项本期未发起过采集',
              duration:5000,
              type: 'warning',
              offset: 75,
            });
          }else if(res.data.data.cjStatus == 3){
            this.$notify({
              title: '警告',
              message: '进项本期采集失败',
              duration:5000,
              type: 'warning',
              offset: 75,
            });
          }
        })
      }
      getAutoBcStatus({type:'in'}).then(res=>{
        this.autoBcStatus = res.data.data.autoBc
        this.autoBcType = res.data.data.autoBcType
      })
    },
    bqfp(){
      if(this.$checkSettleStatus()) return
      invoiceCollectionBcIn({}).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("成功")
          this.getList()
        }
      })
    },
    //批量补充
    handleBuchongstatus(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let str = ''
      this.selects.map(v=>{
        if(v.voucherId){
          str = '检测到您有关联凭证，重新补充会同时清除凭证信息'
          return
        }
      })
      if(str){
        this.$confirm(str, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$refs.inbc.init(this.selects)
        })
      }else{
        this.$refs.inbc.init(this.selects)
      }  
    },
    inbcSuccess(){
      this.getList()
    },
    invoiceTypeChange(){
      if(!this.listQuery.invoiceType.length){
        this.listQuery.rzzt = 0;
      }
      if(this.listQuery.invoiceType.length == 1 && (this.listQuery.invoiceType[0] == '2' || this.listQuery.invoiceType[0] == '5')){
        this.listQuery.rzzt = 0;
      }
      if(this.listQuery.invoiceType.length == 2){
        if(this.listQuery.invoiceType.includes('2') && this.listQuery.invoiceType.includes('5')){
          this.listQuery.rzzt = 0;
        }
      }
      this.getList()
    },
    getList(){
      this.loadingBig = true
      inInvoiceList(this.listQuery).then(res=>{
        this.loadingBig = false
        if(res.data.msg == 'success'){
          this.tableData = res.data.data.list ? res.data.data.list : []
          this.total = res.data.data.total
          this.setScroll()
        }
      })
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoadIn");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.list.map(v=>{
                v.checked = true
              })
              that.allChecked = true
            }
          }
        }
      });
    },
    // 删除发票
    delALL(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let delSelects = []
      this.selects.map(v=>{
        delSelects.push({
          id:v.id
        })
      })
      this.invoiceDel(delSelects)
    },
    delInvoice(row){
      if(this.$checkSettleStatus()) return
      this.invoiceDel([{id: row.id}])
    },
    invoiceDel(ids){
      this.$confirm("确认删除", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'iconfont icon-shanchu'
      }).then(()=>{
        this.loadingBig = true
        this.loading = true

        delInInvoice(ids).then(res=>{
          this.loadingBig = false
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.$bus.emit('voucherUpdate')
            this.getList()
          }
        })
      })
    },
    //删除发票仓库的发票
    delInvoiceWare(row){
      if(this.$checkSettleStatus()) return
      this.invoiceWareDel([{id: row.id}])
    },
    delALLInvoiceWare(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let delSelects = []
      this.selects.map(v=>{
        delSelects.push({
          id:v.id
        })
      })
      this.invoiceWareDel(delSelects)
    },
    invoiceWareDel(ids){
      this.$confirm("确认删除发票吗", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'iconfont icon-shanchu'
      }).then(()=>{
        this.loadingBig = true
        this.loading = true
        delInInvoiceWare(ids).then(res=>{
          this.loadingBig = false
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.getList()
          }
        })
      })
    },
    //清除信息
    clearInfo(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let str = ''
      this.selects.map(v=>{
        if(v.voucherId){
          str = '检测到您有关联凭证，清除补充信息会同时清除凭证信息，'
          return
        }
      })
      let delSelects = []
      this.selects.map(v=>{
        delSelects.push({
          id:v.id
        })
      })
      this.clearInvoice(delSelects,str) 
    },
    // 清除折扣信息
    clearZk(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      voucherClearZk({ids:ids}).then(res => {
        if(res.data.msg == "success"){
          this.$qzfMessage("清除成功")
          this.getList()
        }
      })
    },
    clearInvoice(ids,str){
      if(this.$checkSettleStatus()) return
      this.$confirm(str+"固定资产不会关联删除，清除后请前往固定资产手动删除发票相关固定资产，是否确认清除？", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(()=>{
        this.loadingBig = true
        this.loading = true
        clearInInvoice(ids).then(res=>{
          this.loadingBig = false
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("清除成功")
            this.getList()
          }
        })
      })
    },
    goInfo(row){
      // if(this.$checkSettleStatus() != ""){
      //   //this.$message.error(this.$checkSettleStatus())
      //   return 
      // }
      if(row.voucherId){
        this.$confirm('该笔发票已生成凭证，是否继续操作？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.goSure(row)
        })
      }else{
        this.goSure(row)
      }
    },
    goSure(row){
      this.$store.dispatch('commons/setParam', {addInInvoiceId: row.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "addIncome")
      this.$router.push({
        path: "/input/addIncome",
        name: "addIncome"
      });
    },
    addIncome(e) {
      if(this.$checkSettleStatus()) return
      this.$store.dispatch('commons/setParam', {addInInvoiceId: 0})
      this.$store.dispatch('tagsView/delCachedViewByName', "addIncome")
      this.$router.push({
        path: "/input/addIncome",  
      });
    },
    //单选
    singleSelect(row, index) {
      row.checked = !row.checked;
      this.list.splice(index, 1, row);
      this.screenChecked()
    },
    // 全选、全不选
    allSelect() {
      this.allChecked = !this.allChecked
      let checked = true;
      // 全选
      if (this.isAllSelected) {
        checked = false;
      }
      this.tableData = this.tableData.map(el => {
        el.checked = checked;
        return el;
      });
      this.screenChecked()
    },
    screenChecked(){
      this.selects = this.tableData.filter(v=>{return v.checked})
      if(this.selects.length != 0){
        this.selectAmount = 0;
        this.selectTax = 0;
        this.selectTaxAmount = 0;
        this.selects.map(v=>{
          this.selectAmount += v.sumAmount
          this.selectTax += v.sumTax
          this.selectTaxAmount += v.sumTotal
        })
        this.selectAmount = (this.selectAmount).toFixed(2)
        this.selectTax = (this.selectTax).toFixed(2)
        this.selectTaxAmount = (this.selectTaxAmount).toFixed(2)
      }
    },
    handleWzz(){
      this.$router.push({
        path: "/input/incomeWzz",
      });
    },
    showVoucher(item){
      if(this.$checkSettleStatus()) return
      this.$refs.voucherShow.getList(item)
    },
    getHandlePingzheng(voucherId) {
      this.$store.dispatch('commons/getSubjects', this.comId)
      this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher"
      });
    },
    handleCommand(){
      if(this.$checkSettleStatus()) return
      if(this.selects.length == 0){
        this.$qzfMessage("请选择至少一条数据",1)
        return
      }
      let invoiceArray = []
      this.selects.map(v => {
        invoiceArray.push(v.id)
      })
      this.$refs.oneKeyVoucherHabbit.getList('in',invoiceArray)
    },
    openCom(){
      this.$refs.importBck.handImport()
    },
    // 统计
    sumsAlert() {
      this.$refs.inStatistic.init()
    },
    collect(){
      // this.$qzfMessage('因税局变动，预计1-2个工作日后完成更新',1)
      // return
      this.$refs.collectSingle.init()
    },
    //过滤是否生成凭证状态
    filterVoucherStatus(){
      let str = ''
      this.selects.map(v=>{
        if(v.voucherId){
          str = '检测到您有关联凭证'
          return
        }
      })
      return str
    },
    batchSetting(){
      if(this.$checkSettleStatus()) return
      if(this.selects.length == 0){
        this.$qzfMessage("请选择至少一条数据",1)
        return
      }
      let pzStr = this.filterVoucherStatus()
      pzStr = pzStr ? pzStr + '，' : ''
      let wrzSelects = []
      this.selects.map(v=>{
        wrzSelects.push({
          id:v.id,
          type:'wrz'
        })
      })
      this.$confirm(pzStr+'确认批量设置未认证?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loadingBig = true
        saveWrz(wrzSelects).then(res => {
          this.loadingBig = false
          if(res.data.msg == "success"){
            this.getList()
            this.$qzfMessage("设置成功")
          }
        })
      })
    },
    //批量设置已认证
    batchSettingYrz(){
      if(this.$checkSettleStatus()) return
      if(this.selects.length == 0){
        this.$qzfMessage("请选择至少一条数据",1)
        return
      }
      let pzStr = this.filterVoucherStatus()
      pzStr = pzStr ? pzStr + '，' : ''
      let yrzSelects = []
      this.selects.map(v=>{
        yrzSelects.push({
          id:v.id,
          type:'yrz'
        })
      })
      this.$confirm(pzStr+'确认批量设置已认证?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loadingBig = true
        saveWrz(yrzSelects).then(res => {
          this.loadingBig = false
          if(res.data.msg == "success"){
            this.getList()
            this.$qzfMessage("设置成功")
          }
        })
      })
    },
    // 批量是否退税
    batchDrawback(){
      if(this.$checkSettleStatus()) return
      if(this.selects.length == 0){
        this.$qzfMessage("请选择至少一条数据",1)
        return
      }
      let pzStr = this.filterVoucherStatus()
      pzStr = pzStr ? pzStr + '，' : ''
      let backSelects = []
      this.selects.map(v=>{
        backSelects.push({
          id:v.id,
          type:'sfts'
        })
      })
      this.$confirm(pzStr+'确认批量退税?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loadingBig = true

        saveWrz(backSelects).then(res => {
        this.loadingBig = false

          if(res.data.msg == "success"){
            this.$qzfMessage("设置成功")
          }
        })
      })
    },
    // 批量修改票面类型
    batchInvoiceType(){
      if(this.$checkSettleStatus()) return
      if(this.selects.length == 0){
        this.$qzfMessage("请选择至少一条数据",1)
        return
      }
      this.centerDialogVisible = true
    },
    batchChangeInvoiceType(){
      if(!this.batchInvoiceTypes){
        this.$qzfMessage('请选择票面类型',1)
        return
      }
      let pzStr = this.filterVoucherStatus()
      pzStr = pzStr ? pzStr + '，' : ''
      let invoiceTypeSelects = []
      this.selects.map(v=>{
        invoiceTypeSelects.push({
          id:v.id,
          type:'invoiceType',
          invoiceType:this.batchInvoiceTypes
        })
      })
      this.invoiceTypeSelects2 = invoiceTypeSelects
      this.$confirm(pzStr+'确认批量修改票面类型?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.batchChangeInvoiceTypeLoading = true
        saveWrz(this.invoiceTypeSelects2).then(res => {
          this.batchChangeInvoiceTypeLoading = false
          if(res.data.msg == "success"){
            this.centerDialogVisible = false
            this.selects = []
            this.getList()
            this.$qzfMessage("设置成功")
          }
        })
      })
    },
    handleClick(tab) {
      if(tab.props.name == 2){
        this.listQuery.bc = 2
        this.listQuery.ware = false
        this.getList()
      }else if (tab.props.name == 3){
        this.listQuery.bc = 3
        this.listQuery.ware = false
        this.getList()
      }else if (tab.props.name == 1){
        this.listQuery.bc = 1
        this.listQuery.ware = false
        this.getList()
      }else if(tab.props.name == 4){
        this.listQuery.bc = 3;
        this.listQuery.ware = true
        this.getList()
      }else if(tab.props.name == 5){
        this.$refs.incomeWzz.getList()
      }
    },
    singleDayin(type){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      this.$refs.dayinDetail.init(ids)
      // dayinSellIn({ids,type}).then(res=>{
      //   if(res.data.msg == "success"){
      //     window.open(res.data.data.url);
      //   }
      // })
    },
    exportIncome(){
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      exportReceiptOfReceipts({
        ids:ids
      }).then(res => {
        window.open(res.data.data.url)
      })
    },
    checkCollectInvoice(item){
      this.$refs.invoiceCtp.init(item)
      //window.open('https://file.listensoft.net' + item.pdfUrl)
    },
    changeDpzt(item){
      let param = {
        type:'in',
        ids:[item.id],
        dpzt:item.dpzt
      }
      invoiceDpzt(param).then(res => {
        if(res.data.msg == "success"){
          this.$qzfMessage("修改成功");
          this.getList()
        }
      })
    },
    // 发票记录排序
    invoiceSort(){
      this.$refs.changeSortList.dialogVisible = true
    },
    changeSort(val){
      this.listQuery.sortType = val.radioSort
      this.listQuery.sortField = val.sortMethod
      this.getList()
    },
    //转移发票仓库
    moveWare(){
      if(this.$checkSettleStatus()) return
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.loading1 = true
      // let status = false
      // this.selects.map(v=>{
      //   if(v.voucherNo){
      //     status = true
      //   }
      // })
      // if(status){
      //   this.$qzfMessage("请选择未生成凭证的发票", 1)
      //   return
      // }
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      inInvoiceMigrateWare({
        ids:ids,
        type:'ware'
      }).then(res=>{
        this.loading1 = false
        if(res.data.msg == 'success'){
          this.$qzfMessage('迁移成功');
          this.getList();
          this.selects = []
        }
      })
    },
    //转移到进项
    moveIn(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.loading1 = true
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      inInvoiceMigrateWare({
        ids:ids,
        type:'in'
      }).then(res=>{
        this.loading1 = false
        if(res.data.msg == 'success'){
          this.$qzfMessage('迁移成功');
          this.getList();
          this.selects = []
        }
      })
    },
    //批量修改到票状态
    editDpStatus(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      this.$refs.updateDpStatus.init(ids)
    },
    //批量结算
    batchSettle(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let status = false
      this.selects.map(v=>{
        if(v.settlementStatus == 1){
          status = true
          return
        }
      })
      if(status){
        this.$qzfMessage("存在已结算发票", 1)
        return
      }
      this.$refs.invoiceSettle.init(this.selects)
    },
    checkImg(item){
      this.$refs.checkImg.dialog(item)
    },
    //批量修改抵扣状态
    editDkStatus(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let pzStr = this.filterVoucherStatus()
      if(pzStr){
        this.$confirm(pzStr+'，确认批量修改抵扣状态?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dkType = 'bqdk'
          this.dialogDkVisible = true
        })
      }else{
        this.dkType = 'bqdk'
        this.dialogDkVisible = true
      }
    },
    batchChangeDkStatus(){
      this.dkLoading = true
      let data = []
      this.selects.map(v=>{
        data.push({
          id:v.id,
          type:this.dkType
        })
      })
      saveWrz(data).then(res => {
        this.dkLoading = false
        if(res.data.msg == "success"){
          this.getList()
          this.$qzfMessage("设置成功")
          this.dialogDkVisible = false
        }
      })
    },
    //自动补充
    inSetting(){
      if(this.$checkSettleStatus()) return
      // this.buttonLoading = true
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      this.$refs.autoSetting.init([this.comId],'in',ids)
    },
    //重置
    cancel(){
      let originLimit = this.listQuery.limit
      let originWare = this.listQuery.ware
      let originBc = this.listQuery.bc
      this.listQuery= {
        page: 1,
        limit:originLimit,
        bc: originBc,
        taxRate:1,
        rq:null,
        ssflbm:'',
        upload:0,
        voucher:0,
        js:0,
        fphm:'',
        corpName:'',
        ware:originWare,
        rzzt:0,
        dpzt:2,
        negative:0,
        remark:"",
        invoiceType:[],
        name:'',
        itemName:'',
        voucherNo:'',
        hasAutoLabel:null,
      }
      this.getList()
    },
    changeBtnLoading(){
      this.init(1)
      // this.buttonLoading = true
      this.loading1 = true
    },
    getNewList(){
      // this.buttonLoading = false
      this.loading1 = false
      this.getList()
      this.init(1)
      this.$store.dispatch("commons/getSubjects")
      this.$store.dispatch("commons/getCorp")
      this.$store.dispatch("commons/getBusinessType")
    },
    sortChange(){
      this.descStatus = !this.descStatus
      if(this.descStatus){
        this.listQuery.orderBy = 'o.sum_total desc'
      }else{
        this.listQuery.orderBy = 'o.sum_total'
      }
      this.getList()
    },
    //批量下载发票影像
    batchDownload(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let param = {
        type:"in",
        invoiceIds: this.selects.map(v=>{
          return v.id
        })
      }
      invoiceDownload(param).then(res=>{
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    }
    // 批量结算
    // handleSettlement(command){
    //   if(this.$checkSettleStatus() != ""){
    //     //this.$message.error(this.$checkSettleStatus())
    //     return 
    //   }
    //   if(this.selects.length == 0){
    //     this.$qzfMessage("请至少选择一条", 1)
    //     return
    //   }
    //   let settleStatus = false
    //   let statusFu = false
    //   let statusPayer = false
    //   this.selects.map(v => {
    //     if(v.settlementStatus == 1){
    //       settleStatus = true
    //     }
    //     if(v.sumTotal < 0){
    //       statusFu = true
    //     }
    //     if(!v.subjectId){
    //       statusPayer = true
    //     }
    //   })
    //   // if(settleStatus){
    //   //   this.$qzfMessage("不能重复结算", 1)
    //   //   return
    //   // }
    //   // if(statusFu){
    //   //   this.$qzfMessage("不允许结算负数！", 1)
    //   //   return
    //   // }
    //   // if(statusPayer){
    //   //   this.$qzfMessage("未补充不允许结算！", 1)
    //   //   return
    //   // }

    //   if (command == "a") {
    //     this.$router.push({
    //       path: "/input/money",
    //       name: "money",
    //       params: {
    //         settleType: "income", // 录入来源:销项发票
    //         settleData: JSON.stringify(this.selects),
    //         routeSource:'bank'
    //       }
    //     });
    //   } else if (command == "b") {
    //     //现金跳转
    //     this.$router.push({
    //       path: "/input/money",
    //       name: "money",
    //       params: {
    //         settleType: "income", // 录入来源:销项发票
    //         settleData: JSON.stringify(this.selects),
    //         routeSource:'cash'
    //       }
    //     });
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.opera_icon{
  cursor: pointer;
  font-size: 14px;
  top: 2px;
}
.styleForm .el-form-item{
  margin-bottom: 5px;
}
// ::-webkit-scrollbar {
//     width: 15px;
// }
// /* 滚动槽 */
// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: rgba(0, 0, 0, 0.3);
//   box-shadow: rgba(0, 0, 0, 0.3);
// }
// /* 滚动条滑块 */
// ::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   background: rgba(17, 16, 16, 0.13);
//   -webkit-box-shadow: rgba(0, 0, 0, 0.9);
//   box-shadow: rgba(0, 0, 0, 0.5);
// }
.left_box {
  float: left;
  margin-top: 11px;
  width: 2%;
  input {
    width: 80%;
    height: 16px;
  }
}
.top-nav {
  height: 36px;
  border: 1px solid #b9b9b9;
  background: var(--themeColor,#17a2b8);
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
  position: relative;
  .box-content {
    box-sizing: border-box;
    text-align: center;
    border-left: 1px solid #b9b9b9;
    line-height: 34px;
    font-size: 13px;
    height: 34px;
  }
}
.right_box {
  float: left;
  //width: 98%;
  width: 100%;
}
.for-content {
  .box-content {
    box-sizing: border-box;
    border-left: 1px solid #b9b9b9;
    text-align: center;
    line-height: 36px;
    height: 36px;
    font-size: 13px;
    border-bottom: 1px solid #b9b9b9;
    padding: 0 10px;
  }
  .el-col:nth-child(10) .box-content {
    // border-bottom: none;
    border-right: 1px solid #b9b9b9;
  }
  .el-col:nth-child(1) .box-content {
    // border-bottom: none;
    border-left: none;
  }
}
.table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}
.content-nav {
  line-height: 36px;
  border: 1px solid #b9b9b9;
  .content-top {
    background:var(--themeColorLeftMenu,#ecf7f9);
    .box-content {
      box-sizing: border-box;
      // border-left: 1px solid #eee;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
      border-bottom: 1px solid #b9b9b9;
    }
    .box-content:first-child(1) {
      border-left: none;
    }
  }
  .for-content {
    .box-content {
      box-sizing: border-box;
      border-left: 1px solid #b9b9b9;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
      border-bottom: 1px solid #b9b9b9;
      padding: 0 10px;
      color: #212529;
    }
    .el-col:nth-child(9) .box-content {
      // border-bottom: none;
      border-right: 1px solid #b9b9b9;
    }
    .el-col:nth-child(1) .box-content {
      // border-bottom: none;
      border-left: none;
    }
  }
  .bottom-content {
    .box-content {
      box-sizing: border-box;
      border-left: 1px solid #b9b9b9;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
      color: #212529;
    }
    .el-col:nth-child(1) .box-content {
      border-left: none;
    }
    .el-col:nth-child(7) .box-content {
      border-right: 1px solid #b9b9b9;
    }
  }
}
.top_font {
  float: left;
  width: 87%;
  margin: 0 auto;
  li {
    font-size: 13px;
    color: #212529;
    float: left;
    text-align: left;
    padding-left: 5px;
    //margin-right: 2px;
    &.big {
      // width: 260px
    }
  }
}
.top_right_btns {
  float: right;
  // width: 10%;
  margin-right: 5px;
  position: relative;
  i {
    margin: 0 3px;
    cursor: pointer;
    // color: var(--themeColor,#17a2b8);
    font-size: 17px;
    line-height: 24px;
  }
}
.ccyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.selects :deep(.tags-select-input){
  height: 24px;
}
.selects :deep(.el-select__tags){
    height: 24px;
    white-space: nowrap;
    overflow: hidden;
    flex-wrap: nowrap;
}
.selects :deep(.el-select__tags-text){
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.operation_btn{
  margin-left: 5px;
}
.center_big{
  position: relative;
}
.fold_icon{
  position: absolute;
  left: 50%;
  bottom: -10px;
  z-index: 1;
  padding: 0 10px;
  border-radius: 14px;
  font-size: 11px !important;
  height: 20px;
  line-height: 20px;
}
</style>
<style>
.el-message-box{
  width: 800px;
}
.sel_class .el-input__inner{
  border-color: transparent !important;
}
.top_price .el-input-group--append .el-input__inner,.top_price .el-input-group__prepend{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.top_price .el-select .el-input.is-focus .el-input__inner,.top_price .el-select .el-input:hover .el-input__inner{
  border-color: transparent !important;
}
.top_price .el-input-group--prepend .el-input__inner,.top_price .el-input-group__append{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

</style>