<template>
  <div style="position: relative;" v-loading="loading1">
    <div style="position:absolute;right:17px;z-index:999;top:3px">
      <el-button type="text" @click="sumsAlert" :disabled="!$buttonStatus('fp_xx_cktj')">点击查看统计</el-button>
    </div>
    <el-tabs v-model="listQuery.bc" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="全部发票" :name="3"></el-tab-pane>
      <el-tab-pane label="已补充" :name="1"></el-tab-pane>
      <el-tab-pane label="未补充" :name="2"></el-tab-pane>
      <div class="fl">
        <el-select @change="getList" filterable v-model="listQuery.invoiceType" placeholder="选择发票类型" size="small" style="width:150px;margin-left:0px" clearable multiple collapse-tags class="selects">
          <el-option
            v-for="item in optionsOut"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input v-model="listQuery.fphm" placeholder="购方名称/发票号码" @keyup.enter="getList" style="width:170px" size="small" clearable></el-input>
        <el-button style="margin-right:5px;" size="small" @click="getList" type="primary">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="width:605px" class="styleForm" label-width="85px" :inline="true" size="small">
            <el-form-item label="开票日期：">
              <el-date-picker
                v-model="listQuery.rq"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width:185px"
              />
            </el-form-item>
            <el-form-item label="税目：">
              <shuimu v-model:taxId="listQuery.taxId" style="width:185px;margin:0"></shuimu>
            </el-form-item>
            <el-form-item label="税率：">
              <selectTax v-model:taxRate="listQuery.taxRate" style="width:185px;margin:0"></selectTax>
            </el-form-item>
            <el-form-item label="商品编码：">
              <ssflbm v-model:ssflbm="listQuery.ssflbm" style="width:185px;margin:0"></ssflbm>
            </el-form-item>
            <el-form-item label="发票来源：">
              <el-select v-model="listQuery.upload" placeholder="请选择来源" style="width:185px;margin:0">
                <el-option v-for="item in fromOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="单据标注：">
              <el-select v-model="listQuery.autoLabel" placeholder="请选择单据标注" style="width:185px;margin:0" filterable clearable>
                <el-option v-for="item in labelOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标注：">
              <el-select v-model="listQuery.hasAutoLabel" placeholder="请选择标注" style="width:185px;margin:0" filterable>
                <el-option v-for="item in bzOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="到票状态：">
              <el-radio-group v-model="listQuery.dpzt">
                <el-radio :label="2">全部</el-radio>
                <el-radio :label="0">已到票</el-radio>
                <el-radio :label="1">未到票</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="凭证状态：">
              <el-radio-group v-model="listQuery.voucher">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已生成</el-radio>
                <el-radio :label="2">未生成</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="结算状态：">
              <el-radio-group v-model="listQuery.js">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已结算</el-radio>
                <el-radio :label="2">未结算</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="开票形式：">
              <el-radio-group v-model="listQuery.openMethod">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">自开</el-radio>
                <el-radio :label="2">税局代开</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="正负发票：">
              <el-radio-group v-model="listQuery.negative">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">正数发票</el-radio>
                <el-radio :label="2">负数发票</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="开票项目：">
              <el-input v-model="listQuery.name" placeholder="请输入开票项目"  style="width:185px;margin:0"></el-input>
            </el-form-item>
            <el-form-item label="补充项目：">
              <el-input v-model="listQuery.itemName" placeholder="请输入补充项目"  style="width:185px;margin:0"></el-input>
            </el-form-item>
            <el-form-item label="凭证号：">
              <el-input v-model="listQuery.voucherNo" placeholder="请输入凭证号"  style="width:185px;margin:0"></el-input>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input v-model="listQuery.remark" placeholder="请输入备注"  style="width:185px;margin:0"></el-input>
            </el-form-item>
          </el-form>
        </search>

      </div>
      <div class="fr" style="margin-bottom:10px">
        <span v-if="autoBcStatus"
            style="
              font-size: 13px;
              color: #e6a23c;
              margin-right: 5px;
            "
            ><i class="iconfont icon-sanjiaotanhao" style="color:#e6a23c;margin-right:4px"></i>{{this.autoBcType}}发票自动补充中！</span
          >
        <el-button v-if="$buttonStatus('fp_xx_ai_bc')" :disabled="autoBcStatus" :loading="this.buttonLoading" type="success" size="small" @click="outSetting" style="margin-right:10px"><img style="margin-left:-7px;margin-top:2px;width:21px;height:14px;margin-right:3px" src="@/assets/ai3d.png" alt=""> 自动补充</el-button>
        <el-dropdown split-button type="primary" @click="addOutput" size="small" style="margin-right:10px" :hide-on-click="false"  v-if="$buttonStatus('fp_xx_tj')">
          <i class="iconfont icon-jiahao"></i> 添加
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <span size="small" @click="openCom">销项导入</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span size="small" @click="openComShougou" style="margin-top:10px">收购发票导入</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span size="small" @click="collect">单张采集</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <el-dropdown size="small" style="margin:0 10px 0 0;" :hide-on-click="false">
          <el-button size="small" type="primary" plain>
            批量修改<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu style="text-align:center">
              <el-dropdown-item command="e" @click="batchInvoiceType" v-if="$buttonStatus('fp_xx_plxgpmlx')">
                <span >修改发票类型</span>
              </el-dropdown-item>
              <el-dropdown-item command="i" @click="invoiceSort()" v-if="$buttonStatus('fp_xx_fpjlpx')">
                <span >修改发票顺序</span>
              </el-dropdown-item>
              <el-dropdown-item command="f" @click="batchCorpName" v-if="$buttonStatus('fp_xx_plxggfmc')">
                <span >修改购方名称</span>
              </el-dropdown-item>
              <el-dropdown-item command="z" @click="clearInfo" v-if="$buttonStatus('fp_xx_qcfpbcxx')">
                <span >清除补充信息</span>
              </el-dropdown-item>
              <el-dropdown-item command="w" @click="editDpStatus" v-if="$buttonStatus('fp_xx_plxgdpzt')">
                <span >修改到票状态</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <qzf-button button_code="fp_xx_plbc" type="primary" @success="handleBuchongstatus()" size="small" :disabled="autoBcStatus"  >
          <el-icon><Memo /></el-icon><span  > 批量补充</span>
        </qzf-button>
        <el-button size="small" type="primary" @click="handleCommand" :disabled="!$buttonStatus('fp_xx_yjscpz')" plain>生成凭证</el-button>
        <qzf-button button_code="fp_xx_plsc" size="small" type="danger" @success="delALL()" :loading="loading" plain>
          <el-icon><Delete /></el-icon><span  > 删除</span>
        </qzf-button>
        <el-dropdown size="small" style="margin-left:10px">
          <el-button size="small" type="primary" plain>
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="b" @click="bqfp" v-if="$buttonStatus('fp_xx_zdbqfp')">
                <span>补全明细</span>
              </el-dropdown-item>
              <el-dropdown-item command="c"  @click="singleDayin()" v-if="$buttonStatus('fp_xx_dyxsmx')">
                <span>打印销售明细</span>
              </el-dropdown-item>
              <el-dropdown-item command="d" @click="batchDetail" v-if="$buttonStatus('fp_xx_plbcmx')">
                <span >批量补充明细</span>
              </el-dropdown-item>
              <el-dropdown-item command="x" @click="batchSettle" v-if="$buttonStatus('fp_xx_pljs')">
                <span >批量结算</span>
              </el-dropdown-item>
              <el-dropdown-item command="z" @click="batchDownload()" v-if="$buttonStatus('fp_jx_zydfpck')">
                <span >批量下载发票影像</span>
              </el-dropdown-item>
              <el-dropdown-item command="c" @click="exportOutput" v-if="$buttonStatus('fp_xx_dc')">
                <span>导出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="top_title clearfix">
        <div class="top-nav right_box fl">
          <el-row>
          <el-col :span="3">
            <div style="display: flex;align-items: center;padding-left:10px;">
              <input type="checkbox" :checked="isAllSelected" :style="{marginLeft:0}" @click="allSelect"/>
              <div class="box-content" style="border-left:none;width: 80%;">业务类型</div>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="box-content">税目</div>
          </el-col>
          <el-col :span="5">
            <div class="box-content">开票项目</div>
          </el-col>
          <el-col :span="4">
            <div class="box-content">货物或应税劳务、服务</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">数量</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">金额</div>
          </el-col>
          <el-col :span="1">
            <div class="box-content">税率</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">税额</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">价税合计</div>
          </el-col>
          </el-row>
        </div>
      </div>

        <div v-loading="loadingBig"> 
        <el-scrollbar :style="{height:contentStyleObj}" id="tableLazyLoad" ref="tableScroll"> 
        <el-empty :image-size="150" v-if="this.list.length == 0"></el-empty>

        <div class="content_center clearfix" style="margin-bottom:10px;" v-for="(item,index) in list" :key="item.id">
          <div class="content-nav right_box fl">
            <el-row class="content-top">
              <el-col :span="24" class="box-content clearfix">
                <ul class="top_font clearfix">
                  <li style="padding-left: 10px;" @click="singleSelect(item,index)">
                    <input type="checkbox" :checked="item.checked" :style="{marginLeft:0,marginTop:'10px'}"/>
                  </li>
                  <li style="width:55px">
                    <span style="color:green" v-if="item.invoiceType == '1'">（专）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '2'">（普）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '3'">（无票）</span>
                    <span style="color:green" v-else-if="item.invoiceType == '4'">（电专）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '5'">（电普）</span>
                  </li>
                  <li v-if="item.openMethod == 2" style="width:10px;color:green">(代)</li>

                  <el-tooltip effect="dark" :content="item.fphm" placement="top" :disabled="!item.fphm">
                    <li style="width:215px;" class="ccyc">发票号码:
                      <span style="color:green" v-if="item.invoiceType == '1' || item.invoiceType == '4'">{{item.fphm}}</span>
                      <span style="color:#d56400" v-if="item.invoiceType == '2' || item.invoiceType == '5'">{{item.fphm}}</span>
                      <span style="color:#d56400" v-if="item.invoiceType == '3'">{{item.fphm}}</span>
                    </li>
                  </el-tooltip>
                  
                  <el-tooltip effect="dark" :content="item.corpName" placement="top" :disabled="!item.corpName">
                    <li style="width:220px" class="ccyc">购方:
                      <span >{{item.corpName}}</span>
                    </li>
                  </el-tooltip>
                  <li style="width:136px">开票日期:
                    <span>{{$parseTime(item.invoiceDate, "{y}-{m}-{d}")}}</span>
                  </li>
                  <li style="width:100px">结算状态:
                    <span v-if="item.settlementStatus == 1" style="color:green">已结算</span>
                    <span v-else style="color:red">未结算</span>
                  </li>
                  <li style="width:85px">凭证:
                    <span style="color:var(--themeColor,#17a2b8);text-decoration:underline;cursor:pointer;font-weight:600" v-if="item.voucherNo" @click="getHandlePingzheng(item.voucherId)">{{item.voucherNo}}</span>
                    <span style="color:var(--themeColor,#17a2b8);;cursor:pointer" v-else-if="$buttonStatus('fp_xx_yjscpz')" type="text" size="small" @click="showVoucher(item)">凭证预览</span>
                  </li>
                  <li style="width:94px">到票状态:
                    <el-switch v-model="item.dpzt" size="small" :active-value="0" :inactive-value="1" @change="changeDpzt(item)"/>
                  </li>
                  <li v-if="item.autoLabel">
                    <el-tooltip effect="dark" placement="top">
                      <template #content> 
                        <p>标签：{{ item.autoLabel }}</p>
                      </template>
                     <span class="xgmStyle" style="font-size:11px;cursor: pointer;padding:0px">标注</span>
                    </el-tooltip>
                  </li>
                </ul>
                <div class="top_right_btns">
                  <span v-if="item.upload == 3" class="ybStyle" style="font-size:11px;padding:0px;position: relative;top:-2px">采</span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="top"
                    v-if="item.remark"
                  >
                  <template #content> 采集备注：{{ item.remark }}</template>
                  <span class="xgmStyle" style="font-size:11px;cursor: pointer;padding:0px;position: relative;top:-2px">备注</span>
                  </el-tooltip>
                 <!--  <el-tooltip content="预览发票" placement="top" effect="dark">
                    <el-button type="text" size="small" style="padding:0px;padding-bottom: 6px;" :disabled="!item.ctpImg || !item.ctpImg.match('/mnt')" @click="checkImg(item)">
                      <i class="iconfont icon-picture" style="color: rgb(13, 197, 13);font-size: 16px;" v-if="item.ctpImg && item.ctpImg.match('/mnt')"></i>
                      <i class="iconfont icon-picture" style="font-size: 16px;" v-else></i>
                    </el-button>
                  </el-tooltip> -->
                  <el-tooltip placement="top" effect="dark" v-if="$buttonStatus('fp_jx_ckfp') && item.ctpPdf">
                    <template #content> {{ item.ctpPdf && !item.pdfStatus ? '已封存，请前往发票影像恢复' : '查看发票影像' }}</template>
                    <el-button type="text" size="small" style="padding:0px;padding-bottom:5px;margin-left: 0;" :disabled="item.ctpPdf && !item.pdfStatus" @click="checkCollectInvoice(item)">
                      <i class="iconfont icon-pdf" style="color: rgb(13, 197, 13);font-size: 16px;" v-if="item.ctpPdf && item.pdfStatus"></i>
                      <i class="iconfont icon-pdf" style="color:#999;font-size: 16px;" v-else></i>
                    </el-button>
                  </el-tooltip>
                <!--   <el-tooltip content="查看发票影像" placement="top" effect="dark" v-if="$buttonStatus('fp_xx_ckfp')">
                    <el-button type="text" size="small" style="padding:0px;padding-bottom: 6px;margin-left: 0;" :disabled="!item.image || item.image.match('error')" @click="checkCollectInvoice(item)">
                      <i class="iconfont icon-pdf" style="color: rgb(13, 197, 13);font-size: 16px;" v-if="item.image && !item.image.match('error')"></i>
                      <i class="iconfont icon-pdf" style="font-size: 16px;" v-else></i>
                    </el-button>
                  </el-tooltip> -->
                  <el-tooltip content="编辑" placement="top" v-if="$buttonStatus('fp_xx_bj')" effect="dark">
                    <i @click="goInfo(item)" class="iconfont icon-bianji" style="color:var(--themeColor,#17a2b8)"></i>
                  </el-tooltip>
                  <el-tooltip content="删除" placement="top" v-if="$buttonStatus('fp_xx_plsc')" effect="dark">
                    <i @click="delInvoice(item)" class="iconfont icon-shanchu" style="color:var(--themeColor,#17a2b8)"></i>
                  </el-tooltip>
                </div>
              </el-col>
            </el-row>
            <div class="center_big clearfix">
              <el-button class="fold_icon" v-if="item.items?.length > 4 && !item.showStatus" @click="item.showStatus = true" size="small">展开全部 <el-icon><ArrowDown /></el-icon></el-button>
              <el-button class="fold_icon" v-if="item.showStatus" @click="item.showStatus = false" size="small">收起全部 <el-icon><ArrowUp /></el-icon></el-button>
              <div class="content" v-for="(itemw,index2) in item.items" :key="itemw.id">
                <el-row class="for-content" v-if="(index2 < 4 && !item.showStatus) || item.showStatus">
                  <el-tooltip effect="dark" :content="itemw.subjectName" placement="top" :disabled="!itemw.subjectName">
                    <el-col :span="3">
                      <div class="box-content ccyc">{{itemw.subjectName}}</div>
                    </el-col>
                  </el-tooltip>
                  
                  <el-tooltip effect="dark" :content="itemw.taxName" placement="top" :disabled="!itemw.taxName">
                    <el-col :span="3">
                      <div class="box-content ccyc">{{itemw.taxName}}</div>
                    </el-col>
                  </el-tooltip>
                    <el-col :span="5">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        placement="top"
                      >
                        <template #content>
                          <div>{{itemw.name}}{{ itemw.remark?'('+itemw.remark+')' : '' }}</div>
                        </template>
                        <div class="box-content ccyc" style="text-align: left;">{{itemw.name}}{{ itemw.remark?'('+itemw.remark+')' : '' }}</div>
                      </el-tooltip>
                    </el-col>
                    <el-col :span="4">
                      <div class="box-content ccyc" style="text-align: left;">{{itemw.itemName}}</div>
                    </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.count}}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.amount}}</div>
                  </el-col>
                  <el-col :span="1">
                    <div class="box-content">{{itemw.taxRate}}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.tax}}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.total}}</div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <el-row class="bottom-content ">
              <el-col :span="15">
                <div class="box-content">合计</div>
              </el-col>
              <el-col :span="2">
                <div class="box-content">{{item.sumCount}}</div>
              </el-col>
              <el-col :span="2">
                <div class="box-content">{{item.sumAmount}}</div>
              </el-col>
              <el-col :span="1">
                <div class="box-content"></div>
              </el-col>
              <el-col :span="2">
                <div class="box-content">{{item.sumTax}}</div>
              </el-col>
              <el-col :span="2">
                <div class="box-content">{{item.sumTotal}}</div>
              </el-col>
            </el-row>
          </div>
        </div>
        </el-scrollbar>
        </div>



      <div class="bottom_style">
        <div class="bottom_button" style="float:left" v-if="this.selects.length != 0">
        <i class="iconfont icon-gantanhao" style="color:#e6a23c;margin-right:5px;font-size:13px"></i>统计： &nbsp;发票份数：{{ this.selects.length }}份 &nbsp;金额：{{ selectAmount }} &nbsp; 税额：{{ selectTax }} &nbsp;价税合计：{{ selectTaxAmount }}
        </div>
        <div class="pagination">
          <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
        </div>
      </div>
    </el-tabs>

    <el-dialog :close-on-click-modal="false" destroy-on-close v-model="centerDialogVisible" title="提示" width="30%" center>
      <span>
        <selectInvoiceType v-model:invoiceType="batchInvoiceTypes" type="out"></selectInvoiceType>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="batchChangeInvoiceType" :loading="batchChangeInvoiceTypeLoading">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
 
    <el-dialog :close-on-click-modal="false" destroy-on-close v-model="detailDialogVisible" title="补充明细" width="70%" center >

      <div class="table_height"  ref="item" style="margin-top:10px">
      <section class="bank-table-header" v-if="list.length>0">
        <div>商品名称 <i style="margin-left: 5px;" @click="batchDetailChange('name')" class="iconfont icon-piliangcaozuo"></i></div>
        <div>商品编码 <i style="margin-left: 5px;" @click="batchDetailChange('spbm')" class="iconfont icon-piliangcaozuo"></i></div>
        <div>税率</div>
        <div>税目 <i style="margin-left: 5px;" @click="batchDetailChange('taxId')" class="iconfont icon-piliangcaozuo"></i></div>
        <div>操作</div>

      </section>
        
        <!-- 一大条 -->
      <div class="table_height" @scroll="scrollEvent" :style="{height:contentStyleObj2}" v-loading="isLoading">
        <el-empty :image-size="150" v-if="this.detailData.length == 0"></el-empty>

        <section class="bank-item-container" v-for="(item,index) in detailData" :key="index">
          <section class="bank-item-header">
            <el-tooltip effect="dark" :content="item.corpName" placement="top">
              <div class="ccyc" style="width:320px">
                购方名称:<el-input size="small" v-model="item.corpName" style="width:220px"/>
              </div>
            </el-tooltip>
            <div class="ccyc">
              开票日期: <el-date-picker type="date" v-model="item.invoiceDate" placeholder="选择日期" size="small"></el-date-picker>
            </div>

          </section>
          <!-- 银行账期的选择 -->
          <!-- 一小条 -->
          <table class="item_box" cellspacing="0" width="100%">
            <tr v-for="(bankItem,index2) in item.items" :key="index2">
              <td>
                <div >
                  <el-input v-model="bankItem.name" size="small"></el-input>
                </div>
              </td>
              <td style="min-width:0">
                <div class="block">
                  <ssflbm v-model:ssflbm="bankItem.spbm"></ssflbm>
                </div>
              </td>
              <td>
                <el-input disabled v-model="bankItem.shuilv" size="small"></el-input>
              </td>
              <td>
                <shuimu v-model:taxId="bankItem.taxId"></shuimu>
              </td>
              <td class="top_right_btns">

                <el-tooltip content="新增" placement="top" v-if="$buttonStatus('fp_xx_tj')" effect="dark">
                  <i class="iconfont icon-jiahao" @click="handleAdd(item,bankItem,index2)" style="color:var(--themeColor,#17a2b8)"></i>
                </el-tooltip>
                <el-tooltip content="删除" placement="top" v-if="item.items.length > 1 || $buttonStatus('fp_xx_plsc')" effect="dark">
                  <i class="iconfont icon-shanchu" @click="handleDelete(item,bankItem,index2)" style="color:var(--themeColor,#17a2b8)"></i>
                </el-tooltip>
              </td>
            </tr>
          </table>
        </section>
      </div>
    </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="detailDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="batchDetailSubmit">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <qzf-import-back :hideButton="true" @success="getList()" from="销项" ref="importBack"></qzf-import-back>
    <qzf-import-back :hideButton="true" @success="getList()" from="收购" ref="importBackShougou"></qzf-import-back>
    <voucher-show ref="voucherShow" from="2" @success="getList"></voucher-show>
    <outbc @success="outbcSuccess" ref="outbc"></outbc>
    <collectSingle ref="collectSingle" @success="getList()" type="out"></collectSingle>
    <changeSortList ref="changeSortList" @success="changeSort"></changeSortList>
    <updateDpStatus ref="updateDpStatus" @success="getList()" type="out"/>
    <invoiceSettle ref="invoiceSettle" type="out" @success="getList()"/>
    <oneKeyVoucherHabbit ref="oneKeyVoucherHabbit" @success=getList></oneKeyVoucherHabbit>
    <dayinOutDetail ref="dayinOutDetail"></dayinOutDetail>
    <checkPdf ref="checkPdf" />
    <checkImg ref="checkImg" />
    <autoSetting ref="autoSetting" @success="getNewList" @success2="changeBtnLoading"/>
  </div>
  <outStatistics ref="outStatistics" />
  <invoiceCtp ref="invoiceCtp" />
</template>

<script>
import ssflbm from '@/components/ssflbm/ssflbm.vue'
import shuimu from '@/components/ssflbm/shuimu.vue'
import outbc from './invoiceComponents/outbc.vue' 
import selectInvoiceType from "./selectInvoiceType.vue"
import selectTax from '@/components/Screening/selectTax.vue'

import collectSingle from "./collectSingle.vue"
import changeSortList from "./components/changeSortList.vue"


import { outInvoiceList , delOutInvoice ,inOutSum, invoiceCollectionBcOut ,saveOutWrz , invoiceBcStatus , bqOutInvoice ,invoiceDpzt ,clearOutInvoice ,getAutoBcStatus} from '@/api/invoice.js'
import { invoiceDownload } from '@/api/printSet'
import { exportInvoice } from "@/api/export"
import { sendTask } from "@/api/collection"
import updateDpStatus from './components/updateDpStatus.vue'
import invoiceSettle from './components/invoiceSettle.vue'
import dayinOutDetail from './components/dayinOutDetail.vue'
import oneKeyVoucherHabbit from "../../bookkeeps/components/oneKeyVoucherHabbit.vue"
import checkPdf from './components/checkPdf.vue'
import checkImg from './components/checkImg.vue'
import autoSetting from '../../autoHome/components/autoSetting.vue'
import outStatistics from './components/outStatistics.vue'
import invoiceCtp from './components/invoiceCtp.vue'
function deepClone(obj) {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = deepClone(obj[key]);
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}

export default {
  name:'income',
  components: {
    outbc,
    ssflbm,
    shuimu,
    selectInvoiceType,
    collectSingle,
    selectTax,
    changeSortList,
    updateDpStatus,
    invoiceSettle,
    dayinOutDetail,
    oneKeyVoucherHabbit,
    checkPdf,
    checkImg,
    autoSetting,
    outStatistics,
    invoiceCtp
  },
  data() {
    return {
      selects: [],
      listQuery: {
        page: 1,
        limit: 20,
        bc: 3,
        rq:[],
        taxId:0,
        ssflbm:'',
        upload:0,
        voucher:0,
        js:0,
        taxRate:1,
        dpzt:2,
        openMethod:0,
        negative:0,
        voucherNo:'',
        hasAutoLabel:null,
      },
      selectQueryType:'1',
      inputQuery: "",
      activeName:'first',
      list:[],
      total: 0,
      contentStyleObj:{}, //高度变化
      contentStyleObj2:{}, //高度变化

      fromOptions:[
        {
          value:0,
          label:'全部'
        },
        {
          value:1,
          label:'手动添加'
        },
        {
          value:2,
          label:'导入'
        },
        {
          value:3,
          label:'采集'
        },
      ],
      loading:false,
      loadingBig: false,
      centerDialogVisible:false,
      batchInvoiceTypes:'',
      invoiceTypeSelects2:[],
      detailDialogVisible:false,
      detailData:[],
      str:'',
      beginPeriod:'',
      endPeriod:'',
      batchChangeInvoiceTypeLoading:false,
      collectComId:this.$store.getters['user/comInfo'].comId*1,
      collectPeriod:this.$store.getters['user/comInfo'].period,
      successStatus:false,
      optionsOut:[
        {
          value: "1",
          label: "增值税专用发票"
        },
        {
          value: "2",
          label: "增值税普通发票"
        },
        {
          value: "3",
          label: "无票收入"
        },
        {
          value: "4",
          label: "增值税电子专用发票"
        },
        {
          value: "5",
          label: "增值税电子普通发票"
        }, 
      ],
      selectAmount:0,
      selectTax:0,
      selectTaxAmount:0,
      totalPage:0,
      pageSize:30,
      tableData:[],
      currentPage:1,
      allChecked:false,
      comId: this.$store.getters["user/comInfo"].comId * 1,
      orgId: this.$store.getters["user/user"].orgId,
      buttonLoading:false,
      pageStatus: true,
      autoBcStatus:false,
      loading1:false,
      autoBcType:"",
      labelOptions:[
        {
          value:'现金结算',
          label:'现金结算'
        },
        {
          value:'银行结算',
          label:'银行结算'
        },
        {
          value:'其他应付结算',
          label:'其他应付结算'
        },
        {
          value:'应付结算',
          label:'应付结算'
        }, {
          value:'预付结算',
          label:'预付结算'
        },
        {
          value:'资产清理',
          label:'资产清理'
        },
      ],
      bzOptions:[
        {
          value:0,
          label:'全部'
        },
        {
          value:1,
          label:'是'
        },
        {
          value:2,
          label:'否'
        },
      ],
    }
  },
  computed: {
    // 是否全选
    isAllSelected() {
      if(this.list.length == 0){
        return false
      }
      return this.tableData.every(el => {
        return el.checked;
      });
    },
  },

  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created () {
    this.getList()
    this.init()
    this.contentStyleObj=this.$getHeight(317)
    this.contentStyleObj2=this.$getHeight(460)
  },
  mounted(){
    this.initBus()
    this.initBcStatus()
  },
  methods:{
    initBcStatus(){
      this.$bus.off("autoBcStatusUpdate")
      this.$bus.on("autoBcStatusUpdate", (val) => {
        getAutoBcStatus({type:'out'}).then(res=>{
          this.autoBcStatus = res.data.data.autoBc
          this.autoBcType = res.data.data.autoBcType
        })
      });
    },
    initBus(){
      this.$bus.on("refreshTask", (val) => {
        if(!this.pageStatus){
          return
        }
        if(this.list.length == 0){
          return
        }
        if(this.autoBcStatus){
          getAutoBcStatus({type:'out'}).then(res => {
            this.autoBcStatus = res.data.data.autoBc
            this.autoBcType = res.data.data.autoBcType
            if(!this.autoBcStatus){
              this.$qzfMessage('自动补充完成！')
              this.$bus.emit('autoBcStatusUpdate')
              this.getNewList()
            }
          })
        }
      });
    },
    init(flag){
      if(!flag){
        invoiceBcStatus({type:'out'}).then(res => {
          if(res.data.data.status){
            this.$notify({
              title: '警告',
              message: '销项包含未补充完整发票',
              duration:5000,
              type: 'warning',
              offset: 0,
            });
          }
          if(res.data.data.cjStatus == 2){
            this.$notify({
              title: '警告',
              message: '销项本期未发起过采集',
              duration:5000,
              type: 'warning',
              offset: 75,
            });
          }else if(res.data.data.cjStatus == 3){
            this.$notify({
              title: '警告',
              message: '销项本期采集失败',
              duration:5000,
              type: 'warning',
              offset: 75,
            });
          }
        })
      }
      getAutoBcStatus({type:'out'}).then(res => {
        this.autoBcStatus = res.data.data.autoBc
        this.autoBcType = res.data.data.autoBcType
      })
    },
    selectQuery(){
      if(this.selectQueryType == "1"){
        this.listQuery.fphm = this.inputQuery
        this.listQuery.corpName = ''
      }else{
        this.listQuery.corpName = this.inputQuery
        this.listQuery.fphm = ''
      }
      this.getList()
    },
    //批量补充
    handleBuchongstatus(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let str = ''
      this.selects.map(v=>{
        if(v.voucherId){
          str = '检测到您有关联凭证，重新补充会同时清除凭证信息'
          return
        }
      })
      if(str){
        this.$confirm(str, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$refs.outbc.init(this.selects)
        })
      }else{
        this.$refs.outbc.init(this.selects)
      }  
    },
    outbcSuccess(){
      this.getList()
    },
    getList(){
      if(this.successStatus){
        return
      }
      this.loadingBig = true
      outInvoiceList(this.listQuery).then(res=>{
        this.loadingBig = false
        if(res.data.msg == 'success'){
          this.tableData = res.data.data.list ? res.data.data.list : []
          this.total = res.data.data.total
          this.tableData.forEach(v => {
            v.sumCount = v.items? this.calculateSumCount(v) : 0;
          });
          this.setScroll()
        }
      })
    },
    calculateSumCount(item){
      return item.items.reduce((sum, k) => sum + k.count, 0);
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.list.map(v=>{
                v.checked = true
              })
              that.allChecked = true
            }
          }
        }
      });
    },
    // 删除发票
    delALL(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let delSelects = []
      this.selects.map(v=>{
        delSelects.push({
          id:v.id
        })
      })
      this.invoiceDel(delSelects)
    },
    delInvoice(row){
      if(this.$checkSettleStatus()) return
      this.invoiceDel([{id: row.id}])
    },
    invoiceDel(ids){
      this.$confirm("确认删除", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'iconfont icon-shanchu'
      }).then(()=>{
        this.loading = true
        delOutInvoice(ids).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.$bus.emit('voucherUpdate')
            this.getList()
          }
        })
      })
    },
    goInfo(row){
      // if(this.$checkSettleStatus() != ""){
      //   //this.$message.error(this.$checkSettleStatus())
      //   return 
      // }
      if(row.voucherId){
        this.$confirm('该笔发票已生成凭证，是否继续操作？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.goSure(row)
        })
      }else{
        this.goSure(row)
      }
    },
    goSure(row){
      this.$store.dispatch('commons/setParam', {addOutInvoiceId: row.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "addOutput")
      this.$router.push({
        path: "/input/addOutput",
        name: "addOutput"
      });
    },
    addOutput(e) {
      if(this.$checkSettleStatus()) return
      this.$store.dispatch('commons/setParam', {addOutInvoiceId: 0})
      this.$store.dispatch('tagsView/delCachedViewByName', "addOutput")
      this.$router.push({
        path: "/input/addOutput",  
      });
    },
    //单选
    singleSelect(row, index) {
      row.checked = !row.checked;
      this.list.splice(index, 1, row);
      this.screenChecked()
    },
    // 全选、全不选
    allSelect() {
      this.allChecked = !this.allChecked
      let checked = true;
      // 全选
      if (this.isAllSelected) {
        checked = false;
      }
      this.tableData = this.tableData.map(el => {
        el.checked = checked;
        return el;
      });
      this.screenChecked()
    },
    screenChecked(){
      this.selects = this.tableData.filter(v=>{return v.checked})
      if(this.selects.length != 0){
        this.selectAmount = 0;
        this.selectTax = 0;
        this.selectTaxAmount = 0;
        this.selects.map(v=>{
          this.selectAmount += v.sumAmount
          this.selectTax += v.sumTax
          this.selectTaxAmount += v.sumTotal
        })
        this.selectAmount = (this.selectAmount).toFixed(2)
        this.selectTax = (this.selectTax).toFixed(2)
        this.selectTaxAmount = (this.selectTaxAmount).toFixed(2)
      }
    },
    showVoucher(item){
      this.$refs.voucherShow.getList(item)
    },
    getHandlePingzheng(voucherId) {
      this.$store.dispatch('commons/getSubjects', this.comId)
      this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher"
      });
    },
    bqfp(){
      if(this.$checkSettleStatus()) return
      invoiceCollectionBcOut({}).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("成功")
          this.getList()
        }
      })
    },
    handleCommand() {
      if(this.$checkSettleStatus()) return
      if(this.selects.length == 0){
        this.$qzfMessage("请选择至少一条数据",1)
        return
      }
      let invoiceArray = []
      this.selects.map(v => {
        invoiceArray.push(v.id)
      })
      this.$refs.oneKeyVoucherHabbit.getList('out',invoiceArray)
    },
    openCom(){
      if(this.$checkSettleStatus()) return
      this.$refs.importBack.handImport()
    },
    openComShougou(){
      if(this.$checkSettleStatus()) return
      this.$refs.importBackShougou.handImport()
    },
    sumsAlert() {
      this.$refs.outStatistics.init()
    },
    collect(){
      if(this.$checkSettleStatus()) return
      // this.$qzfMessage('因税局变动，预计1-2个工作日后完成更新',1)
      // return
      this.$refs.collectSingle.init()
    },
     //过滤是否生成凭证状态
     filterVoucherStatus(){
      let str = ''
      this.selects.map(v=>{
        if(v.voucherId){
          str = '检测到您有关联凭证'
          return
        }
      })
      return str
    },
    // 批量修改票面类型
    batchInvoiceType(){
      if(this.$checkSettleStatus()) return
      if(this.selects.length == 0){
        this.$qzfMessage("请选择至少一条数据",1)
        return
      }
      this.centerDialogVisible = true
    },
    batchChangeInvoiceType(){
      let pzStr = this.filterVoucherStatus()
      pzStr = pzStr ? pzStr + '，' : ''
      let invoiceTypeSelects = []
      this.selects.map(v=>{
        invoiceTypeSelects.push({
          id:v.id,
          type:'invoiceType',
          invoiceType:this.batchInvoiceTypes
        })
      })
      this.invoiceTypeSelects2 = invoiceTypeSelects
      this.$confirm(pzStr+'确认批量修改票面类型?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.batchChangeInvoiceTypeLoading = true
        saveOutWrz(this.invoiceTypeSelects2).then(res => {
          this.batchChangeInvoiceTypeLoading = false
          if(res.data.msg == "success"){
            this.centerDialogVisible = false
            this.selects = []
            this.getList()
            this.$qzfMessage("设置成功")
          }
        })
      })
    },
    // 批量修改购方名称
    saveCorpSure(){
      this.$prompt('请输入购方名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.loadingBig = true
        let corpNameSelects = []
        this.selects.map(v=>{
          corpNameSelects.push({
            id:v.id,
            type:'corpName',
            corpName:value
          })
        })
        saveOutWrz(corpNameSelects).then(res => {
          this.loadingBig = false
          if(res.data.msg == "success"){
            this.selects = []
            this.getList()
            this.$qzfMessage("修改成功")
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });       
      });
    },
    batchCorpName(){
      if(this.$checkSettleStatus()) return
      if(this.selects.length == 0){
        this.$qzfMessage("请选择至少一条数据",1)
        return
      }
      let pzStr = this.filterVoucherStatus()
      pzStr = pzStr ? pzStr + '，' : ''
      if(pzStr){
        this.$confirm(pzStr+'确认批量修改购方名称?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.saveCorpSure()
        })
      }else{
        this.saveCorpSure()
      }
    },
    exportOutput(){
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      exportInvoice({
        ids:ids
      }).then(res => {
        window.open(res.data.data.url)
      })
    },
    handleClick(tab) {
      if(tab.props.name == 2){
        this.listQuery.bc = 2
        this.getList()
      }else if (tab.props.name == 3){
        this.listQuery.bc = 3
        this.getList()
      }else if (tab.props.name == 1){
        this.listQuery.bc = 1
        this.getList()
      }
    },

    // 打印明细
    singleDayin(type){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      this.$refs.dayinOutDetail.init(ids)
    },
    changeDpzt(item){
      let param = {
        type:'out',
        ids:[item.id],
        dpzt:item.dpzt
      }
      invoiceDpzt(param).then(res => {
        if(res.data.msg == "success"){
          this.$qzfMessage("修改成功");
          this.getList()
        }
      })
    },
    // 发票记录排序
    invoiceSort(){
      this.$refs.changeSortList.dialogVisible = true
    },
    changeSort(val){
      this.listQuery.sortType = val.radioSort
      this.listQuery.sortField = val.sortMethod
      this.getList()
    },
    // 获取发票影像
    collectInvoicePdf(){
      this.loadingBig = true
      let param = {
        taskName: 'cj-invoice-pdf',
        comIds:[this.$store.getters['user/comInfo'].comId*1],
        period:this.$store.getters['user/comInfo'].period,
      }
      sendTask(param).then(res => {
        this.successStatus = false
        if(res.data.msg == "success"){
          this.$qzfMessage('任务已发起',3)
          this.getList();
        }else{
          this.loadingBig = false
          this.successStatus = true
        }
      })
    },
    //批量修改到票状态
    editDpStatus(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      this.$refs.updateDpStatus.init(ids)
    },
    // 批量结算
    // handleSettlement(command){
    //   if(this.$checkSettleStatus() != ""){
    //     //this.$message.error(this.$checkSettleStatus())
    //     return 
    //   }
    //   if(this.selects.length == 0){
    //     this.$qzfMessage("请至少选择一条", 1)
    //     return
    //   }
    //   let settleStatus = false
    //   let statusFu = false
    //   let statusPayer = false
    //   this.selects.map(v => {
    //     if(v.settlementStatus == 1){
    //       settleStatus = true
    //     }
    //     if(v.sumTotal < 0){
    //       statusFu = true
    //     }
    //     if(!v.subjectId){
    //       statusPayer = true
    //     }
    //   })
    //   // if(settleStatus){
    //   //   this.$qzfMessage("不能重复结算", 1)
    //   //   return
    //   // }
    //   // if(statusFu){
    //   //   this.$qzfMessage("不允许结算负数！", 1)
    //   //   return
    //   // }
    //   // if(statusPayer){
    //   //   this.$qzfMessage("未补充不允许结算！", 1)
    //   //   return
    //   // }

    //   if (command == "a") {
    //     this.$router.push({
    //       path: "/input/money",
    //       name: "money",
    //       params: {
    //         settleType: "output", // 录入来源:销项发票
    //         settleData: JSON.stringify(this.selects),
    //         routeSource:'bank'
    //       }
    //     });
    //   } else if (command == "b") {
    //     //现金跳转
    //     this.$router.push({
    //       path: "/input/money",
    //       name: "money",
    //       params: {
    //         settleType: "output", // 录入来源:销项发票
    //         settleData: JSON.stringify(this.selects),
    //         routeSource:'cash'
    //       }
    //     });
    //   }
    // },

    // 批量补充明细
    batchDetail(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let itemBool = false
      this.selects.map(v => {
        if(v.items){
          itemBool = true
        }
      })
      if(itemBool){
        this.$qzfMessage("已存在明细", 1)
        return
      }
      this.detailData = []    
      this.selects.map(v=>{
        let invoiceDate = ""
        if(!v.invoiceDate.match("0001")){
          invoiceDate = v.invoiceDate
        }
        this.detailData.unshift({
          id: v.id,
          corpName: v.corpName,
          invoiceDate: invoiceDate,
          items: [
          {
            spbm: "",
            name: "",
            taxId: 0,
            shuilv:(v.sumTax/v.sumAmount).toFixed(2),
          }
        ]
        })
      })  
      this.detailDialogVisible = true
    },
    batchDetailChange(e){
      this.detailData.map(v=>{
        v.items.map(vv => {
          vv[e] = this.detailData[0].items[0][e]
        })
      })
    },
    batchDetailSubmit(){
      bqOutInvoice(this.detailData).then(res => {
        if(res.data.msg == "success"){
          this.detailDialogVisible = false
          this.$qzfMessage("补充成功")
          this.getList()
        }
      })
    },

    // 批量补充明细
    handleAdd(item, row, index){
      let clone = deepClone(row);
      delete clone.id;
      item.items.splice(index + 1, 0, clone);
    },
    handleDelete(item, row, index) {
      item.items.splice(index, 1);
    },
    checkCollectInvoice(item){
      this.$refs.invoiceCtp.init(item)
      //window.open('https://file.listensoft.net' + item.pdfUrl)
    },
    //清除信息
    clearInfo(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let str = ''
      this.selects.map(v=>{
        if(v.voucherId){
          str = '检测到您有关联凭证，清除补充信息会同时清除凭证信息，'
          return
        }
      })
      let delSelects = []
      this.selects.map(v=>{
        delSelects.push({
          id:v.id
        })
      })
      this.clearInvoice(delSelects,str) 
    },
    clearInvoice(ids,str){
      if(this.$checkSettleStatus()) return
      this.$confirm(str+"固定资产不会关联删除，清除后请前往固定资产手动删除发票相关固定资产，是否确认清除？", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(()=>{     
        this.loading = true
        clearOutInvoice(ids).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("清除成功")
            this.getList()
          }
        })
      })
    },
     //批量结算
     batchSettle(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let status = false
      this.selects.map(v=>{
        if(v.settlementStatus == 1){
          status = true
          return
        }
      })
      if(status){
        this.$qzfMessage("存在已结算发票", 1)
        return
      }
      this.$refs.invoiceSettle.init(this.selects)
    },
    checkImg(item){
      this.$refs.checkImg.dialog(item)
    },
    //销项自动补充
    outSetting(){
      if(this.$checkSettleStatus()) return
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      this.$refs.autoSetting.init([this.comId],'out',ids)
    },
    //重置
    cancel(){
      let originLimit = this.listQuery.limit
      let originBc = this.listQuery.bc
      this.listQuery= {
        page: 1,
        limit:originLimit,
        bc: originBc,
        rq:[],
        taxId:0,
        ssflbm:'',
        upload:0,
        voucher:0,
        js:0,
        taxRate:1,
        dpzt:2,
        openMethod:0,
        negative:0,
        invoiceType:[],
        remark:"",
        name:'',
        itemName:'',
        voucherNo:'',
        hasAutoLabel:null,
      }
      this.getList()
    },
    changeBtnLoading(){
      this.init(1)
      this.loading1 = true
      // this.buttonLoading = true
    },
    getNewList(){
      this.init(1)
      // this.buttonLoading = false
      this.loading1 = false
      this.getList()
      this.$store.dispatch("commons/getSubjects")
      this.$store.dispatch("commons/getCorp")
      this.$store.dispatch("commons/getBusinessType")
    },
    //批量下载发票影像
    batchDownload(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let param = {
        type:"out",
        invoiceIds: this.selects.map(v=>{
          return v.id
        })
      }
      invoiceDownload(param).then(res=>{
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.styleForm .el-form-item{
  margin-bottom: 5px;
}
.left_box {
  float: left;
  margin-top: 11px;
  width: 2%;
  input {
    width: 80%;
    height: 16px;
  }
}
.top-nav {
  height: 36px;
  border: 1px solid #b9b9b9;
  background: var(--themeColor,#17a2b8);
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
  position: relative;
  .box-content {
    box-sizing: border-box;
    text-align: center;
    border-left: 1px solid #b9b9b9;
    line-height: 34px;
    font-size: 13px;
    height: 34px;
  }
}
.right_box {
  float: left;
 // width: 98%;
  width: 100%;
}
.for-content {
    .box-content {
      box-sizing: border-box;
      border-left: 1px solid #b9b9b9;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
      border-bottom: 1px solid #b9b9b9;
      padding: 0 10px;
      // color: #212529;
    }
    .el-col:nth-child(10) .box-content {
      // border-bottom: none;
      border-right: 1px solid #b9b9b9;
    }
    .el-col:nth-child(1) .box-content {
      // border-bottom: none;
      border-left: none;
    }
  }
  .table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}
.content-nav {
  line-height: 36px;
  border: 1px solid #b9b9b9;
  .content-top {
    background: var(--themeColorLeftMenu,#ecf7f9);
    .box-content {
      box-sizing: border-box;
      // border-left: 1px solid #eee;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
      border-bottom: 1px solid #b9b9b9;
    }
    .box-content:first-child(1) {
      border-left: none;
    }
  }
  .for-content {
    .box-content {
      box-sizing: border-box;
      border-left: 1px solid #b9b9b9;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
      border-bottom: 1px solid #b9b9b9;
      padding: 0 10px;
      color: #212529;
    }
    .el-col:nth-child(9) .box-content {
      // border-bottom: none;
      border-right: 1px solid #b9b9b9;
    }
    .el-col:nth-child(1) .box-content {
      // border-bottom: none;
      border-left: none;
    }
  }
  .bottom-content {
    .box-content {
      box-sizing: border-box;
      border-left: 1px solid #b9b9b9;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
      color: #212529;
    }
    .el-col:nth-child(1) .box-content {
      border-left: none;
    }
    .el-col:nth-child(7) .box-content {
      border-right: 1px solid #b9b9b9;
    }
  }
}
.top_font {
  float: left;
  width: 84%;
  margin: 0 auto;
  li {
    font-size: 13px;
    color: #212529;
    float: left;
   // width: 18%;
    text-align: left;
    padding-left: 15px;
    //margin-right: 2px;
    &.big {
      // width: 260px
    }
  }
}
.top_right_btns {
  float: right;
  // width: 22%;
  margin-right: 16px;
  i {
    margin: 0 5px;
    cursor: pointer;
    // color: var(--themeColor,#17a2b8);
    font-size: 17px;
    line-height: 24px;
  }
}
.ccyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.dfyhm{
  cursor: pointer;
  border: 1px solid #c2c2c2;
  border-radius: 2px;
  outline: none;
  width: 250px;
  line-height: 20px;
  margin-top: 6px;
}

.table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}

.bank-table-header {
  background: #eee;
  position: relative;
  border: 1px solid #c2c2c2;
  color: #212529;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  height: 36px;
  line-height: 34px;
  font-size: 13px;
  > div {
    border-right: 1px solid #c2c2c2;
    text-align: center;
    flex: 1;
  }
  > div:last-child{
    border-right: 0;
  }
  input {
    position: absolute;

    zoom: 120%;
    left: 5px;
    top: 50%;
    transform: translateY(-50%) !important;
  }
}

.bank-item-container {
  margin-bottom: 10px;
  border: 1px solid #c2c2c2;
  border-bottom: 0 !important;
}
.bank-item-header {
  width: 100%;
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 20px;
  height: 36px;
  line-height: 36px;
  font-size: 13px;
  // display: flex;
  position: relative;
  border-bottom: 1px solid #c2c2c2;
  > div {
    margin-right: 30px;
    display: inline-block;
  }
  .item-input {
    position: absolute;
    left: 5px;
    zoom: 110%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.top_right_btns {
  float: right;
  // width: 22%;
  // margin-right: 15px;
  text-align: center;
  position: relative;
  i {
    margin: 0 5px;
    cursor: pointer;
    // color: var(--themeColor,#17a2b8);
    font-size: 17px;
    line-height: 24px;
  }
}

.item_box{
  width: 100%;
  font-size: 10px;
  tr{
    width: 100%;
    display: flex;
    box-sizing: border-box;
    td{
      flex: 1;
      line-height: 34px;
      border-color: #c2c2c2!important;
      border-bottom: 1px solid #c2c2c2;
      border-right: 1px solid #c2c2c2;
      padding: 0 10px;
    }
  }
}

.bank-item-header-caozuo {
  float: right;
  margin-right: 15px;
  i {
    margin: 0 5px;
    cursor: pointer;
    color: var(--themeColor,#17a2b8);
    font-size: 17px;
    line-height: 20px;
  }
}
.center_big{
  position: relative;
}
.fold_icon{
  position: absolute;
  left: 50%;
  bottom: -10px;
  z-index: 1;
  padding: 0 10px;
  border-radius: 14px;
  font-size: 11px !important;
  height: 20px;
  line-height: 20px;
  // box-shadow: 0px 0px 1px 1px #17a2b8;
}
</style>