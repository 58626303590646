<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    width="900px"
    title="发票预览"
  >
    <div class="title">
      <i
        class="iconfont icon-gantanhao1"
        style="color: #e6a23c; margin-right: 5px"
      ></i
      >本发票为原始数据，不根据发票详情内的信息进行变化！
    </div>
    <div class="pic"><img :src="imgUrl" alt="" style="width: 100%" />
      <div class="img_right" v-if="imgUrl">
        此样式仅用于发票样式预览
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" size="small" @click="upload">
          下载
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "checkImg",
  data() {
    return {
      dialogVisible: false,
      imgUrl: "",
      info: {},
    };
  },
  methods: {
    dialog(item) {
      this.imgUrl = item.ctpImg.match("https://file.jsptax.com")
        ? item.ctpImg
        : "https://file.listensoft.net" + item.ctpImg;
      this.info = item;
      this.dialogVisible = true;
    },
    upload() {
      let url = this.info.ctpPdf.match("https://file.jsptax.com")
        ? this.info.ctpPdf
        : "https://file.listensoft.net" + this.info.ctpPdf;
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  background-color: #fef6ed;
  font-size: 13px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: #333;
}
.pic {
  position: relative;
}
.img_right{
  position: absolute;
  right: -5px;
  top: 35%;
  writing-mode:tb-rl;
  letter-spacing: 3px;
}
</style>
