<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-select
          v-model="listQuery.type"
          placeholder="请选择"
          style="width: 100px; margin: 0"
          size="small"
          @change="getList"
        >
          <el-option label="全部" value=""></el-option>
          <el-option label="进项" value="in"></el-option>
          <el-option label="销项" value="out"></el-option>
        </el-select>
        <el-select
          v-model="listQuery.pdfUrl"
          size="small"
          style="width: 100px"
          @change="getList"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-input
          size="small"
          placeholder="输入发票号码/对方公司名"
          v-model="listQuery.fphm"
          style="width: 170px"
          clearable
          @keyup.enter="getList"
        ></el-input>
        <el-button type="primary" size="small" @click="getList">搜索</el-button>
        <span style="color: red" class="hint"
          >*温馨提示：只采集{{
            comType == "1" ? "当季" : "当期"
          }}的发票影像</span
        >
      </div>
      <div>
        <el-tooltip placement="top" effect="dark" content="封存发票可直接恢复">
          <el-button
            type="primary"
            plain
            size="small"
            @click="batchHf"
            class="mr-5"
            >恢复</el-button
          >
        </el-tooltip>
        <buttonCommon
          name="再次获取影像"
          @success="checkMakeSendImage()"
          :comId="comId"
          taskName="tax-cj-pdf"
          :period="period"
          @fresh="getList"
        ></buttonCommon>
        <!-- <el-button
          type="primary"
          plain
          size="small"
          @click="batchDownLoadPdf"
          :disabled="this.sels.length == 0 || !$buttonStatus('fp_fpyx_plxz')"
          >批量下载</el-button
        > -->
      </div>
    </div>
    <el-table
      :data="list"
      border
      :height="contentStyleObj"
      @select="handleSelectionChange"
      v-loading="loadingBig"
      ref="tableScroll"
      id="tableLazyLoad"
      @select-all="handleSelectionChangeAll"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column prop="fphm" label="进/销项" width="90" align="center">
        <template #default="scope">
          <span v-if="scope.row.type == 'in'">进项</span>
          <span v-if="scope.row.type == 'out'">销项</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="fphm"
        label="发票号码"
        min-width="230"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="corpName"
        label="对方公司名"
        min-width="230"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="invoiceDate"
        label="开票日期"
        min-width="130"
        align="center"
      >
        <template #default="scope">
          <span>{{ $parseTime(scope.row.invoiceDate, "{y}-{m}-{d}") }}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="address"
        label="操作"
        align="center"
        min-width="130"
      >
        <template #default="scope">
          <el-button
            @click="checkCollectInvoice(scope.row)"
            size="small"
            link
            :disabled="
              (scope.row.ctpPdf && !scope.row.pdfStatus) ||
              !scope.row.ctpPdf ||
              scope.row.ctpPdf.match('error') ||
              !$buttonStatus('fp_fpyx_yl')
            "
          >
            预览
            <span v-if="scope.row.ctpPdf && !scope.row.pdfStatus"
              >（已封存）</span
            >
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom_style">
      <div class="bottom_button"></div>
      <div class="pagination">
        <qzf-pagination
          v-show="total > 0"
          :total="total"
          v-model:page="listQuery.page"
          v-model:limit="listQuery.limit"
          @pagination="getList"
        />
      </div>
    </div>
  </div>
  <invoiceCtp ref="invoiceCtp" />
</template>

<script>
import { invoicePdfList,invoiceHf } from "@/api/invoice.js";
import { sendTask } from "@/api/collection";
import { printElecInvoice } from "@/api/printSet";
import invoiceCtp from "./components/invoiceCtp.vue";
export default {
  name: "invoicePdf",
  components: {
    invoiceCtp,
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        type: "in",
        pdfUrl: "",
      },
      total: 0,
      contentStyleObj: {}, //高度变化
      loadingBig: false,
      list: [],
      options: [
        {
          value: "yes",
          label: "有影像",
        },
        {
          value: "no",
          label: "无影像",
        },
        {
          value: "",
          label: "全部",
        },
      ],
      sels: [],
      callList: [],
      totalPage: 0,
      pageSize: 35,
      tableData: [],
      currentPage: 1,
      allChecked: false,
      comId: this.$store.getters["user/comInfo"].comId * 1,
      period: this.$store.getters["user/comInfo"].period,
      comType: this.$store.getters["user/comInfo"].comType,
    };
  },
  created() {
    this.getList();
    this.contentStyleObj = this.$getHeight(231);
  },

  methods: {
    getList() {
      this.loadingBig = true;
      invoicePdfList(this.listQuery).then((res) => {
        this.loadingBig = false;
        this.tableData = res.data.data.list ? res.data.data.list : [];
        this.total = res.data.data.total;
        this.setScroll();
      });
    },
    setScroll() {
      //先重置状态和数组
      this.allChecked = false;
      this.sels = [];
      //计算滚动页数
      this.totalPage = this.tableData.length / this.pageSize;
      if (this.tableData.length % this.pageSize == 0) {
        this.totalPage = this.tableData.length / this.pageSize;
      } else {
        this.totalPage = parseInt(this.tableData.length / this.pageSize) + 1;
      }
      this.list = this.tableData.slice(0, this.currentPage * this.pageSize);
      this.lazyLoading();
    },
    lazyLoading() {
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this;
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if (that.allChecked && that.sels.length == that.tableData.length) {
              that.$refs.tableScroll.toggleAllSelection();
              that.allChecked = true;
            } else if (that.sels.length != 0) {
              that.sels.map((v) => {
                that.list.map((item) => {
                  if (item.id == v.id) {
                    that.$nextTick(() => {
                      that.$refs.tableScroll.toggleRowSelection(item, true);
                    });
                  }
                });
              });
            }
          }
        }
      });
    },
    checkCollectInvoice(item) {
      this.$refs.invoiceCtp.init(item);
    },
    // 批量下载发票影像
    batchDownLoadPdf() {
      let ids = [];
      this.sels.map((v) => {
        ids.push(v.id);
      });
      printElecInvoice({
        type: this.listQuery.type,
        invoiceIds: ids,
      }).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
    //多选
    handleSelectionChange(val) {
      if (this.allChecked && this.list.length != this.tableData.length) {
        let currentIndex = 0;
        this.list.map((v, i2) => {
          val.map((e) => {
            if (e.id == v.id) {
              currentIndex = i2;
            }
          });
        });
        let arr = this.tableData.slice(currentIndex + 1, this.tableData.length);
        let newArr = [...val, ...arr];
        this.sels = newArr;
      } else {
        this.sels = val;
      }
    },
    handleSelectionChangeAll(e) {
      if (e.length != 0) {
        this.sels = this.tableData;
        this.allChecked = true;
      } else {
        this.sels = [];
        this.allChecked = false;
      }
    },
    checkMakeSendImage() {
      this.loadingBig = true;
      let param = {
        taskName: "tax-cj-pdf",
        comIds: [this.comId],
        period: this.period,
      };
      sendTask(param).then((res) => {
        this.loadingBig = false;
        if (res.data.msg == "success") {
          this.$qzfMessage("任务已发起", 3);
          this.getList();
          this.$queueMessage(res.data.data.queue);
        }
      });
    },
    //批量恢复
    batchHf(){
      if(this.sels.length == 0){
        this.$qzfMessage("请选择要恢复的发票",1)
        return
      }
      let fail = this.sels.find(v=> v.ctpPdf && v.pdfStatus)
      if(fail){
        this.$qzfMessage("请选择封存影像",1)
        return
      }
      let numbers = this.sels.map(v=>{return  v.fphm})
      invoiceHf(numbers).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage('任务已发起')
          this.getList()
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.hint {
  font-size: 14px;
  color: red;
  margin-left: 5px;
}
</style>
