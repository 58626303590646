<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      destroy-on-close
      title="打印设置"
      v-model="dialogFormVisible"
      class="button_bg"
    >
      <el-form ref="dataForm" label-position="right" label-width="110px">
        <el-form-item label="明细设置：">
          <el-radio-group v-model="type">
            <el-radio label="">单张打印销售明细</el-radio>
            <el-radio label="merge">合并单位打印销售明细</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small"
            >取消</el-button
          >
          <el-button type="primary" @click="print()" size="small"
            >确定</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { dayinSell } from "@/api/printSet";
export default {
  props: {},
  data() {
    return {
      type: "",
      dialogFormVisible: false,
      comIds: [],
    };
  },
  methods: {
    init(ids) {
      this.comIds = ids;
      this.dialogFormVisible = true;
    },
    print() {
      let ids = this.comIds;
      let type = this.type;
      dayinSell({ ids,type }).then((res) => {
        this.dialogFormVisible = false;
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
