<template>
  <div class="app-container">
    <div style="justify-content: space-between;">
      <search @success="getList" @cancel="cancel">
          <el-form style="width:405px" class="styleForm" label-width="100px" :inline="true" size="small">
            <el-form-item label="凭证状态：">
              <el-radio-group v-model="listQuery.voucher">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已生成</el-radio>
                <el-radio :label="-1">未生成</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="费用类型：">
              <subject-list-all style="width: 185px;"  v-model:subjectId="listQuery.subjectId"></subject-list-all>
            </el-form-item>
            <el-form-item label="对方账户名：">
              <el-input v-model="listQuery.corpName" placeholder="请输入对方账户名"  style="width:185px;margin:0"></el-input>
            </el-form-item>
            <el-form-item label="凭证号：">
              <el-input v-model="listQuery.voucherNo" placeholder="请输入凭证号"  style="width:185px;margin:0"></el-input>
            </el-form-item>
            <el-form-item label="摘要">
              <el-input v-model="listQuery.summary" placeholder="请输入摘要"  style="width:185px;margin:0"></el-input>
            </el-form-item>
          </el-form>
        </search>
        <div class="fr" style="margin-bottom:10px">
        <!-- <qzf-video vid="4467be5374b98db0c91543e361cfc711_4"></qzf-video> -->
        <el-dropdown split-button type="success" @click="feeAdd" size="small" style="margin-right:10px" :hide-on-click="false" v-if="$buttonStatus('fp_fy_tj')">
          <i class="iconfont icon-jiahao"></i> 添加
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <span size="small" @click="openCom">费用导入</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button size="small" plain type="danger" @success="delAll" button_code="fp_fy_sc" >
          <el-icon><Delete /></el-icon><span  > 删除</span>
        </qzf-button>
        <qzf-button size="small" type="success" @success="saveAll" button_code="fp_fy_tj" >
          <el-icon><Finished /></el-icon><span  > 批量保存</span>
        </qzf-button>
        <!-- <settings v-if="$buttonStatus('fp_fy_sz')" :type="['code_fee_1001']"></settings> -->
        <el-dropdown @command="handleCommand" size="small" style="margin-left:10px">
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="a" v-if="$buttonStatus('fp_fy_yjscpz')">
                <span>一键生成凭证</span>
              </el-dropdown-item>
              <el-dropdown-item command="i" @click="invoiceSort()" v-if="$buttonStatus('fp_fy_px')">
                <span >费用排序</span>
              </el-dropdown-item>
              <el-dropdown-item command="b" @click="daochu()">
                <span >导出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="top_title">
      <!-- <div class="left_box" @click="allSelect">
        <input type="checkbox" :checked="isAllSelected" />
      </div> -->
      <div class="top-nav right_box">
        <el-row>
        <el-col :span="4">
          <div style="display: flex;align-items: center;padding-left:10px;">
            <input type="checkbox" :checked="isAllSelected" :style="{marginLeft:0}" @click="allSelect"/>
            <div class="box-content" style="border-left:none;width: 80%;">日期</div>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="box-content">费用类型</div>
        </el-col>
        <el-col :span="3">
          <div class="box-content">全部</div>
        </el-col>
        <el-col :span="3">
          <div class="box-content">现金结算金额</div>
        </el-col>
        <el-col :span="3">
          <div class="box-content">单位名称</div>
        </el-col>
        <el-col :span="3">
          <div class="box-content">摘要</div>
        </el-col>
        <el-col :span="2">
          <div class="box-content">附件数</div>
        </el-col>
        <el-col :span="3">
          <div class="box-content">操作</div>
        </el-col>
        </el-row>
      </div>
    </div>
    <!-- <div class="table_height" :style="{height:contentStyleObj}" v-loading="loadingBig"> -->
      <div v-loading="loadingBig"> 
        <el-scrollbar :style="{height:contentStyleObj}"> 
      <el-empty :image-size="150" v-if="this.list.length == 0"></el-empty>

      <div class="content_center" style="margin-bottom:10px;" v-for="(item,index) in list" :key="index">
        <!-- <div class="left_box fl" @click="singleSelect(item, index)"> -->
        <!-- <div class="left_box fl" @click="singleSelect(item,index)">
          <input type="checkbox" :checked="item.checked"/>
        </div> -->
        <!-- </div> -->
        <div class="content-nav right_box fl">
          <el-row class="content-top">
            <el-col :span="24" class="box-content clearfix">
              <ul class="top_font clearfix">
                <li style="padding-left: 10px;" @click="singleSelect(item,index)">
                  <input type="checkbox" :checked="item.checked" :style="{marginLeft:0,marginTop:'10px'}"/>
                </li>
                <li style="width:150px">费用金额:
                  <span style="color:#39b0d2">{{item.sumAmount}}</span>
                </li>
                <li>凭证号:
                  <span v-if="item.voucherNo" @click="getHandlePingzheng(item.voucherId)" style="color:#39b0d2;text-decoration:underline;cursor:pointer">{{ item.voucherNo }}</span>
                  <span v-else-if="$buttonStatus('fp_fy_yjscpz')" @click="showVoucher(item)" style="color:#39b0d2;text-decoration:underline;cursor:pointer" >凭证预览</span>
                </li>
              </ul>
              <div class="top_right_btns">
                <el-tooltip content="保存" placement="top" v-if="$buttonStatus('fp_fy_tj')" effect="dark">
                  <i @click="savefee(item)" class="iconfont icon-baocun"></i>
                </el-tooltip>
                <!-- 可能用不要删 -->
                <el-tooltip content="编辑" placement="top" v-if="$buttonStatus('fp_fy_bj')" effect="dark">
                  <i @click="goInfo(item)" class="iconfont icon-bianji" ></i>
                </el-tooltip>
                <el-tooltip content="删除" placement="top" v-if="$buttonStatus('fp_fy_sc')" effect="dark">
                  <i @click="handleDeleteBig(item)" class="iconfont icon-shanchu"></i>
                </el-tooltip>
              </div>
            </el-col>
          </el-row>
          <div class="bottom-nav" v-for="(itemz,index1) in item.items" :key="index1">
            <el-row>
            <el-col :span="4">
              <el-date-picker type="date" :disabled="item.edit" v-model="itemz.feeDate" placeholder="选择日期时间" size="small" style="width:90%"></el-date-picker>
            </el-col>
            <el-col :span="3">
              <subject-list v-model:subjectId="itemz.subjectId" :disabledIn="item.edit" width="90%" v-model:fzhsItemId="itemz.fzhsId"></subject-list>
            </el-col>
            <el-col :span="3">
              <qzf-input placeholder="请输入金额" :disabled="item.edit" v-model:num="itemz.amount" size="small"></qzf-input>
            </el-col>
            <el-col :span="3">
              <qzf-input placeholder="请输入金额" :disabled="item.edit" v-model:num="itemz.settleAmt" size="small"></qzf-input>
              
            </el-col>
            <el-col :span="3">
              <corp-list style="border:1px solid " v-model:corpId="itemz.corpId" :disabledIn="item.edit" type="1"></corp-list>
            </el-col>
            <el-col :span="3">
              <qzf-input placeholder="请输入内容" :disabled="item.edit" v-model="itemz.remark" size="small"></qzf-input>
            </el-col>
            <el-col :span="2">
              <qzf-input placeholder="请输入内容" :disabled="item.edit" v-model:num="itemz.count" size="small" ></qzf-input>
            </el-col>
            <el-col :span="3">
              <div class="top_right_btn2">
                <el-tooltip content="新增" placement="top" v-if="$buttonStatus('fp_fy_tj')" effect="dark">
                  <i class="iconfont icon-jiahao" @click="addItem(index,index1)"></i>
                </el-tooltip>
                <!-- <el-tooltip content="编辑" placement="top" >
                  <i @click="goInfo(item.id)" class="iconfont icon-bianji" ></i>
                </el-tooltip> -->
                <el-tooltip content="删除" placement="top" v-if="$buttonStatus('fp_fy_sc')" effect="dark">
                  <i @click="handleDelete(item,itemz,index1)" class="iconfont icon-shanchu"></i>
                </el-tooltip>
              </div>
            </el-col>
            </el-row>
          </div>
        </div>
      </div>
      </el-scrollbar>
      </div>
    <!-- </div> -->

    <div class="bottom_style">
      <div class="bottom_button">

      </div>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>
    
    <voucher-show ref="voucherShow" from="3" @success="getList"></voucher-show>
    <qzf-import-back :hideButton="true" ref="importBck" @success="getList()" from="费用"></qzf-import-back>
    <changeSortList ref="changeSortList" @success="changeSort" remark="feeSort"></changeSortList>


  </div>
</template>

<script>
import {feeList,feeSave,feeDele,feeExport} from '@/api/fee.js'
import { getPeriodTime } from "@/utils/fun";
import { oneKeyVoucher } from '@/api/voucher'
import changeSortList from "./components/changeSortList.vue"


export default {
  name:'fee',
  components: {
    changeSortList
  },
  data() {
    return {
      selects: [],
      input:'',
      listQuery: {
        page: 1,
        limit: 20,
        voucher:null,
        subjectId:null,
        corpName:'',
        voucherNo:'',
        summary:'',
      },
      corpId: {
        type: Number,
        default: 0
      },
      total: 0,
      list: [],
      itemShow:false,
      contentStyleObj:{}, //高度变化
      loadingBig: false,
      comId:this.$store.getters['user/comInfo'].comId*1,
    }
  },
  created() {
    this.getList()
    this.contentStyleObj=this.$getHeight(300)
  },
  computed: {
    // 是否全选
    isAllSelected() {
      if(this.list.length == 0){
        return false
      }
      return this.list.every(el => {
        return el.checked;
      });
    },
  },
  methods: {
    //单选
    singleSelect(row,index) {
      row.checked = !row.checked;
      this.list.splice(index, 1, row);
      this.screenChecked()
    },
    // 全选、全不选
    allSelect() {
      let checked = true;
      // 全选
      if (this.isAllSelected) {
        checked = false;
      }
      this.list = this.list.map(el => {
        el.checked = checked;
        return el;
      });
      this.screenChecked()
    },
    screenChecked(){
      this.selects = this.list.filter(v=>{return v.checked})
    },
    //新增一行
    feeAdd() {
      if(this.$checkSettleStatus()) return
      this.list.unshift({
        sumAmount:0,
        checked: false,
        edit:false,
        items:[{
          feeDate:getPeriodTime(),
          amount: 0,
          settleAmt: 0,
          remark:'',
          subjectId: 0,
          fzhsId: 0,
          count: 0
        }],
      })
    },
    //保存
    savefee(item) {
      if(this.$checkSettleStatus()) return
      feeSave([item]).then(res => {
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.$bus.emit('voucherUpdate')

          item.edit = true
          this.getList()
        }
      })
    },
    //里边新增一行
    addItem(index,itemIndex) {
      if(this.$checkSettleStatus()) return
      this.list[index].items.splice(itemIndex + 1, 0, {
        feeDate:getPeriodTime(),
        amount: 0,
        settleAmt: 0,
        remark:'',
        subjectId: 0,
        fzhsId: 0,
        count: 0
      })
    },
    //里边删除一行
    handleDelete(item, row, index) {
      if(this.$checkSettleStatus()) return
      item.items.splice(index, 1);
    },
    //展示列表
    getList(){
      this.loadingBig = true
      feeList(this.listQuery).then(res=>{
        this.loadingBig = false
        if(res.data.data.list){
          let newList = res.data.data.list
          newList.map(v=>{
            v['edit'] = true
          })
          this.list = newList
          this.total = res.data.data.total
        }else{
          this.list = []
          this.total = 0
        }
      })
    },
    cancel(){
      this.listQuery = {
        page: 1,
        limit: 20,
        voucher:null,
        subjectId:null,
        corpName:'',
        voucherNo:'',
        summary:'',
      }
      this.getList()
    },
    goInfo(item){
      if(this.$checkSettleStatus()) return
      item.edit = false
    },
    showVoucher(item){
      this.$refs.voucherShow.getList(item)
    },
    handleCommand(command) {
      if(command == 'a'){
        if(this.$checkSettleStatus()) return
        if(this.selects.length == 0){
          this.$qzfMessage("请选择至少一条数据",1)
          return
        }
        let invoiceArray = []
        this.selects.map(v => {
          invoiceArray.push(v.id)
        })
        this.loadingBig = true
        oneKeyVoucher({type:'fee',ids:invoiceArray}).then(res=>{
        this.loadingBig = false
          if(res.data.msg == "success"){
            this.$qzfMessage("操作成功")
            this.$bus.emit('voucherUpdate')
            this.getList()
          }
        })
      }
    },
    delAll(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let delSelects = []
      this.selects.map(v=>{
        delSelects.push({
          id:v.id
        })
      })
      this.feeDel(delSelects)
    },
    handleDeleteBig(row){
      if(this.$checkSettleStatus()) return
      this.feeDel([{id: row.id}])
    },
    feeDel(ids){
      this.$confirm(`确认删除${ids.length}条数据么? `, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(()=>{
        feeDele(ids).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.$bus.emit('voucherUpdate')
            this.getList()
          }
        })
      })
    },
    // 费用导入
    openCom(){
      if(this.$checkSettleStatus()) return
      this.$refs.importBck.handImport()
    },
    getHandlePingzheng(voucherId) {
      this.$store.dispatch('commons/getSubjects', this.comId)
      this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher"
      });
    },
    // 发票记录排序
    invoiceSort(){
      this.$refs.changeSortList.dialogVisible = true
    },
    changeSort(val){
      this.listQuery.sortType = val.radioSort
      this.listQuery.sortField = val.sortMethod
      this.getList()
    },
    saveAll(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      feeSave(this.selects).then(res => {
        if(res.data.msg == "success"){
          this.selects.map(v=>{
            v.edit = true
          })
          this.$qzfMessage("保存成功")
          this.getList()
        }
      })
    },
    daochu(){
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      let param = {
        query:this.listQuery,
        ids: ids
      }
      feeExport(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.addLis {
  display: flex;
  justify-content: right;
  margin-right: 60px;
}
.left_box {
  float: left;
  width: 2%;
  input {
    width: 80%;
    height: 16px;
  }
}
.right_btns{
  width: 3%;
  float: right;
  text-align: right;
  margin-bottom: 10px;
}
.top_title {
  display: flex;
  align-items: center;
  width: 100%;
}
.right_box {
  //width: 99%;
  width: 100%;
}
.top-nav {
  height: 36px;
  border: 1px solid #c2c2c2;
  background: var(--themeColor,#17a2b8);
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
  position: relative;
  .box-content {
    box-sizing: border-box;
    text-align: center;
    border-left: 1px solid #c2c2c2;
    line-height: 34px;
    font-size: 13px;
    height: 34px;
  }
}
.bottom-nav{
  height: 36px;
  font-weight: 500;
  position: relative;
  .box-content {
    box-sizing: border-box;
    text-align: center;
    border-left: 1px solid #c2c2c2;
    line-height: 34px;
    font-size: 13px;
    height: 34px;
  }
  
}
.table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}
.content_center {
  display: flex;
  align-items: center;
}
.content-nav {
  line-height: 36px;
  border: 1px solid #c2c2c2;
  .content-top {
    background: var(--themeColorLeftMenu,#ecf7f9);
    .box-content {
      box-sizing: border-box;
      // border-left: 1px solid #eee;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
      border-bottom: 1px solid #c2c2c2;
    }
    .box-content:first-child(1) {
      border-left: none;
    }
  }
  .for-content {
    .box-content {
      box-sizing: border-box;
      border-left: 1px solid #c2c2c2;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
      border-bottom: 1px solid #c2c2c2;
      padding: 0 10px;
    }
    .el-col:nth-child(9) .box-content {
      // border-bottom: none;
      border-right: 1px solid #c2c2c2;
    }
    .el-col:nth-child(1) .box-content {
      // border-bottom: none;
      border-left: none;
    }
  }
  .bottom-content {
    .box-content {
      box-sizing: border-box;
      border-left: 1px solid #c2c2c2;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
    }
    .el-col:nth-child(1) .box-content {
      border-left: none;
    }
    .el-col:nth-child(7) .box-content {
      border-right: 1px solid #c2c2c2;
    }
  }
}
.top_font {
  float: left;
  width: 83%;
  margin: 0 auto;
  li {
    font-size: 13px;
    color: #333;
    float: left;
   // width: 18%;
    text-align: left;
    padding-left: 15px;
    margin-right: 10px;
    &.big {
      // width: 260px
    }
  }
}
.top_right_btns {
  float: right;
  margin-right:40px;
  i {
    margin: 0 5px;
    cursor: pointer;
    color: var(--themeColor,#17a2b8);
    font-size: 17px;
    line-height: 24px;
  }
}
.top_right_btn2 {
  float: right;
  margin-right: 40px;
  i {
    margin: 0 5px;
    cursor: pointer;
    color: var(--themeColor,#17a2b8);
    font-size: 17px;
    line-height: 24px;
  }
}
.border_none .el-input__inner {
    // border-color: #c2c2c2 !important;
}
.el-col-3 {
  border-right: 1px solid #cfc2c2;
  text-align: center;
}
.el-col-2 {
  border-right: 1px solid #cfc2c2;
  text-align: center;
}
.el-col-4 {
  border-right: 1px solid #cfc2c2;
  text-align: center;
}
.el-col-3 .el-input {
  width: 90%;
}
.el-col-2 .el-input {
  width: 90%;
}
.select-trigger .el-input {
  border: 1px solid #eee !important;
}
</style>

