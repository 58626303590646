<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    width="90%"
    title="影像预览"
  >
    <div class="title">
      <i
        class="iconfont icon-gantanhao1"
        style="color: #e6a23c; margin-right: 5px"
      ></i
      >本发票为原始数据，不根据发票详情内的信息进行变化！发票影像数据仅保留三个月！
    </div>
    <iframe
      style="position: relative"
      :src="url"
      frameborder="0"
      id="mainiframe"
      width="100%"
      :height="contentStyleObj2"
      border="0"
    >
    </iframe>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" size="small" @click="dialogVisible = false">
          确 定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      contentStyleObj2: {},
      url: "",
    };
  },
  mounted() {
    this.contentStyleObj2 = this.$getHeight(270);
  },
  methods: {
    init(row, flag) {
      this.url = row.ctpPdf.match("https://file.jsptax.com")
        ? row.ctpPdf
        : "https://file.listensoft.net" + row.ctpPdf;
      this.dialogVisible = true;
      var iframe = document.getElementById("mainiframe");
      iframe.src = this.url;
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  background-color: #fef6ed;
  font-size: 13px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: #333;
}
</style>
