<template>
  <el-select :class="{border_none:borderNone}" filterable v-model="id" placeholder="选择税目" size="small" clearable>
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>
<script>
export default {
  props:{
    taxId: {
      type: Number,
      default: 0
    },
    borderNone:{
      type:Boolean,
      default:false
    },
    rate:{
      type: String,
      default: ""
    }
  },
  computed: {
    options(){
      if(!this.rate){
        return this.$store.getters["commons/taxCategorys"]
      }else{
        let matchText = String(this.rate*100)
        let list = this.$store.getters["commons/taxCategorys"] ? this.$store.getters["commons/taxCategorys"] : []
        let newList = list.filter((item)=>{
          return item.name.indexOf(matchText) > -1
        })
        return newList
      }
    },
    id: {
      get() {
        if(!this.taxId){
          return ""
        }
        return this.taxId
      },
      set(val) {
        this.$emit("update:taxId", Number(val))
        let info = {}
        this.options.map(v=>{
          if(v.id == val){
            info = v
          }
        })
        this.$emit("update:taxRate", info.rate)
        this.$emit("success", val,info.rate)
        this.$emit("success2")
      }
    }
  },
}
</script>