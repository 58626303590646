<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    width="60%"
    title="发票预览"
  >
    <div class="title">
      <i
        class="iconfont icon-gantanhao1"
        style="color:#e6a23c;margin-right: 5px;"
      ></i
      >本发票数据来源为原始数据，不根据发票详情内的信息进行变化！
    </div>
    <el-scrollbar :maxHeight="contentStyleObj">
      <!-- <div v-for="(item, index) in imgUrl" :key="index">
        <img :src="item" alt="" style="width: 100%;" />
      </div> -->
      <el-carousel :class="changeIndex==0?'leftBtnNone':changeIndex==imgUrl.length-1? 'rightBtnNone':''"   :autoplay="false" trigger="click" height="700px" @change="carousel" >
        <el-carousel-item v-for="item in imgUrl" :key="item">
          <el-image :src="item" style="width: 100%;"></el-image>
        </el-carousel-item>
      </el-carousel>
    </el-scrollbar>
    <template #footer>
      <div style="text-align: center">
        {{ changeIndex + 1 }} / {{ imgUrl.length }} 张
      </div>
      <span class="dialog-footer">
        <el-button type="primary" size="small" @click="upload">
          下载
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "checkPdf",
  data() {
    return {
      dialogVisible: false,
      imgUrl: "",
      info: {},
      contentStyleObj:{},
      changeIndex:0,
    };
  },
  mounted(){
    this.contentStyleObj = this.$getHeight(300)
  },
  methods: {
    carousel(val){
      this.changeIndex=val
      if(this.imgUrl.length-1 == val){
        this.$qzfMessage("已经是最后一张了", 1) 
      }
      if(val==0){
        this.$qzfMessage("当前是第一张", 1) 
      }
    },
    dialog(item) {
      if (item.image == "error: PDF文件路径错误") {
        this.$qzfMessage("发票影像打开失败", 1);
        return;
      }
      if (item.image.indexOf(";") == -1) {
        this.imgUrl = ["https://file.listensoft.net" + item.image];
      } else {
        this.imgUrl = []
        let arr = item.image.split(";");
        arr.map(v=>{
          this.imgUrl.push("https://file.listensoft.net" + v)
        })
      }
      this.info = item;
      this.dialogVisible = true;
    },
    upload() {
      window.open("https://file.listensoft.net" + this.info.pdfUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  background-color: #fef6ed;
  font-size: 13px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: #333;
}
.leftBtnNone {
  :deep(.el-carousel__arrow--left) {
        display: none !important;
    }
}
.rightBtnNone {
  :deep(.el-carousel__arrow--right) {
        display: none !important;
    }
}
</style>
