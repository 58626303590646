<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="批量修改到票状态"
    width="30%"
    destroy-on-close
  >
    <el-form :model="temp">
      <el-form-item label="到票状态：" :label-width="formLabelWidth">
        <el-switch
          v-model="temp.dpzt"
          size="small"
          :active-value="0"
          :inactive-value="1"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="saveStatus()"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { invoiceDpzt } from "@/api/invoice";
export default {
  name: "",
  components: {},
  props: {
    type: String,
    default: "",
  },
  data() {
    return {
      ids: [],
      temp: {
        dpzt: 0,
      },
      dialogFormVisible: false,
    };
  },
  methods: {
    init(e) {
      this.ids = e;
      this.dialogFormVisible = true;
    },
    saveStatus() {
      let param = {
        type: this.type,
        ids: this.ids,
        dpzt: this.temp.dpzt,
      };
      invoiceDpzt(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("修改成功");
          this.dialogFormVisible = false;
          this.$emit("success");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
