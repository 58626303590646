<template>
  <el-dialog title="查看统计" v-model="dialogVisible" width="1000px">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <div style="margin-bottom: 15px">
        <el-date-picker
          style="width: 100px"
          size="small"
          value-format="YYYYMM"
          v-model="listQuery.beginPeriod"
          type="month"
          placeholder="起始日期"
        >
        </el-date-picker>
        --
        <el-date-picker
          style="width: 100px"
          size="small"
          value-format="YYYYMM"
          v-model="listQuery.endPeriod"
          type="month"
          placeholder="终止日期"
        >
        </el-date-picker>
        <el-button
          size="small"
          type="primary"
          style="margin-left: 10px"
          @click="getList"
          >查询</el-button
        >
      </div>
      <el-tab-pane label="发票类型统计" name="first">
        <el-table
          :data="dataStatistics"
          style="width: 100%"
          border
          @selection-change="handleSelectionChange"
          show-summary
          :summary-method="getSummaries"
          v-loading="loading"
        >
          <el-table-column
            align="center"
            prop="id"
            type="selection"
            width="55"
          />

          <el-table-column
            prop="inOutName"
            label="类型"
            min-width="250"
            align="center"
          />
          <el-table-column
            prop="count"
            label="数量"
            width="150"
            align="center"
          />
          <el-table-column
            prop="sum_amount"
            label="金额"
            width="150"
            align="center"
          />
          <el-table-column
            prop="sum_tax"
            label="税额"
            width="150"
            align="center"
          />
          <el-table-column
            prop="sum_total"
            label="价税合计"
            width="150"
            align="center"
          />
        </el-table>
        <div
          class="bottom_button"
          style="float: left"
          v-if="dataInfo.length != 0"
        >
          <i
            class="iconfont icon-gantanhao"
            style="color: #e6a23c; margin-right: 5px; font-size: 13px"
          ></i
          >统计： &nbsp;发票份数：{{ infoCount }}份 &nbsp;金额：{{
            infoAmount
          }}
          &nbsp; 税额：{{ infoTax }} &nbsp;价税合计：{{ infoTaxAmount }}
        </div>
      </el-tab-pane>
      <el-tab-pane label="业务类型统计" name="second">
        <el-table
          :data="list"
          style="width: 100%"
          border
          show-summary
          v-loading="loading"
          :height="450"
        >
          <el-table-column prop="inOutName" label="开票项目" align="left" />
          <el-table-column prop="count" label="数量" align="center" />
          <el-table-column prop="sum_amount" label="金额" align="center" />
          <el-table-column prop="sum_tax" label="税额" align="center" />
          <el-table-column prop="sum_total" label="价税合计" align="center" />
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false" size="small">
          关闭
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { inOutSum, inOutSumRemark } from "@/api/invoice";
import { ref } from "vue";
const dialogVisible = ref(false);
const listQuery = ref({
  beginPeriod: "",
  endPeriod: "",
});
const activeName = ref("first");
const total = ref({
  count: 0,
  sum_amount: 0,
  sum_tax: 0,
  sum_total: 0,
});
const list = ref([]);
const dataStatistics = ref([]);
const dataInfo = ref([]);
const infoCount = ref(0);
const infoAmount = ref(0);
const infoTax = ref(0);
const infoTaxAmount = ref(0);
const loading = ref(false);
const init = () => {
  dialogVisible.value = true;
  getList();
};
const getList = () => {
  loading.value = true;
  if (activeName.value == "first") {
    inOutSum(listQuery.value).then((res) => {
      loading.value = false;
      let info = res.data.data;
      total.value = res.data.data.inSum;
      let arr = [];
      arr.push(
        info.inSum1,
        info.inSum9,
        info.inSum2,
        info.inSum3,
        info.inSum4,
        info.inSum5,
        info.inSum11,
        info.inSum6,
        info.inSum10,
        info.inSum7,
        info.inSum8
      );
      dataStatistics.value = arr;
    });
  } else {
    inOutSumRemark(listQuery.value).then((res) => {
      loading.value = false;
      list.value = res.data.data.inSum ? res.data.data.inSum : [];
    });
  }
};
const handleClick = (tab, event) => {
  activeName.value = tab.props.name;
  getList();
};
const handleSelectionChange = (e) => {
  dataInfo.value = e;
  if (dataInfo.value.length != 0) {
    infoCount.value = 0;
    infoAmount.value = 0;
    infoTax.value = 0;
    infoTaxAmount.value = 0;
    dataInfo.value.map((v) => {
      infoCount.value += v.count;
      infoAmount.value += v.sum_amount;
      infoTax.value += v.sum_tax;
      infoTaxAmount.value += v.sum_total;
    });
    infoAmount.value = infoAmount.value.toFixed(2);
    infoTax.value = infoTax.value.toFixed(2);
    infoTaxAmount.value = infoTaxAmount.value.toFixed(2);
  }
};
const getSummaries = (param) => {
  let { columns, data } = param;
  data = total.value;
  let countData = 0;
  let amountData = 0;
  let taxData = 0;
  let totalData = 0;
  countData = data.count;
  amountData = data.sum_amount;
  taxData = data.sum_tax;
  totalData = data.sum_total;
  const sums = [];
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = "合计";
      return;
    }
    if (column.property == "count") {
      sums[index] = countData;
    } else if (column.property == "sum_amount") {
      sums[index] = amountData;
    } else if (column.property == "sum_tax") {
      sums[index] = taxData;
    } else if (column.property == "sum_total") {
      sums[index] = totalData;
    } else {
      sums[index] = "";
    }
  });
  return sums;
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.content_table {
  border: 1px solid #c2c2c2;
  width: 100%;
  border-right: none;
  border-bottom: none;
  td {
    border-bottom: 1px solid #c2c2c2;
    border-right: 1px solid #c2c2c2;
    text-align: center;
    line-height: 32px;
  }
}
</style>
