<template>
  <el-dialog :close-on-click-modal="false" title="批量补充业务类型" v-model="dialogVisible" width="400px" >
    <el-form ref="ruleForm" :model="temp" label-width="160px" class="form_style" size="small" label-position="right" >
      <el-form-item v-if="dialogVisible" label="业务类型：" style="width:90%" >
        <subject-list v-model:subjectId="temp.subjectId" v-model:suibjectSelectName="temp.subjectName" v-model:fzhsItemId="temp.fzhsId" :codes="$findCode(['code1606','code5001','code5051','code5052','code5053','code5301','code4901'])"></subject-list>
      </el-form-item>
      <el-form-item label="货物或应税劳务、服务：" style="width:90%" >
        <inventory-list  v-model:inventoryId="temp.itemId" v-model:itemName="temp.itemName" v-model:inventoryType="temp.itemType" :type="fw"></inventory-list>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <qzf-button :loading="loading" type="primary" @success="handleAddSure" size="small">确 定</qzf-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>

export default {
  data () {
    return {
      fw: true,
      loading: false,
      dialogVisible: false,
      temp: {
        subjectId: 0,
        fzhsId: 0,
        itemId: 0,
        itemType: '',
        itemName: '',
        subjectName: ''
      },
      rules: {
        subjectId: [
          { required: true, message: '请选择收入科目：', trigger: 'change' },
        ],
        itemId: [
          { required: true, message: '请选择存货/服务', trigger: 'change' },
        ],
      },
    }
  },
  methods: {
    init(fw){
      this.fw = fw
      this.temp.subjectId = 0
      this.temp.fzhsId = 0
      this.temp.itemId = 0
      this.temp.itemType = ""
      this.temp.itemName = ""
      this.temp.subjectName = ''

      setTimeout(() => {
        this.dialogVisible = true
      })
    },
    handleAddSure(){
      this.$refs['ruleForm'].validate((valid) => {
        //console.log(valid);
        if (valid) {
          // if(this.temp.subjectId == 0 ){
          //   this.$qzfMessage("请选择收入科目：", 1)
          //   return
          // }
          // if( this.temp.itemId == 0){
          //   this.$qzfMessage("请选择存货/服务", 1)
          //   return
          // }
          this.$emit("success", this.temp)
          this.dialogVisible = false
        } else {
          // //console.log('error submit!!')
          return false
        }
      })

      
     
    }
  }
}
</script>