<template>
  <div class="select-inventory-list-container">
    <div class="text-box">
      <span class="ellipsis">{{ subjectName }}</span>
      <el-icon><ArrowDown /></el-icon>
    </div>
    <div ref="renderBoxRef" class="render-box" @click="renderSelect"></div>
  </div>
</template>
<script setup lang="jsx">
import { defineProps, computed, getCurrentInstance, ref, withModifiers, createApp } from 'vue'
// import { ElTooltip } from 'element-plus'
import { ArrowDown } from '@element-plus/icons-vue'
import { Select as AntdSelect } from 'ant-design-vue';

const {proxy: vm} = getCurrentInstance()
const props = defineProps({
  width: {
      type: String,
      default: '200'
    },
    codes: {//默认下拉框的code
      type: Array,
      default: []
    },
    subjectId: {
      type: Number,
      default: 0
    },
    fzhsItemId: {
      type: Number,
      default: 0
    },
    subjectName: {//新增科目的名字
      type: String,
      default: "",
    },
    borderNone:{
      type:Boolean,
      default:false
    },
    addNotShow:{
      type:Boolean,
      default:false
    },
    disabledIn:{
      type:Boolean,
      default:false
    },
    placeholder: {//默认下拉框的code
      type: String,
      default: "选择会计科目"
    },
    index: {
      type: Number,
      default: 0
    },
    size:{
      type: String,
      default: "small",
    },
    disabledAdd:{
      type:Boolean,
      default:false
    },
    comId: {
      type: Number,
      default: 0
    },
    spec:{
      type: String,
      default: ''
    },
})

const inventorySelectRef = ref()
const renderBoxRef = ref()
const selectApp = ref()

const subjectListOldList = computed({
  get(){
    let list = vm.$subjectFilterCode(props.codes, 1)
    let ls = list.map((v)=> ({
      value:v.sign,
      label:v.fzhsItemName?v.name3 + '（'+v.fzhsItemName+'）' :v.name3,
      name:v.name3,
      label2:v.fzhsItemName,
      disabled:v.disabled,
    }))
    if(!props.disabledAdd && vm.$buttonStatus('kjkm_xz')){
      ls.unshift({
      name: '新增+',//不填 新增存货
      value:'add-0',
      label:"新增"
    })
    }
    if(!ls){
      return []
    }
    return ls
  }
})
const id = computed({
  get() {
    // 修改了不显示placeHolder得问题
    if(!props.subjectId){
      return undefined
    }
    return {value: props.subjectId + "-" + props.fzhsItemId, label: props.subjectName}
  },
  set({value: val, label}) {
    if(val == 'add-0'){
      let data = {
        id: undefined,
        name: "",
        type: 0,
      }
      vm.$emit('addSubject', {data, codes: props.codes})
      vm.$emit("update:subjectId", 'add-0')
      vm.$emit("update:subjectName", '')
      setTimeout(() => {
        vm.$emit("update:subjectId", val.split('-')[0] * 1)
      }, 1);
      return
    }
    if(val){
      vm.$emit("update:subjectName", label)
      vm.$emit("update:subjectId", val.split('-')[0] * 1)
      vm.$emit("update:fzhsItemId", val.split('-')[1] * 1)
    }else{
      vm.$emit("update:subjectName", '')
      vm.$emit("update:subjectId", 0)
      vm.$emit("update:fzhsItemId", 0)
    }
    vm.$emit("success", val)
  }
  
})

function renderSelect() {
  selectApp.value = createApp({
    render() {
      return <AntdSelect
        ref={inventorySelectRef}
        size="small"
        class={{border_none:props.borderNone}}
        v-model:value={id.value}
        filterable
        show-search
        options={subjectListOldList}
        placeholder ="选择会计科目"
        clearable
        autofocus
        defaultOpen
        label-in-value
        filter-option={filterOption}
        style={{width: '100%'}}
        disabled={props.disabledIn}
        onFocus={emitFocus}
        onMousedown={withModifiers(() => {}, ['stop'])}
        onClick={withModifiers(() => {}, ['stop'])}
        onBlur={onBlur}
        v-slots={{
          option: (item) => {
            return <>
              {
                item.label == '新增+' ? <span style="color:red">{ item.label }</span> : (
                  <span>
                    <span>{ item.name }</span>
                    <span style="color: red;font-size: 13px;">{item.label2}</span>
                  </span>
                )
              }
            </>
          }
        }}
      >
      </AntdSelect>
    }
  })

  selectApp.value.mount(renderBoxRef.value)
  setTimeout(() => {
    inventorySelectRef.value?.focus()
    // renderBoxRef.value?.querySelector('.el-select-v2__combobox-input').focus()
  }, 14);
}

function filterOption(input, option) {
  if (!input) return true
  return option.label?.includes(input)
}

function onBlur() {
  selectApp.value.unmount()
}

function emitFocus() {
  vm.$emit('focus')
}
</script>

<style scoped>
.el-select{
  margin-left: 0px;
}
</style>

<style lang="scss" scoped>
.boxShadowNone {
  :deep(.el-select-v2__wrapper) {
    box-shadow: none;
    background: none;
    border: none;
  }
}
</style>
<style lang="scss">
.el-vl__window {
  width: 430px !important;
}
</style>
<style lang="scss" scoped>
.select-inventory-list-container {
  position: relative;
  width: 223px;
  height: 24px;

  .text-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 12px;
    border: 1px solid #b9b9b9;

    &>span {
      flex: 1;
      width: 0;
    }
  }

  .render-box {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="css">
.ant-select-dropdown {
  z-index: 5000;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--themeColor, #17a2b8);
  font-weight: 700;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 12px;
}
</style>