<template>
  <div>
    <el-dialog
      v-model="dialogVisible"
      title="选择排序方式"
      width="30%"
      :before-close="handleClose"
    >
    <div style="margin-left:6px;margin-bottom:20px">
      <span style="position: relative;top: -2px;font-size: 15px;margin-right:15px">排序方式选择 :</span>
      <el-radio-group v-model="temp.radioSort" class="ml-4" size="large">
        <el-radio :label="1" size="large">升序</el-radio>
        <el-radio :label="2" size="large">降序</el-radio>
      </el-radio-group>
    </div>
      <el-select v-model="temp.sortMethod" class="m-2" placeholder="选择排序方式" v-if="this.remark == 'feeSort'">
        <el-option
          v-for="item in options2"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-select v-model="temp.sortMethod" class="m-2" placeholder="选择排序方式" v-else>
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleSureSort">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'changeSortList',
  props:{
    remark:{   
      type:String,    
      default:''
    },
  },
  data() {
    return {
      dialogVisible:false,
      options:[
        {
          value:'5',
          label:"按清单日期"
        },
        {
          value:'4',
          label:"按发票号码"
        },
        {
          value:'2',
          label:"按发票税率"
        },
        {
          value:'3',
          label:"按凭证字号"
        },
        {
          value:'1',
          label:"按新增时间"
        },
      ],
      options2:[
        {
          value:'5',
          label:"按清单日期"
        },
        {
          value:'3',
          label:"按凭证字号"
        },
        {
          value:'1',
          label:"按新增时间"
        },
      ],
      temp:{
        sortMethod:'5',
        radioSort:1
      }
      
    };
  },

  mounted() {
    
  },

  methods: {
    handleSureSort(){
      this.dialogVisible = false
      this.$emit('success',this.temp)
    }
  },
};
</script>

<style lang="scss" scoped>

</style>