<template>
  <el-dialog title="查看统计" v-model="dialogVisible" width="60%">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <div style="margin-bottom: 15px">
        <el-date-picker
          style="width: 100px"
          size="small"
          value-format="YYYYMM"
          v-model="listQuery.beginPeriod"
          type="month"
          placeholder="起始日期"
        >
        </el-date-picker>
        --
        <el-date-picker
          style="width: 100px"
          size="small"
          value-format="YYYYMM"
          v-model="listQuery.endPeriod"
          type="month"
          placeholder="终止日期"
        >
        </el-date-picker>
        <el-button
          size="small"
          type="primary"
          style="margin-left: 10px"
          @click="getList"
          >查询</el-button
        >
      </div>
      <el-tab-pane label="发票类型统计" name="first">
        <table class="content_table" cellspacing="0" v-loading="loading">
          <tr class="top_bg">
            <td style="width: 40%">类型</td>
            <td style="width: 6%">数量</td>
            <td style="width: 18%">金额</td>
            <td style="width: 18%">税额</td>
            <td style="width: 18%">价税合计</td>
          </tr>
          <tr>
            <td>增值税专用发票</td>
            <td>{{ sums1.count }}</td>
            <td>{{ sums1.sum_amount }}</td>
            <td>{{ sums1.sum_tax }}</td>
            <td>{{ sums1.sum_total }}</td>
          </tr>
          <tr>
            <td>增值税普通发票</td>
            <td>{{ sums2.count }}</td>
            <td>{{ sums2.sum_amount }}</td>
            <td>{{ sums2.sum_tax }}</td>
            <td>{{ sums2.sum_total }}</td>
          </tr>
          <tr>
            <td>无票</td>
            <td>{{ sums3.count }}</td>
            <td>{{ sums3.sum_amount }}</td>
            <td>{{ sums3.sum_tax }}</td>
            <td>{{ sums3.sum_total }}</td>
          </tr>
          <tr style="line-height: 28px">
            <td>增值税电子专用发票</td>
            <td>{{ sums4.count }}</td>
            <td>{{ sums4.sum_amount }}</td>
            <td>{{ sums4.sum_tax }}</td>
            <td>{{ sums4.sum_total }}</td>
          </tr>
          <tr style="line-height: 28px">
            <td>增值税电子普通发票</td>
            <td>{{ sums5.count }}</td>
            <td>{{ sums5.sum_amount }}</td>
            <td>{{ sums5.sum_tax }}</td>
            <td>{{ sums5.sum_total }}</td>
          </tr>
          <tr>
            <td>合计</td>
            <td>{{ sums.count }}</td>
            <td>{{ sums.sum_amount }}</td>
            <td>{{ sums.sum_tax }}</td>
            <td>{{ sums.sum_total }}</td>
          </tr>
        </table>
      </el-tab-pane>
      <el-tab-pane label="业务类型统计" name="second">
        <el-table
          :data="list"
          style="width: 100%"
          border
          show-summary
          v-loading="loading"
          :height="450"
        >
          <el-table-column prop="inOutName" label="开票项目" align="left" />
          <el-table-column prop="count" label="数量" align="center" />
          <el-table-column prop="sum_amount" label="金额" align="center" />
          <el-table-column prop="sum_tax" label="税额" align="center" />
          <el-table-column prop="sum_total" label="价税合计" align="center" />
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false" size="small">
          关闭
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { inOutSum, inOutSumRemark } from "@/api/invoice";
import { ref } from "vue";
const dialogVisible = ref(false);
const listQuery = ref({
  beginPeriod: "",
  endPeriod: "",
});
const activeName = ref("first");
const sums = ref({});
const sums1 = ref({});
const sums2 = ref({});
const sums3 = ref({});
const sums4 = ref({});
const sums5 = ref({});
const list = ref([]);
const loading = ref(false);
const init = () => {
  dialogVisible.value = true;
  getList();
};
const getList = () => {
  loading.value = true;
  if (activeName.value == "first") {
    inOutSum(listQuery.value).then((res) => {
      loading.value = false;
      sums.value = res.data.data.outSum;
      sums1.value = res.data.data.outSum1;
      sums2.value = res.data.data.outSum2;
      sums3.value = res.data.data.outSum3;
      sums4.value = res.data.data.outSum4;
      sums5.value = res.data.data.outSum5;
    });
  } else {
    inOutSumRemark(listQuery.value).then((res) => {
      loading.value = false;
      list.value = res.data.data.outSum ? res.data.data.outSum : [];
    });
  }
};
const handleClick = (tab, event) => {
  activeName.value = tab.props.name;
  getList();
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.content_table {
  border: 1px solid #c2c2c2;
  width: 100%;
  border-right: none;
  border-bottom: none;
  td {
    border-bottom: 1px solid #c2c2c2;
    border-right: 1px solid #c2c2c2;
    text-align: center;
    line-height: 32px;
  }
}
</style>
