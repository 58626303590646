<template>
  <el-select @change="changeType" filterable v-model="invoiceType" placeholder="选择发票类型" size="small" style="width:200px" clearable>
    <el-option
      v-for="item in (type == 'in'?optionsIn:optionsOut)"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>
<script>
import { IN_INVOICE_OPTION} from "@/utils/commonData"

export default {
  props:{
    invoiceType: {
      type: String,
      default: ""
    },
    type:{
      type:String,
      default:'in'
    }
  },
  data () {
    return {
      optionsIn:IN_INVOICE_OPTION,
      optionsOut:[
      {
          value: "1",
          label: "增值税专用发票"
        },
        {
          value: "2",
          label: "增值税普通发票"
        },
        {
          value: "3",
          label: "无票收入"
        },
        {
          value: "4",
          label: "增值税电子专用发票"
        },
        {
          value: "5",
          label: "增值税电子普通发票"
        }, 
      ]
    }
  },
  methods: {
    changeType(){
      this.$emit("update:invoiceType", this.invoiceType)
      this.$emit("success", this.invoiceType)
    }
  }
}
</script>