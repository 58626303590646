<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="补充业务类型" v-model="dialogVisible" width="1300px" >

    <div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="批量补充" name="批量补充"></el-tab-pane>
        <el-tab-pane label="存货自动补充" name="存货自动补充"></el-tab-pane>
      </el-tabs>
    </div>


    <div v-if="activeName == '存货自动补充'">

      <el-form ref="formRef" label-width="120px">
        <el-form-item label="业务类型">
          <!-- <subject-list v-model:subjectId="zdbc.subjectId" v-model:fzhsItemId="zdbc.fzhsId" :codes="$findCode(['code1606','code5001','code5051'])"></subject-list> -->

          <el-select v-model="zdbc.businessTypeId" class="m-2" placeholder="业务类型" size="small">
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>


        </el-form-item>

        <el-form-item label="商品分类">
          <el-radio-group @change="changeZdbcType" v-model="zdbc.type">
            <el-radio style="width:100%" :label="1">自选存货  (*手动选择存货补充)</el-radio>
            <el-radio style="width:100%" :label="2">匹配创建下级科目  (*按照开票项目和规格型号自动创建下级补充)</el-radio>
            <el-radio style="width:100%" :label="3">绑定已有科目  (*选择已有存货科目补充)</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="zdbc.type == 1" label="存货列表">
          <inventory-list v-model:inventoryId="zdbc.inventoryId"></inventory-list>
        </el-form-item>
        <el-form-item v-else-if="zdbc.type == 2" label="上级科目">
          <subject-list-all v-model:subjectId="zdbc.inventorySubjectId" :codes="$findCode(codes)"></subject-list-all>
        </el-form-item>
        <el-form-item v-else-if="zdbc.type == 3" label="对应科目">
          <subject-list v-model:subjectId="zdbc.inventorySubjectId" :codes="$findCode(codes)"></subject-list>
        </el-form-item>
      </el-form>
    </div>


    <div class="inbc2-patch-set" v-if="activeName == '批量补充'">
      <div class="top">
        <el-row>
          <el-col :span="18">
            <div class="grid-content bg-purple">
              <el-input style="width:150px;margin-right:10px" @keyup.enter.native="listFilter()" size="small"
                v-model="listQuery.corpName" placeholder="公司名"></el-input>
              <el-input style="width:100px" @keyup.enter.native="listFilter()" size="small" v-model="listQuery.kpxm"
                placeholder="开票项目"></el-input>
              <!-- <el-select size="small" v-model="listQuery.spec" multiple filterable placeholder="规格型号">
                <el-option v-for="item in specList" :key="item.value" :label="item.value" :value="item.value">
                </el-option>
              </el-select> -->
              <qzf-button @success="listFilter()" type="primary" style="margin-right: 10px;">搜索</qzf-button>
              <search @success="listFilter" @cancel="cancel">
                <el-form style="margin:11px 0px 0px 6px;width:300px" class="styleForm" label-width="64px">
                  <el-form-item label="税率">
                    <el-input style="width:174px;margin-left:5px" size="small" v-model="listQuery.taxRate"
                      placeholder="税率"></el-input>
                  </el-form-item>
                  <el-form-item label="金额">
                    <el-input style="width:80px;margin-left:5px;margin-right: 5px;" size="small"
                      v-model="listQuery.firstShowAmount" placeholder="金额起"></el-input>
                    -
                    <el-input style="width:80px;margin-left: 5px;" size="small" v-model="listQuery.secondShowAmount"
                      placeholder="金额止"></el-input>

                  </el-form-item>
                  <el-form-item label="单价">
                    <el-input style="width:80px;margin-left:5px;margin-right: 5px;" size="small"
                      v-model="listQuery.firstPrice" placeholder="单价起"></el-input>
                    -
                    <el-input style="width:80px;margin-left: 5px;" size="small" v-model="listQuery.SecondPrice"
                      placeholder="单价止"></el-input>

                  </el-form-item>
                  <el-form-item label="规格型号">
                    <el-select size="small" v-model="listQuery.spec" multiple filterable allow-create default-first-option
                      placeholder="请选择">
                      <el-option v-for="item in specList" :key="item.value" :label="item.value" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>

                </el-form>
              </search>
              <el-select style="width:113px" size="small" v-model="bcOptionSingle" @change="listFilter()" filterable
                placeholder="">
                <el-option v-for="item in bcOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <ssflbm :all="true" v-model:ssflbm="webListQuery.ssflbm" @success="listFilterSsflbm"></ssflbm>

            </div>
            <el-radio-group @change="listFilterQc()" v-model="qc">
              <el-radio :label="0">相同开票项目去重 (*同一个开票项目只显示一个)</el-radio>
              <el-radio :label="1">相同开票项目型号去重 (*同一开票项目且型号相同只显示一个)</el-radio>
              <el-radio :label="2">全部(单个补充) (*所有明细全部显示)</el-radio>
            </el-radio-group>
            <!-- <span v-if="qc == 0" style="color: var(--themeColor,#17a2b8);margin-left: 20px;">*温馨提示：</span>
            <span v-if="qc == 1" style="color: var(--themeColor,#17a2b8);margin-left: 20px;">*温馨提示：同一开票项目且型号相同只显示一个</span>
            <span v-if="qc == 2" style="color: var(--themeColor,#17a2b8);margin-left: 20px;">*温馨提示：所有明细全部显示</span> -->
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-light">
              <qzf-button @success="plbc()" type="primary">批量补充</qzf-button>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%" :height="contentStyleObj" border
        @selection-change="handleSelectionChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>


        <el-table-column align="center" type="selection" />

        <el-table-column align="left" label="公司" min-width="150">
          <template #default="scope">
            <span v-if="scope.row.corpName">{{ scope.row.corpName }}</span>
            <span v-else>手动添加发票未知</span>
          </template>
        </el-table-column>

        <el-table-column align="left" label="开票项目" min-width="250" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="scope.row.remark">{{'('+scope.row.remark+')'}}</span>
            <span v-else>{{scope.row.remark}}</span>
            <!-- <span>{{ scope.row.name }}{{ scope.row.remark?'('+scope.row.remark+')' : '' }}</span> -->
          </template>
        </el-table-column>

        <el-table-column align="left" label="规格型号" min-width="120">
          <template #default="scope">
            <span>{{ scope.row.spec }}</span>
          </template>
        </el-table-column>
        <el-table-column align="left" label="单位" min-width="80">
          <template #default="scope">
            <span>{{ scope.row.unit }}</span>
          </template>
        </el-table-column>
        <el-table-column align="left" label="单价" min-width="90">
          <template #default="scope">
            <span>{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column align="left" label="金额" min-width="110">
          <template #default="scope">
            <span>{{ scope.row.showAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column align="left" label="税率" min-width="80">
          <template #default="scope">
            <span>{{ scope.row.taxRate }}</span>
          </template>
        </el-table-column>

        <el-table-column align="left" min-width="300" label="业务类型">
          <template #default="scope">
            <DynamicDown :row="scope.row" businessIdKey="businessTypeId" @change="val => changeCas(val, scope.row)" @changeItemName="(val) => changeItemName(val, scope.row)" />
            <!-- <business-type v-model:businessId="scope.row.businessTypeId" v-model:itemId="scope.row.itemId"
              v-model:fzhsId="scope.row.fzhsId" v-model:itemType="scope.row.itemType" :name="scope.row.name"
              :spec="scope.row.spec" :unit="scope.row.unit" type="in" v-model:assetsName="scope.row.name" v-model:assetsCount="scope.row.count" v-model:assetsAmount ="scope.row.amount" v-model:assetsDate="scope.row.createdAt"></business-type> -->
          </template>
        </el-table-column>


      </el-table>
      <div class="pagination" style="float:none">
        <qzf-pagination v-show="total > 0" :total="total" v-model:page="webListQuery.page"
          v-model:limit="webListQuery.limit" @pagination="pageChange" />
      </div>
    </div>



    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">上一步</el-button>
        <qzf-button :loading="loading" type="primary" @success="handleAddSure" size="small">完成</qzf-button>
      </span>
    </template>
  </el-dialog>



  <!-- 批量补充弹框 -->
  <ywlx @success="ywlxSuccess" ref="ywlx"></ywlx>

  <add-inventory
    ref="addInventory"
    @success="addInventorySuccess"
  />
  <add-subject
    ref="addSubject"
    @success="addSubjectSuccess"
  />
  <addAssets
    ref="addAssets"
    :type="typexsjx"
    :tyname="'xzjx'"
    title="关联资产"
    @success="addAssetSuccess"
  />
</template>
<script>
import { inInvoiceBcTwoList, inBcSaveTwo, inAutomaticBc } from "@/api/invoice.js"
import ssflbm from '@/components/ssflbm/ssflbm.vue'
import DynamicDown from './DynamicDown'
import addAssets from '@/views/input/assets/components/addAssets.vue'

import ywlx from "./ywlx"

export default {
  components: {
    ywlx,
    ssflbm,
    DynamicDown,
    addAssets,
  },
  props: {

  },
  computed: {
    options() {
      let data = this.$store.getters["commons/businessType"].in
      return data.slice(0, 11)//前11个是存货
    },
  },
  data() {
    return {
      typexsjx: 0,
      upQc: 0,
      qc: 0,
      zdbc: {
        type: 1,
        subjectId: 0,
        fzhsId: 0,
        inventoryId: 0,
        inventorySubjectId: 0,
      },
      codes: ['code1285', 'code1403', 'code1405', 'code1406', 'code1408', 'code1411', 'code1412', 'code1421', 'code1605','code1621'],
      activeName: "批量补充",
      loading: false,
      listQuery: {
        corpName: '',
        kpxm: '',
        spec: [],
        taxRate: "",
        firstShowAmount: '-100000000',
        secondShowAmount: '',
        firstPrice: '-100000000',
        SecondPrice: ''
      },
      bcOptionSingle: '',

      webListQuery: {
        ssflbm: '',
        page: 1,
        limit: 20,
      },
      dialogVisible: false,
      list: [],
      pageList: [],
      total: 0,
      oldList: [],
      selects: [],
      specList: [],
      bcOptions: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '1',
          label: '只显示未补充'
        },
        {
          value: '2',
          label: '只显示已补充'
        }
      ],
      spssbm: undefined,
      contentStyleObj:{},
      comType:this.$store.getters["user/comInfo"].comType
    }
  },
  
  created(){
    this.contentStyleObj= this.$getHeight(500)
  },
  methods: {
    changeItemName(val, row) {
      row.itemName = val.join('/')
    },
    changeRowIds(ids, row) {
      row.businessTypeId = ids[0] * 1
      row.itemId = ids[1].split('-')[1] * 1
      row.fzhsId = ids[1].split('-')[2] * 1
    },
    addAssetSuccess(sign) {
      const ids = [this.bid, this.bid + '-' + sign,]
      this.changeRowIds(ids, this.currentRow)
    },
    addInventorySuccess(e) {
      const ids = [this.bid, this.bid + '-' + String(e.id) + '-0',]
      this.changeRowIds(ids, this.currentRow)
    },
    addSubjectSuccess(e) {
      const ids = [this.bid, this.bid + '-' + e.sign,]
      this.changeRowIds(ids, this.currentRow)
    },
    changeCas(val, row) {
      let { ids, node, } = val
      if (!ids) {
        row.businessTypeId = ''
        row.itemId = ''
        row.fzhsId = ''
        row.itemName = ''
        return
      }
      const bid = ids[0]
      this.bid = bid
      this.currentRow = row
      const addId = ids[0] * 1
      let businessTypeInfo = node?.parent?.data
      if (ids[1] === 'addInventory') {
        let data = {
          id: undefined,
          spec: row.spec,
          unit: row.unit,
          name: row.name,
          type: addId,
        }
        this.$refs.addInventory.init(data)
      } else if (ids[1] === 'addSubject') {
        let data = {
          id: undefined,
          type: 0,
        }
        let useCodes = null
        if (businessTypeInfo) {
          useCodes = businessTypeInfo.useCodes
        }
        this.$refs.addSubject.init(data, useCodes)
      } else if (ids[1] === 'addAssage') {
        //14 固定资产
        //16 无形资产
        //17 待摊费用
        const bidFortypexsjx = {
          14: 1,
          16: 2,
          17: 3,
        }
        this.typexsjx = bidFortypexsjx[bid]
        let param = {
          name: row.name,
          originalValue: this.comType == '2' && ['1', '4'].includes(row.invoiceType) ? row.amount : row.total,
          unit: node.data.unit,
          type: this.typexsjx,
          transDate: this.$parseTime(row.invoiceDate, '{y}-{m}-{d}'),
          count: row.count,
        }
        this.$refs.addAssets.openDialog(param, this.typexsjx, 'glzc')
      }
      if (ids.length > 1) {
        this.changeRowIds(ids, this.currentRow)
        row.itemName = node.pathLabels.join('/')
        //存货的 双向绑定一下 型号 单位
        if (ids[0] * 1 <= 11 || [20,21,22].includes(addId)) {
          row.spec = node.data.spec ? node.data.spec : row.spec
          row.unit = node.data.unit ? node.data.unit : row.unit
          row.itemType = 'inventory'
        } else {
          row.itemType = 'item'
        }
      }
    },
    pageChange() {
      let list = this.pageList
      this.total = list.length
      let s = (this.webListQuery.page - 1) * this.webListQuery.limit
      let e = this.webListQuery.page * this.webListQuery.limit
      this.list = list.slice(s, e)
    },
    //初始化
    init(params) {
      this.zdbc.items = params //用于自动补充
      inInvoiceBcTwoList(params).then(res => {
        if (res.data.msg == "success") {
          let list = res.data.data.list ? res.data.data.list : []
          this.oldList = list
          this.pageList = this.oldList
          // this.pageChange()
          let specList = []
          list.map(v => {
            specList.push({ value: v.spec })
          })
          let map = new Map()
          let newArr = specList.filter(v => !map.has(v.value) && map.set(v.value, 1) && v.value.trim())
          this.specList = newArr
          this.listFilter()
          this.dialogVisible = true
        }
      })
    },
    listFilterQc() {
      if (this.qc == 2) {
        if (this.upQc == 0) {//上次在相同项目去重上
          this.oldList.map(v => {
            this.list.map(x => {
              if (v.name == x.name) {
                v.businessTypeId?v.businessTypeId:v.businessTypeId = x.businessTypeId
                v.fzhsId?v.fzhsId:v.fzhsId = x.fzhsId
                v.itemId?v.itemId:v.itemId = x.itemId
                v.itemType?v.itemType:v.itemType = x.itemType
              }
            })
          })
        } else if (this.upQc == 1) {
          this.oldList.map(v => {
            this.list.map(x => {
              if (v.name == x.name && v.spec == x.spec) {
                v.businessTypeId?v.businessTypeId:v.businessTypeId = x.businessTypeId
                v.fzhsId?v.fzhsId:v.fzhsId = x.fzhsId
                v.itemId?v.itemId:v.itemId = x.itemId
                v.itemType?v.itemType:v.itemType = x.itemType
              }
            })
          })
        }
      }
      this.upQc == this.qc
      this.$nextTick(function () {
        this.listFilter()
      })
    },
    //重置
    cancel(){
      this.listQuery = {
        corpName: '',
        kpxm: '',
        spec: [],
        taxRate: "",
        firstShowAmount: '-100000000',
        secondShowAmount: '',
        firstPrice: '-100000000',
        SecondPrice: ''
      }
      this.listFilter()
    },
    //过滤
    listFilter(e) {
      //console.log(this.list);
      // let newArr2 = []
      // //console.log(this.listQuery.spec)
      // this.oldList.map(v => {
      //   this.listQuery.spec.map(vv => {
      //     newArr2 =  newArr2.concat(v.spec.match(vv))
      //   })
      // })
      // let newArr3 = newArr2.filter(n => n)
      let list = this.oldList.filter(v => {
        // //console.log(newArr3);
        return v.corpName.match(this.listQuery.corpName)
          && v.name.match(this.listQuery.kpxm)
          && (this.listQuery.spec.length == 0 || (this.listQuery.spec.indexOf(v.spec) > -1))
          && (v.taxRate == this.listQuery.taxRate || this.listQuery.taxRate == "")
          && (this.listQuery.secondShowAmount == '' ? (this.listQuery.firstShowAmount <= v.showAmount) : ((this.listQuery.firstShowAmount <= v.showAmount)
            && (v.showAmount <= this.listQuery.secondShowAmount)))
          && (this.listQuery.SecondPrice == '' ? (this.listQuery.firstPrice <= v.price) : ((this.listQuery.firstPrice <= v.price) && (v.price <= this.listQuery.SecondPrice)))
      })
      if (this.spssbm) {
        list = list.filter(v => {
          return !this.spssbm || this.spssbm == v.ssflbm.slice(0, 2)
        })
      }
      if (this.bcOptionSingle == '1') {
        list = list.filter(v => {
          return v.itemId == 0
        })
      } else if (this.bcOptionSingle == '2') {
        list = list.filter(v => {
          return v.itemId != 0
        })
      } else {
        list = list
      }

      //去重
      if (this.qc == 0) {
        var q = []
        list.map(v => {
          var s = true
          q.map(x => {
            if (x.name == v.name) {
              s = false
            }
          })
          if (s) {
            q.push(v)
          }
        })
        list = q
      } else if (this.qc == 1) {
        var q = []
        list.map(v => {
          var s = true
          q.map(x => {
            if (x.name == v.name && x.spec == v.spec) {
              s = false
            }
          })
          if (s) {
            q.push(v)
          }
        })
        list = q
      }

      this.pageList = list
      this.pageChange()
    },
    handleSelectionChange(e) {
      this.selects = e
    },
    //批量创建公司
    plbc() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.$refs.ywlx.init()
    },
    ywlxSuccess({ ids, node }) {
      if (!ids) {
        return
      }
      this.selects.map(item => {
        item.businessTypeId = ids.businessTypeId
        item.fzhsId = ids.fzhsId
        item.itemId = ids.itemId 
        item.itemType = ids.itemType
        item.itemName = node.pathLabels.join('/')
      })
    },
    //完成
    handleAddSure() {
      //保存
      if (this.activeName == "批量补充") {
        if (this.qc == 0) {//上次在相同项目去重上
          this.oldList.map(v => {
            this.list.map(x => {
              if (v.name == x.name) {
                v.businessTypeId = x.businessTypeId
                v.fzhsId = x.fzhsId
                v.itemId = x.itemId
                v.itemType = x.itemType
              }
            })
          })
        } else if (this.qc == 1) {
          this.oldList.map(v => {
            this.list.map(x => {
              if (v.name == x.name && v.spec == x.spec) {
                v.businessTypeId = x.businessTypeId
                v.fzhsId = x.fzhsId
                v.itemId = x.itemId
                v.itemType = x.itemType
              }
            })
          })
        }
        let status = false
        this.oldList.map(v=>{
          if(!v.businessTypeId){
            status = true
            return
          }
        })
        if(status){
          this.$confirm('存在未补充完整的项目，是否继续保存？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.loading = true
            inBcSaveTwo(this.oldList).then(res => {
              this.loading = false
              if (res.data.msg == "success") {
                this.$emit("success")
                this.dialogVisible = false
              }
            })
          });
        }else{
          this.loading = true
          inBcSaveTwo(this.oldList).then(res => {
            this.loading = false
            if (res.data.msg == "success") {
              this.$emit("success")
              this.dialogVisible = false
            }
          })
        }
      } else if (this.activeName == "存货自动补充") {
        if (this.zdbc.businessTypeId == 0) {
          this.$qzfMessage("请选择业务类型", 1)
          return
        }
        if (this.zdbc.type == 1 && !this.zdbc.inventoryId) {
          this.$qzfMessage("请选择存货", 1)
          return
        }
        if ((this.zdbc.type == 2 || this.zdbc.type == 3) && !this.zdbc.inventorySubjectId) {
          this.$qzfMessage("请选择存货科目", 1)
          return
        }
        this.loading = true
        inAutomaticBc(this.zdbc).then(res => {
          this.loading = false
          if (res.data.msg == "success") {
            if (res.data.data.status) {
              this.$qzfMessage("存在非存货类明细，请查看未补充发票重新手动补充")
            }
            if(res.data.data.msg.match('进行中')){
              this.$qzfMessage(res.data.data.msg)
            }
            this.$emit("success")
            this.$store.dispatch("commons/getSubjects")
            this.$store.dispatch("commons/getInventory")
            this.$store.dispatch("commons/getBusinessType")
            this.$bus.emit('voucherUpdate')
            this.dialogVisible = false
          }
        })
      }
    },
    //自动补充
    changeZdbcType() {
      this.zdbc.inventorySubjectId = 0
    },
    listFilterSsflbm(e) {
      this.spssbm = e
      this.listFilter(e)
    },
    querySearch(queryString, cb) {
      var restaurants = this.specList;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      //console.log(results);
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
  }
}
</script>
<style lang="less" scoped>
.top {
  margin-bottom: 10px;
}

.bg-purple-light {
  float: right;
}
</style>
<style scoped lang="scss"></style>