<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="批量结算"
    width="70%"
    destroy-on-close
  >
    <el-table :data="list" style="width: 100%" :height="contentStyleObj" border :loading="loading">
      <el-table-column align="left" min-width="300" label="银行对应项目">
        <template #default="scope">
          <business-type
            v-model:businessId="scope.row.bankBusinessId"
            v-model:itemId="scope.row.bankSubjectId"
            v-model:fzhsId="scope.row.bankFzhsId"
            type="bank"
          ></business-type>
        </template>
      </el-table-column>
      <el-table-column align="left" min-width="150" label="银行">
        <template #default="scope">
          <el-select
            placeholder="请选择"
            v-model="scope.row.bankId"
            size="small"
            filterable
          >
            <el-option
              v-for="(item, index) in bankList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column align="left" min-width="200" label="金额">
        <template #default="scope">
          <el-input size="small" v-model="scope.row.bankAmount"></el-input>
        </template>
      </el-table-column>
      <el-table-column align="left" min-width="300" label="现金对应项目">
        <template #default="scope">
          <business-type
            v-model:businessId="scope.row.cashBusinessId"
            v-model:itemId="scope.row.cashSubjectId"
            v-model:fzhsId="scope.row.cashFzhsId"
            type="cash"
          ></business-type>
        </template>
      </el-table-column>
      <el-table-column align="left" min-width="200" label="现金金额">
        <template #default="scope">
          <el-input size="small" v-model="scope.row.cashAmount"></el-input>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="settleSure()"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { bankNameList, bankList } from "@/api/bank";
import { settlement } from "@/api/invoice";
export default {
  name: "",
  components: {},
  props: {
    type: String,
    default: "",
  },
  data() {
    return {
      list: [],
      dialogFormVisible: false,
      contentStyleObj: {},
      bankOptions: this.$store.getters["commons/businessType"].bank,
      cashOptions: this.$store.getters["commons/businessType"].cash,
      loading:false,
      bankList:[]
    };
  },
  computed: {
    bankOptions() {
      let data = this.$store.getters["commons/businessType"].bank;
      return data;
    },
    cashOptions() {
      let data = this.$store.getters["commons/businessType"].cash;
      return data;
    },
  },
  mounted() {
    this.getBankList();
    this.contentStyleObj = this.$getHeight(350);
  },
  methods: {
    init(initList) {
      let arr = [];
      initList.map((v) => {
        this.bankOptions.map((e) => {
          if (e.id == 3462) {
            e.items.map((z) => {
              if (z.name.match(v.corpName)) {
                v.bankBusinessId = 3462;
                v.bankSubjectId = z.subjectId;
                v.bankFzhsId = z.sign.split("-")[2] * 1;
              } else {
                v.bankBusinessId = 3462;
                v.bankSubjectId = 0;
                v.bankFzhsId = 0;
              }
            });
          }
        });
        this.cashOptions.map((e) => {
          if (e.id == 3494) {
            e.items.map((z) => {
              if (z.name.match(v.corpName)) {
                v.cashBusinessId = 3494;
                v.cashSubjectId = z.subjectId;
                v.cashFzhsId = z.sign.split("-")[2] * 1;
              } else {
                v.cashBusinessId = 3494;
                v.cashSubjectId = 0;
                v.cashFzhsId = 0;
              }
            });
          }
        });
        arr.push({
          method: this.type,
          corpId: v.corpId,
          invoiceId: v.id,
          bankBusinessId: v.bankBusinessId,
          bankSubjectId: v.bankSubjectId,
          bankFzhsId: v.bankFzhsId,
          cashBusinessId: v.cashBusinessId,
          cashSubjectId: v.cashSubjectId,
          cashFzhsId: v.cashFzhsId,
          bankId: this.bankList.length ? this.bankList[0].id : null,
          bankAmount: v.sumTotal,
          sumTotal: v.sumTotal,
          cashAmount: 0,
        });
      });
      this.list = arr;
      this.dialogFormVisible = true;
    },
    getBankList() {
      bankNameList({}).then((res) => {
        if (res.data.data.list.length != 0) {
          this.bankList = res.data.data.list;
        }
      });
    },
    settleSure() {
      let bcStatus = false;
      this.list.map((v) => {
        if (v.corpId == 0) {
          bcStatus = true;
          return;
        }
      });
      if (bcStatus) {
        this.$qzfMessage("请先补充往来单位", 1);
        return;
      }
      let status = false;
      this.list.map((v) => {
        let amount = v.bankAmount * 1 + v.cashAmount * 1;
        if (amount > v.sumTotal) {
          status = true;
          return;
        }
      });
      if (status) {
        this.$qzfMessage("银行金额与现金金额之和大于价税合计", 1);
        return;
      }
      this.loading = true
      this.list.map((v) => {
        v.bankAmount = Number(v.bankAmount);
        v.cashAmount = Number(v.cashAmount);
      });
      settlement(this.list).then((res) => {
        this.loading = false
        if (res.data.msg == "success") {
          this.$qzfMessage("结算成功");
          this.dialogFormVisible = false;
          this.$emit("success");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
