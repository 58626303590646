<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="批量补充业务类型" v-model="dialogVisible" width="25%" >
    <business-type ref="cascaderRef" v-model:businessId="temp.businessTypeId" v-model:itemId="temp.itemId" v-model:fzhsId="temp.fzhsId" v-model:itemType="temp.itemType" type="in"></business-type>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <qzf-button :loading="loading" type="primary" @success="handleAddSure" size="small">确 定</qzf-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>

export default {
  data () {
    return {
      loading: false,
      dialogVisible: false,
      temp: {
        businessTypeId: 0,
        fzhsId: 0,
      },
    }
  },
  methods: {
    init(){
      this.temp.businessTypeId = 0
      this.temp.fzhsId = 0
      this.temp.itemId = 0
      this.temp.itemType = ""

      this.dialogVisible = true
    },
    handleAddSure(){
      if(this.temp.businessTypeId == 0 || this.temp.itemId == 0){
        this.$qzfMessage("请选择", 1)
        return
      }
      const nodes = this.$refs.cascaderRef.getCheckedNodes()
      this.$emit("success", {
        ids: {
          ...this.temp
        },
        node: nodes[0]
      })
      this.dialogVisible = false
     
    }
  }
}
</script>