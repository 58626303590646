<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="补充往来单位" v-model="dialogVisible" width="1300px" >
    <div>
      <div class="top">
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-input style="width:200px" @keyup.enter.native="listFilter()" size="small" v-model="listQuery.corpName" placeholder="公司名"></el-input>
              <qzf-button @success="listFilter()" type="primary">搜索</qzf-button>
              <el-select v-model="listQuery.subjectId" placeholder="补充状态" size="small" style="width:150px;" @change="listFilter()">
                <el-option label="未补充" :value="0" size="small">
                </el-option>
                <el-option label="已补充" :value="1" size="small">
                </el-option>
                <el-option label="全部" :value="2" size="small">
                </el-option>
               </el-select>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <span style="font-size: 13px;color:var(--themeColor,#17a2b8);margin-right:8px;position: relative;top:1px">
                <i style="font-size: 13px;" class="iconfont icon-gantanhao1" ></i> <span >温馨提示：点击批量创建可以自动创建往来科目 <el-icon style="position:relative;top:2px"> <d-arrow-right /> </el-icon> </span>
              </span>
              <qzf-button @success="bcWldw(1)" type="primary">批量创建</qzf-button>
              <qzf-button @success="bcWldw(2)" type="primary">批量选择</qzf-button>
            </div>
          </el-col>
        </el-row>
      </div>



      <el-table
        :height="contentStyleObj"
        ref="multipleTable"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
        border
        @selection-change="handleSelectionChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>


        <el-table-column align="center" type="selection" width="55" />

        <el-table-column align="left" label="公司" min-width="300">
          <template #default="scope">
            <span v-if="scope.row.corpName">{{scope.row.corpName}}</span>
            <span v-else>手动添加发票未知</span>
          </template>
        </el-table-column>
        <el-table-column align="left" label="开票项目" width="150" show-overflow-tooltip prop="invoiceName">
        </el-table-column>
        <!-- <el-table-column align="left" label="对应公司">
          <template #default="scope">
            <corp-list :corpName="scope.row.corpName" v-model:corpId="scope.row.corpId" type="1"></corp-list>
          </template>
        </el-table-column> -->

        <el-table-column align="left" label="往来单位"  min-width="300">
          <template #default="scope">
            <subject-list width="400" :subjectName="scope.row.corpName" v-model:subjectId="scope.row.subjectId" v-model:fzhsItemId="scope.row.fzhsId" :codes="$findCode(code)" v-model:status="status" :index="scope.$index"></subject-list>
          </template>
        </el-table-column>

      </el-table>


      <div class="pagination" style="float:none">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="webListQuery.page" v-model:limit="webListQuery.limit" @pagination="pageChange" />
      </div>
    </div>



    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleAddSure" size="small" :loading="btnLoading">下一步</el-button>
      </span>
    </template>
  </el-dialog>


  
  <!-- 创建往来单位弹框 -->
  <wldw @success="wldwSuccess" ref="wldw" @cancel="jumpNext"></wldw>

  <!-- 第二步 -->
  <outbc2 @success="outbc2Success" ref="outbc2"></outbc2>

</template>
<script>
import { outInvoiceBcOneList, outInvoiceBcTwoList, outBcSaveOne } from "@/api/invoice.js"
import wldw from "./wldw"
import outbc2 from "./outbc2"
export default {
  props: {
    
  },
  data () {
    return {
      status: "true",
      webListQuery: {
        page: 1,
        limit: 20,
      },
      listQuery: {
        corpName: '',
        subjectId:2
      },
      dialogVisible: false,
      list: [],
      pageList: [],
      oldList: [],
      total: 0,
      code: ['code1001','code1002','code1012','code1121','code1122','code1221','code2201','code2202','code2241','code2701','code1123','code2203',],
      selects: [],
      contentStyleObj:{},
      oldParams:[],
      params:[],
      oldList2:[],
      btnLoading:false
    }
  },
  components: {
    wldw,
    outbc2,
  },
  created(){
    this.contentStyleObj= this.$getHeight(420)
  },
  methods: {
    pageChange(){
      let list = this.pageList
      this.total = list.length
      let s = (this.webListQuery.page - 1) * this.webListQuery.limit
      let e = this.webListQuery.page * this.webListQuery.limit
      this.list = list.slice(s, e)
    },
    //初始化
    init(params){
      this.selects = []
      this.oldParams = params
      outInvoiceBcOneList(params).then(res=>{
        if(res.data.msg == "success"){
          let list = res.data.data.list
          this.oldList2 = res.data.data.list

          this.oldList = list
          this.pageList = this.oldList
          this.listQuery.subjectId = 2
          this.listFilter()
          this.dialogVisible = true
        }
      })
    },
    //过滤
    listFilter(){
    //页面渲染--list
    let list = []
      list = this.oldList.filter(v=>{
        return v.corpName.match(this.listQuery.corpName)
      })
      if(this.listQuery.subjectId == 0){
        list = list.filter(v=>{
        return v.subjectId == 0
       })
      }else if(this.listQuery.subjectId == 1){
        list = list.filter(v=>{
        return v.subjectId != 0
       })
      }else{
        list = list.filter(v=>{
        return v
       })
      }
      var q = []
      list.map(v => {
        var s = true
        q.map(x => {
          if (x.corpName == v.corpName && x.subjectId == v.subjectId && x.fzhsId == v.fzhsId) {
            s = false
          }
        })
        if (s) {
          q.push(v)
        }
      })
      list = q
        //传params--list1
      //   let list1 = []
      // list1 = this.oldParams.filter(v=>{
      //   return v.corpName.match(this.listQuery.corpName)
      // })
      // if(this.listQuery.subjectId == 0){
      //   list1 = list1.filter(v=>{
      //   return v.subjectId == 0
      //  })
      // }else if(this.listQuery.subjectId == 1){
      //   list1 = list1.filter(v=>{
      //   return v.subjectId != 0
      //  })
      // }else{
      //   list1 = list1.filter(v=>{
      //   return v
      //  })
      // }
      // this.params = list1
      this.pageList = list
      this.pageChange()
    },
    handleSelectionChange(e){
      this.selects = e
    },
    //批量创建公司
    bcWldw(type){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let items = this.selects.map(v=>{
        return {
          corpName: v.corpName,
          id: v.id,
        }
      })
      this.$refs.wldw.init(items, type, this.code, 1)
    },
    wldwSuccess(e){
      if(!e){
        return
      }
      e.map(v=>{
        this.selects.map(item=>{
          if(v.id == item.id){
            item.subjectId = v.subjectId
            item.fzhsId = v.fzhsId
          }
        })
      })
    },
    //新的
    handleAddSure(){
      //手动勾选
      if(this.selects.length != 0){
        this.btnLoading = true
        let saveOneList = []
        this.oldList2.map(v=>{
          let s = false
          this.selects.map(z=>{
            if(v.id == z.id){
              s = true
              saveOneList.push(v)
            }
          })
          if(!s){
            this.selects.map(e=>{
              if(v.corpName == e.corpName){
                v.subjectId = e.subjectId
                v.fzhsId = e.fzhsId
                saveOneList.push(v)
              }
           })
          }
        })
        outBcSaveOne(saveOneList).then(res=>{
          this.btnLoading = false
          if(res.data.msg == 'success'){
            this.$store.dispatch("commons/getCorp")
            let selectArr = []
            this.oldParams.map(v=>{
              this.selects.map(e=>{
                if(v.corpName == e.corpName){
                  selectArr.push(v)
                }
              })
            })
            this.$refs.outbc2.init(selectArr)
          }else{
            let arr = []
            this.selects.map(v=>{
              if(!v.subjectId){
                this.$refs.multipleTable.toggleRowSelection(v,true)
                arr.push({
                  corpName: v.corpName,
                  id: v.id,
                })
              }
            })
            this.$refs.wldw.init(arr, 1, this.code, 2,'fixed')
          }
        })
      }else{
        //默认全部
        this.allSave()
      }
    },
    allSave(){
      this.btnLoading = true
      this.oldList2.map(v =>{
        let s = false
        this.pageList.map(z=>{
          if(v.id == z.id){
            s = true
          }
        })
        if(!s){
          for (let i = 0; i < this.pageList.length; i++) {
            if (v.corpName == this.pageList[i].corpName) {
              v.subjectId = this.pageList[i].subjectId
              v.fzhsId = this.pageList[i].fzhsId
            }
          }
        }
      })
      outBcSaveOne(this.oldList2).then(res=>{
        this.btnLoading = false
        if(res.data.msg == 'success'){
          this.$store.dispatch("commons/getCorp")
          this.$refs.outbc2.init(this.oldParams)
        }else{
          let arr = []
          this.list.map(v=>{
            if(!v.subjectId){
              this.$refs.multipleTable.toggleRowSelection(v,true)
              arr.push({
                corpName: v.corpName,
                id: v.id,
              })
            }
          })
          this.$refs.wldw.init(arr, 1, this.code, 2,'fixed')
        }
      })
    },
    outbc2Success(){
      this.$emit("success")
      this.dialogVisible = false
    },
    jumpNext(){
      this.$store.dispatch("commons/getCorp")
      if(this.selects.length != 0){
        let selectArr = []
        this.oldParams.map(v=>{
          this.selects.map(e=>{
            if(v.corpName == e.corpName){
              selectArr.push(v)
            }
          })
        })
        this.$refs.outbc2.init(selectArr)
        this.$refs.multipleTable.clearSelection()
      }else{
        this.$refs.outbc2.init(this.oldParams)
      }
    },
     //下一步
     handleAddSure1(){
      //保存
      // //console.log(this.oldList)
      // console.log(this.pageList)
      // console.log(this.oldList2);
      this.btnLoading = true
      this.oldList2.map(v =>{
        for (let i = 0; i < this.pageList.length; i++) {
          if (v.corpName == this.pageList[i].corpName) {
            v.subjectId = this.pageList[i].subjectId
            v.fzhsId = this.pageList[i].fzhsId
          }
        }
      })

      outBcSaveOne(this.oldList2).then(res=>{
        this.btnLoading = false
        if(res.data.msg == 'success'){
          this.$store.dispatch("commons/getCorp")
          this.$refs.outbc2.init(this.oldParams)
        }else{
          let arr = []
          this.oldList2.map(v=>{
            if(!v.subjectId){
              this.$refs.multipleTable.toggleRowSelection(v,true)
              arr.push({
                corpName: v.corpName,
                id: v.id,
              })
            }
          })
          this.$refs.wldw.init(arr, 1, this.code, 1,'fixed')
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.top{
  margin-bottom: 10px;
}
.bg-purple-light{
  float: right;
}
</style>