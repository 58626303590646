<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="批量修改税目" v-model="dialogVisible" width="25%" >

    <shuimu v-model:taxId="temp.taxId" @success="getRate"></shuimu>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <qzf-button :loading="loading" type="primary" @success="handleAddSure" size="small">确 定</qzf-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import shuimu from '@/components/ssflbm/shuimu.vue'
export default {
  data () {
    return {
      loading: false,
      dialogVisible: false,
      temp: {
        taxId: 0,
        rate:0
      },
    }
  },
  components: {
    shuimu
  },
  methods: {
    init(){
      this.dialogVisible = true
    },
    handleAddSure(){
      if(this.temp.subjectId == 0 || this.temp.itemId == 0){
        this.$qzfMessage("请选择", 1)
        return
      }
      this.$emit("success", this.temp)
      this.dialogVisible = false
     
    },
    getRate(val,val2){
      this.temp.rate = val2
    }
  }
}
</script>