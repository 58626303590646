import request from '@/utils/request'

// 发票费用列表
export function feeList(data) {
  return request({
    url: 'api/v2/fee/feeList',
    method: 'post',
    data
  })
}

// 新增费用
export function feeSave(data) {
  return request({
    url: 'api/v2/fee/saveFee',
    method: 'post',
    data
  })
}

// 删除费用
export function feeDele(data) {
  return request({
    url: 'api/v2/fee/delFee',
    method: 'post',
    data
  })
}

//导出费用
export function feeExport(data) {
  return request({
    url: 'api/v2/fee/feeExport',
    method: 'post',
    data
  })
}