<template>
  <div class="select-inventory-list-container">
    <div class="text-box">
      <span class="ellipsis">{{ itemName }}</span>
      <el-icon><ArrowDown /></el-icon>
    </div>
    <div ref="renderBoxRef" class="render-box" @click="renderSelect"></div>
  </div>
</template>
<script setup lang="jsx">
import {
  defineProps,
  computed,
  getCurrentInstance,
  ref,
  createApp,
  withModifiers,
} from "vue";
import { ArrowDown } from "@element-plus/icons-vue";
import { Select as AntdSelect } from "ant-design-vue";
const { proxy: vm } = getCurrentInstance();

const props = defineProps({
  type: {
    //是否使用服务
    type: Boolean,
    default: false,
  },
  inventoryId: {
    type: Number,
    default: 0,
  },
  itemName: {
    type: String,
    default: "",
  },
  inventoryName: {
    //默认新增存货名称
    type: String,
    default: "",
  },
  inventorySpec: {
    //默认新增存货型号
    type: String,
    default: "",
  },
  inventoryUnit: {
    //默认新增存货单位
    type: String,
    default: "",
  },
  inventoryType: {
    //存货还是服务  inventory service
    type: String,
    default: "",
  },
  borderNone: {
    type: Boolean,
    default: false,
  },
  spec: {
    type: String,
    default: "",
  },
  unit: {
    type: String,
    default: "",
  },
  disabledIn: {
    type: Boolean,
    default: false,
  },
  comId: {
    type: Number,
    default: 0,
  },
  from: {
    type: String,
    default: "",
  },
});

const addOptions = [
  {
    id: "add",
    name: "+新增存货",
  },
];
const inventorySelectRef = ref();
const renderBoxRef = ref();
const selectApp = ref();

const options = computed(() => {
  let inventorys = vm.$store.getters["commons/inventorys"];
  let options = [];
  if (inventorys.services && props.type) {
    options.push({
      type: "group",
      label: "服务",
      key: "service",
      options: inventorys.services.map((v) => ({
        value: v.id,
        label: v.name,
      })),
    });
  }
  options.unshift({
    type: "group",
    key: "addch",
    label: "新增存货", //不填 新增存货
    options: addOptions.map((v) => ({
      value: v.id,
      label: v.name,
    })),
  });
  if (inventorys.inventoryYcl) {
    options.push({
      type: "group",
      key: "ycl",
      label: "原材料",
      options: inventorys.inventoryYcl.map((v) => ({
        value: v.id,
        label:
          v.name +
          (v.coding ? "(" + v.coding + ")" : "") +
          (v.unit ? "(" + v.unit + ")" : ""),
        spec: v.spec,
        unit: v.unit,
        coding: v.coding,
      })),
    });
  }
  if (inventorys.inventoryKcsp) {
    options.push({
      type: "group",
      key: "kcsp",
      label: "库存商品",
      options: inventorys.inventoryKcsp.map((v) => ({
        value: v.id,
        label:
          v.name +
          (v.coding ? "(" + v.coding + ")" : "") +
          (v.unit ? "(" + v.unit + ")" : ""),
        spec: v.spec,
        unit: v.unit,
        coding: v.coding,
      })),
    });
  }

  if (inventorys.inventoryBcp) {
    options.push({
      type: "group",
      key: "bcp",
      label: "半成品",
      options: inventorys.inventoryBcp.map((v) => ({
        value: v.id,
        label:
          v.name +
          (v.coding ? "(" + v.coding + ")" : "") +
          (v.unit ? "(" + v.unit + ")" : ""),
        spec: v.spec,
        unit: v.unit,
        coding: v.coding,
      })),
    });
  }

  if (inventorys.inventoryBzw) {
    options.push({
      type: "group",
      key: "bzw",
      label: "包装物",
      options: inventorys.inventoryBzw.map((v) => ({
        value: v.id,
        label:
          v.name +
          (v.coding ? "(" + v.coding + ")" : "") +
          (v.unit ? "(" + v.unit + ")" : ""),
        spec: v.spec,
        unit: v.unit,
        coding: v.coding,
      })),
    });
  }
  if (inventorys.inventoryWtjgwz) {
    options.push({
      type: "group",
      key: "wtjgw",
      label: "委托加工物资",
      options: inventorys.inventoryWtjgwz.map((v) => ({
        value: v.id,
        label:
          v.name +
          (v.coding ? "(" + v.coding + ")" : "") +
          (v.unit ? "(" + v.unit + ")" : ""),
        spec: v.spec,
        unit: v.unit,
        coding: v.coding,
      })),
    });
  }
  if (inventorys.inventoryZzcl) {
    options.push({
      type: "group",
      key: "zzcl",
      label: "周转材料",
      options: inventorys.inventoryZzcl.map((v) => ({
        value: v.id,
        label:
          v.name +
          (v.coding ? "(" + v.coding + ")" : "") +
          (v.unit ? "(" + v.unit + ")" : ""),
        spec: v.spec,
        unit: v.unit,
        coding: v.coding,
      })),
    });
  }
  if (inventorys.inventoryXhxswzc) {
    options.push({
      type: "group",
      key: "xhxswzc",
      label: "消耗性生物资产",
      options: inventorys.inventoryXhxswzc.map((v) => ({
        value: v.id,
        label:
          v.name +
          (v.coding ? "(" + v.coding + ")" : "") +
          (v.unit ? "(" + v.unit + ")" : ""),
        spec: v.spec,
        unit: v.unit,
        coding: v.coding,
      })),
    });
  }
  if (inventorys.inventoryDzyhp) {
    options.push({
      type: "group",
      key: "dzyhp",
      label: "低值易耗品",
      options: inventorys.inventoryDzyhp.map((v) => ({
        value: v.id,
        label:
          v.name +
          (v.coding ? "(" + v.coding + ")" : "") +
          (v.unit ? "(" + v.unit + ")" : ""),
        spec: v.spec,
        unit: v.unit,
        coding: v.coding,
      })),
    });
  }
  if (inventorys.inventoryClcg) {
    options.push({
      type: "group",
      key: "clcg",
      label: "材料采购",
      options: inventorys.inventoryClcg.map((v) => ({
        value: v.id,
        label:
          v.name +
          (v.coding ? "(" + v.coding + ")" : "") +
          (v.unit ? "(" + v.unit + ")" : ""),
        spec: v.spec,
        unit: v.unit,
        coding: v.coding,
      })),
    });
  }
  if (inventorys.inventoryScxswzc) {
    options.push({
      type: "group",
      key: "scxscwz",
      label: "生产性生物资产",
      options: inventorys.inventoryScxswzc.map((v) => ({
        value: v.id,
        label:
          v.name +
          (v.coding ? "(" + v.coding + ")" : "") +
          (v.unit ? "(" + v.unit + ")" : ""),
        spec: v.spec,
        unit: v.unit,
        coding: v.coding,
      })),
    });
  }
  if (inventorys.inventoryGcwz) {
    options.push({
      type: "group",
      key: "gcwz",
      label: "工程物资",
      options: inventorys.inventoryGcwz.map((v) => ({
        value: v.id,
        label:
          v.name +
          (v.coding ? "(" + v.coding + ")" : "") +
          (v.unit ? "(" + v.unit + ")" : ""),
        spec: v.spec,
        unit: v.unit,
        coding: v.coding,
      })),
    });
  }
  if (inventorys.services && !props.type) {
    options.push({
      type: "group",
      key: "fw",
      label: "服务",
      options: inventorys.services.map((v) => ({
        value: v.id,
        label:
          v.name +
          (v.coding ? "(" + v.coding + ")" : "") +
          (v.unit ? "(" + v.unit + ")" : ""),
        spec: v.spec,
        unit: v.unit,
        coding: v.coding,
      })),
    });
  }
  return options;
});
const id = computed({
  get() {
    if (!props.inventoryId || props.inventoryId == "add") {
      return;
    }
    return { value: props.inventoryId, label: props.itemName };
  },
  set(val) {
    if (val.value == "add") {
      let data = {
        id: undefined,
        name: props.inventoryName,
        spec: props.inventorySpec,
        unit: props.inventoryUnit,
        type: 2,
      };
      vm.$emit("addInvenTory", data);
      vm.$emit("update:inventoryId", 0);
      vm.$emit("update:itemName", "");
      vm.$emit("update:inventoryType", "inventory");
      return;
    }
    vm.$emit("update:itemName", val.label);
    vm.$emit("update:inventoryId", val.value);
    if (val.value <= 11) {
      vm.$emit("update:inventoryType", "service");
    } else {
      vm.$emit("update:inventoryType", "inventory");
    }

    let info = {};
    options.value.map((v) => {
      v.options.map((z) => {
        if (z.value == val.value) {
          info = z;
        }
      });
    });
    vm.$emit("update:spec", info.spec);
    vm.$emit("update:unit", info.unit);
    vm.$emit("success", val.value);
  },
});

function emitFocus() {
  vm.$emit("focus");
}
function replaceText(item) {
  let text = item.label;
  if (text.indexOf(item.coding) > -1) {
    text = text.replaceAll("(" + item.coding + ")", "");
  }
  if (text.indexOf(item.unit) > -1) {
    text = text.replaceAll("(" + item.unit + ")", "");
  }
  return text;
}
function renderSelect() {
  selectApp.value = createApp({
    render() {
      return (
        <AntdSelect
          ref={inventorySelectRef}
          size="small"
          class={{ border_none: props.borderNone }}
          v-model:value={id.value}
          filterable
          show-search
          options={options.value}
          placeholder="请选择存货或服务"
          clearable
          autofocus
          defaultOpen
          label-in-value
          filter-option={filterOption}
          style={{ width: "100%" }}
          disabled={props.disabledIn}
          onFocus={emitFocus}
          onBlur={onBlur}
          onMousedown={withModifiers(() => {}, ["stop"])}
          onClick={withModifiers(() => {}, ["stop"])}
          v-slots={{
            option: (item) => {
              return (
                <>
                  {item.label == "+新增存货" ? (
                    <span style="color:red">{item.label}</span>
                  ) : (
                    <span>
                      {replaceText(item)}
                      {item.coding && (
                        <span style="color:var(--themeColor,#17a2b8)">
                          ({item.coding})
                        </span>
                      )}
                      {item.unit && (
                        <span style="color:var(--themeColor,#17a2b8)">
                          ({item.unit})
                        </span>
                      )}
                    </span>
                  )}
                </>
              );
            },
          }}
        ></AntdSelect>
      );
    },
  });

  selectApp.value.mount(renderBoxRef.value);
  setTimeout(() => {
    inventorySelectRef.value?.focus();
    // renderBoxRef.value?.querySelector('.el-select-v2__combobox-input').focus()
  }, 14);
}

function filterOption(input, option) {
  if (!input) return true;
  return option.label?.includes(input);
}

function onBlur() {
  selectApp.value.unmount();
}
</script>

<style lang="scss">
.el-vl__window {
  width: 430px !important;
}
</style>
<style lang="scss" scoped>
.select-inventory-list-container {
  position: relative;
  width: 200px;
  height: 24px;

  .text-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 12px;
    border: 1px solid #b9b9b9;

    & > span {
      flex: 1;
      width: 0;
    }
  }

  .render-box {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="css">
.ant-select-dropdown {
  z-index: 5000;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0 12px;
}
.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--themeColor, #17a2b8);
  font-weight: 700;
}
</style>
