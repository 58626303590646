<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="补充业务类型" v-model="dialogVisible" width="90%" >

    <div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="批量补充" name="批量补充"></el-tab-pane>
        <el-tab-pane label="自动补充" name="自动补充"></el-tab-pane>
      </el-tabs>
    </div>


    <div v-if="activeName == '自动补充'">

      <el-form ref="formRef" label-width="120px">
        <el-form-item label="业务分配">
          <el-radio-group @change="changeZdbcType" v-model="zdbc.ywlxStatus">
            <el-radio :label="0">自选业务类型</el-radio>
            <el-radio :label="1">创建下级科目</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="业务类型">
          <subject-list :width="136" v-if="zdbc.ywlxStatus == 0" v-model:subjectId="zdbc.subjectId" v-model:fzhsItemId="zdbc.fzhsId" :codes="$findCode(['code1606','code5001','code5051','code5052','code5053','code5301','code4901'])"></subject-list>
          <subject-list-all v-else v-model:subjectId="zdbc.subjectId" v-model:fzhsItemId="zdbc.fzhsId" :codes="$findCode(['code1606','code5001','code5051','code5052','code5053','code5301','code4901'])"></subject-list-all>
        </el-form-item>

        <el-form-item label="商品分类">
          <el-radio-group @change="changeZdbcType2" v-model="zdbc.type">
            <el-radio style="width:100%" :label="1">自选存货  (*手动选择存货补充)</el-radio>
            <el-radio style="width:100%" :label="2">匹配创建下级科目  (*按照开票项目和规格型号自动创建下级补充)</el-radio>
            <el-radio style="width:100%" :label="3">绑定已有科目  (*选择已有存货科目补充)</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="zdbc.type == 1" label="存货列表">
          <inventory-list v-model:inventoryId="zdbc.inventoryId"></inventory-list>
        </el-form-item>
        <el-form-item v-else-if="zdbc.type == 2" label="上级科目">
          <subject-list-all v-model:subjectId="zdbc.inventorySubjectId" :codes="$findCode(codes)"></subject-list-all>
        </el-form-item>
        <el-form-item v-else-if="zdbc.type == 3" label="对应科目">
          <subject-list v-model:subjectId="zdbc.inventorySubjectId" :codes="$findCode(codes)"></subject-list>
        </el-form-item>
      </el-form>
    </div>


    <div v-if="activeName == '批量补充'">
      <div class="top">
        <el-row>
          <el-col :span="18">
            <div class="grid-content bg-purple">
              <el-input style="width:150px" @keyup.enter.native="listFilter()" size="small" v-model="listQuery.corpName" placeholder="公司名"></el-input>
              <el-input style="width:100px" @keyup.enter.native="listFilter()" size="small" v-model="listQuery.kpxm" placeholder="开票项目"></el-input>
              <qzf-button @success="listFilter()" type="primary" size="small" style="margin-right: 10px;">搜索</qzf-button>
              <search @success="listFilter" @cancel="cancel">
                <el-form style="margin:11px 0px 0px 6px;width:320px" class="styleForm">
                  <el-form-item label="税率" :label-width="formLabelWidth">
                    <el-input style="width:174px;margin-left:5px" size="small" v-model="listQuery.taxRate" placeholder="税率"></el-input>
                  </el-form-item>
                  <el-form-item label="金额" :label-width="formLabelWidth">
                    <el-input style="width:80px;margin-left:5px;margin-right: 5px;" size="small" v-model="listQuery.firstShowAmount" placeholder="金额起"></el-input>
                    -
                    <el-input style="width:80px;margin-left: 5px;" size="small" v-model="listQuery.secondShowAmount" placeholder="金额止"></el-input>

                  </el-form-item>
                  <el-form-item label="单价" :label-width="formLabelWidth">
                    <el-input style="width:80px;margin-left:5px;margin-right: 5px;" size="small" v-model="listQuery.firstPrice" placeholder="单价起"></el-input>
                    -
                    <el-input style="width:80px;margin-left: 5px;" size="small" v-model="listQuery.SecondPrice" placeholder="单价止"></el-input>

                  </el-form-item>
                  <el-form-item label="规格型号" :label-width="formLabelWidth">
                    <!-- <el-input style="width:174px" size="small" v-model="listQuery.spec" placeholder="规格型号"></el-input> -->
                    <!-- <el-autocomplete
                      size="small"
                      class="inline-input"
                      v-model="listQuery.spec"
                      :fetch-suggestions="querySearch"
                      placeholder="请输入内容"
                      @select="handleSelect"
                    ></el-autocomplete> -->
                    <el-select
                      size="small"
                      v-model="listQuery.spec"
                      multiple
                      filterable
                      allow-create
                      default-first-option
                      placeholder="请选择">
                      <el-option
                        v-for="item in this.specList"
                        :key="item.value"
                        :label="item.value"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </search>
              <el-select
                style="width:140px"
                size="small"
                v-model="bcOptionSingle"
                @change="listFilter()"
                filterable
                placeholder="">
                <el-option
                  v-for="item in bcOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>

              <ssflbm :all="true" v-model:ssflbm="webListQuery.ssflbm" @success="listFilterSsflbm"></ssflbm>
            </div>

            <el-radio-group @change="listFilterQc()" v-model="qc">
              <el-radio :label="0">相同开票项目税率去重 (*同一个开票项目且税率相同只显示一个)</el-radio>
              <el-radio :label="1">相同开票项目型号去重 (*同一开票项目且型号相同只显示一个)</el-radio>
              <el-radio :label="2">全部(单个补充) (*所有明细全部显示)</el-radio>
            </el-radio-group>
            <!-- <span style="color: blue;margin-left: 20px;">*温馨提示：采用去重补充更便捷</span> -->
            <!-- <span v-if="qc == 0" style="color: var(--themeColor,#17a2b8);margin-left: 20px;">*温馨提示：同一个开票项目只显示一个</span>
            <span v-if="qc == 1" style="color: var(--themeColor,#17a2b8);margin-left: 20px;">*温馨提示：同一开票项目且型号相同只显示一个</span>
            <span v-if="qc == 2" style="color: var(--themeColor,#17a2b8);margin-left: 20px;">*温馨提示：所有明细全部显示</span> -->

          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-light">
              <qzf-button @success="plbc()" type="primary" size="small">批量补充业务类型</qzf-button>
              <qzf-button @success="plxgsm()" type="primary" size="small">批量修改税目</qzf-button>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-table
        border
        ref="multipleTable"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :height="contentStyleObj">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>


        <el-table-column align="center" type="selection" width="40" />

        <el-table-column align="left" label="公司" min-width="240" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="scope.row.corpName">{{scope.row.corpName}}</span>
            <span v-else>手动添加发票未知</span>
          </template>
        </el-table-column>

        <el-table-column align="left" label="开票项目" min-width="120" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="scope.row.remark">{{'('+scope.row.remark+')'}}</span>
            <span v-else>{{scope.row.remark}}</span>
            <!-- <span>{{scope.row.name}}{{ scope.row.remark?'('+scope.row.remark+')' : '' }}</span> -->
          </template>
        </el-table-column>

        <el-table-column align="left" label="规格型号" min-width="110">
          <template #default="scope">
            <span>{{scope.row.spec}}</span>
          </template>
        </el-table-column>
        <el-table-column align="left" label="单位" min-width="60">
          <template #default="scope">
            <span>{{ scope.row.unit }}</span>
          </template>
        </el-table-column>

        <el-table-column align="left" label="单价" min-width="90">
          <template #default="scope">
            <span>{{scope.row.price}}</span>
          </template>
        </el-table-column>
        <el-table-column align="left" label="金额" min-width="110" >
          <template #default="scope">
            <span>{{scope.row.showAmount}}</span>
          </template>
        </el-table-column>
        <el-table-column align="left" label="税率" width="60">
          <template #default="scope">
            <span>{{scope.row.taxRate}}</span>
          </template>
        </el-table-column>

        <el-table-column align="left" min-width="315" label="业务类型">
          <template #default="scope">
            <div class="subject-select-cell-box">
              <SubjectListNoDialog style="width:300px !important" v-model:subjectId="scope.row.subjectId" v-model:fzhsItemId="scope.row.fzhsId" v-model:subjectName="scope.row.subjectName" :codes="$findCode(['code1606','code5001','code5051','code5052','code5053','code5301','code4901'])"  v-model:status="status" :index="scope.$index" @addSubject="addSubjectFunc"></SubjectListNoDialog>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" min-width="315" label="货物或应税劳务、服务">
          <template #default="scope">
            <div class="subject-select-cell-box">
              <InventoryListNoDialog style="width:300px !important" v-model:status="status2" v-model:itemName="scope.row.itemName" :index="scope.$index" :inventoryName="scope.row.name" :inventorySpec="scope.row.spec" :inventoryUnit="scope.row.unit" v-model:inventoryId="scope.row.itemId" v-model:inventoryType="scope.row.itemType" :type="scope.row.ssflbm.length >= 2 && scope.row.ssflbm.slice(0,2) != '10'" @focus="() => getCurrentRow(scope.row)" @addInvenTory="addInvenToryFunc"></InventoryListNoDialog>
            </div>
          </template>
        </el-table-column>


        <el-table-column align="left" label="税目" min-width="240">
          <template #default="scope">
            <shuimu v-model:taxId="scope.row.taxId" @success="smSingleSuccess" @success2="getSingle(scope.row)" style="width:100%" :rate="scope.row.taxRate"></shuimu>
          </template>
        </el-table-column>

      </el-table>

      <div class="pagination" style="float:none">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="webListQuery.page" v-model:limit="webListQuery.limit" @pagination="pageChange" />
      </div>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">上一步</el-button>
        <qzf-button :loading="loading" type="primary" @success="handleAddSure" size="small">完成</qzf-button>
      </span>
    </template>
  </el-dialog>


  
  <!-- 批量补充弹框 -->
  <ywlx @success="ywlxSuccess" ref="ywlx"></ywlx>


  <!-- 批量补充税目弹框 -->
  <sm @success="smSuccess" ref="sm"></sm>

  <add-inventory @success="addSuccessInventory" ref="addInventoryRef" from="plbc"></add-inventory>
  <add-subject @success="addSuccessSubject" ref="addSubjectRef"></add-subject>

</template>
<script>
import { outInvoiceBcTwoList, outBcSaveTwo, outAutomaticBc } from "@/api/invoice.js"
import ywlx from "./ywlx.vue"
import sm from "./sm"
import shuimu from '@/components/ssflbm/shuimu.vue'
import ssflbm from '@/components/ssflbm/ssflbm.vue'
import SubjectListNoDialog from "./SubjectList.vue"
import InventoryListNoDialog from './InventoryList.vue'
import AddSubject from '@/components/subject/addSubject.vue'

export default {
  props: {
    
  },
  data () {
    return {
      upQc: 0,
      qc: 0,
      status: "true",
      status2: "true",
      zdbc: {
        type: 1,
        subjectId: 0,
        fzhsId: 0,
        inventoryId: 0,
        inventorySubjectId: 0,
        ywlxStatus:0
      },
      codes: ['code1285','code1403','code1405','code1406','code1408','code1411','code1412','code1421','code1605','code1621'],
      activeName: "批量补充",
      loading: false,
      webListQuery: {
        ssflbm: '',
        page: 1,
        limit: 20,
      },
      listQuery: {
        kpxm: '',
        corpName: '',
        spec: [],
        taxRate:"",
        firstShowAmount:'-100000000',
        secondShowAmount:'',
        firstPrice:'-100000000',
        SecondPrice: '',
      },
      bcOptionSingle:'',
      dialogVisible: false,
      list: [],
      pageList: [],
      total: 0,
      oldList: [],
      selects: [],
      formLabelWidth:'80px',
      taxRateChange:0,
      specList:[],
      bcOptions:[
        {
          value:'',
          label:'全部'
        },
        {
          value:'1',
          label:'只显示未补充完整'
        },
        {
          value:'2',
          label:'只显示已补充'
        }
      ],
      spssbm:undefined,
      contentStyleObj:{},
      currentRow: null,

    }
  },
  components: {
    sm,
    ssflbm,
    shuimu,
    ywlx,
    SubjectListNoDialog,
    InventoryListNoDialog,
    AddSubject,
  },
  created(){
    this.contentStyleObj= this.$getHeight(450)
  },
  methods: {
    addInvenToryFunc(data) {
      this.$refs.addInventoryRef.init(data)
    },
    addSubjectFunc({ data, codes }) {
      this.$refs.addSubjectRef.init(data, codes)
    },
    getCurrentRow(row) {
      this.currentRow = row
    },
    addSuccessSubject(e) {
      this.currentRow.subjectId = e.sign
      this.currentRow.subjectName = e.name3
    },
    addSuccessInventory(e){
      this.currentRow.itemId = e.id
      this.currentRow.itemName = e.name
    },
    pageChange(){
      let list = this.pageList
      this.total = list.length
      let s = (this.webListQuery.page - 1) * this.webListQuery.limit
      let e = this.webListQuery.page * this.webListQuery.limit
      this.list = list.slice(s, e)
    },
    //初始化
    init(params){
      this.zdbc.items = params //用于自动补充
      outInvoiceBcTwoList(params).then(res=>{
        if(res.data.msg == "success"){
          let list = res.data.data.list ? res.data.data.list : []
          this.oldList = list
          this.pageList = this.oldList
          
          // this.pageChange()
          
          this.dialogVisible = true
          list.map(v => {
            this.specList.push({value:v.spec})
          })
          const map = new Map()
          const newArr  = this.specList.filter(v => !map.has(v.value) && map.set(v.value,1) && v.value.trim())
          this.specList = newArr
          this.listFilter()
        }
      })
    },
    listFilterSsflbm(e){
      this.spssbm = e
      this.listFilter(e)
    },
    listFilterQc(){
      if(this.qc == 2){
        if(this.upQc == 0){//上次在相同项目去重上
          this.oldList.map(v=>{
            this.list.map(x=>{
              if(v.name == x.name){
                v.taxId?v.taxId:v.taxId = x.taxId
                v.taxRate?v.taxRate:v.taxRate = x.rate
                v.subjectId?v.subjectId:v.subjectId = x.subjectId
                v.fzhsId?v.fzhsId:v.fzhsId = x.fzhsId
                v.itemId?v.itemId:v.itemId = x.itemId
                v.itemType?v.itemType:v.itemType = x.itemType
              }
            })
          })
        } else if(this.upQc == 1){
          this.oldList.map(v=>{
            this.list.map(x=>{
              if(v.name == x.name && v.spec == x.spec){
                v.taxId?v.taxId:v.taxId = x.taxId
                v.taxRate?v.taxRate:v.taxRate = x.rate
                v.subjectId?v.subjectId:v.subjectId = x.subjectId
                v.fzhsId?v.fzhsId:v.fzhsId = x.fzhsId
                v.itemId?v.itemId:v.itemId = x.itemId
                v.itemType?v.itemType:v.itemType = x.itemType
              }
            })
          })
        }
      }
      this.upQc == this.qc
      this.$nextTick(function(){
        this.listFilter()
      })
    },
    //重置
    cancel(){
      this.listQuery= {
        kpxm: '',
        corpName: '',
        spec: [],
        taxRate:"",
        firstShowAmount:'-100000000',
        secondShowAmount:'',
        firstPrice:'-100000000',
        SecondPrice: '',
      }
      this.listFilter()
    },
    //过滤
    listFilter(e){
      let newArr2 = []
      //console.log(this.oldList)
      this.oldList.map(v => {
        this.listQuery.spec.map(vv => {
          newArr2 =  newArr2.concat(v.spec.match(vv))
        })
      })
      let newArr3 = newArr2.filter(n => n)
      //console.log(this.listQuery.secondShowAmount)
      let list = this.oldList.filter(v=>{
        //console.log( v.showAmount)
        return v.corpName.match(this.listQuery.corpName) && v.name.match(this.listQuery.kpxm) && (this.listQuery.spec == "" || (newArr3.indexOf(v.spec) > -1)) && (v.taxRate == this.listQuery.taxRate || this.listQuery.taxRate == "") 
        && (this.listQuery.secondShowAmount ==''?(this.listQuery.firstShowAmount <= v.showAmount):((this.listQuery.firstShowAmount <= v.showAmount) && (v.showAmount <= this.listQuery.secondShowAmount)))
        && (this.listQuery.SecondPrice ==''?(this.listQuery.firstPrice <= v.price):((this.listQuery.firstPrice <= v.price) && (v.price <= this.listQuery.SecondPrice)))
      })
      if(this.spssbm){
        list = list.filter(v=>{
          return !this.spssbm || this.spssbm == v.ssflbm.slice(0, 2)
        })
      }
      if(this.bcOptionSingle == '1'){
        list = list.filter(v=>{
          return v.subjectId == 0 || v.itemId == 0 || v.taxId == 0 
        })
      }else if(this.bcOptionSingle == '2'){
        list = list.filter(v=>{
          return v.subjectId != 0 && v.itemId != 0 && v.taxId != 0 
        })
      }else{
        list = list
      }
      //去重
      if(this.qc == 0){
        var q = []
        list.map(v=>{
          var s = true
          q.map(x=>{
            if(x.name == v.name && x.taxRate == v.taxRate){
              s = false
            }
          })
          if(s){
            q.push(v)
          }
        })
        list = q
        
      } else if(this.qc == 1){
        var q = []
        list.map(v=>{
          var s = true
          q.map(x=>{
            if(x.name == v.name && x.spec == v.spec){
              s = false
            }
          })
          if(s){
            q.push(v)
          }
        })
        list = q
      }
      //console.log(this.oldList)
      this.pageList = list
      this.pageChange()
    },
    handleSelectionChange(e){
      this.selects = e
    },
    plxgsm(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.$refs.sm.init()
    },
    smSuccess(e){
      if(!e){
        return
      }
      if(this.qc == 0){
        let arr = []
        this.oldList.map(v=>{
          this.selects.map(item=>{
            if(item.name == v.name && item.taxRate == v.taxRate){
              arr.push(v)
            }
          })
        })
        arr.map(v=>{
          v.taxId = e.taxId
          v.taxRate = e.rate
        })
      }else{
        this.selects.map(v=>{
          v.taxId = e.taxId
          v.taxRate = e.rate
        })
      }
    },
    smSingleSuccess(a,b){
      this.taxRateChange = b
    },
    getSingle(row){
      if(this.qc == 0){
        let arr = []
        this.oldList.map(v=>{
          if(v.name == row.name && v.taxRate == row.taxRate){
            arr.push(v)
          }
        })
        arr.map(e=>{
          e.taxId = row.taxId
          e.taxRate = this.taxRateChange
        })
      }else{
        row.taxRate = this.taxRateChange
      }
    },
    //批量创建公司
    plbc(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      var ssflbm = ''
      var status = true
      this.selects.map(v=>{
        let s = v.ssflbm.slice(0, 2)
        if(ssflbm == "" || ssflbm == s){
          ssflbm = s
        }else{
          status = false
        }
      })
      if(!status){
        this.$qzfMessage("提示：请先使用“筛选”功能区分“服务”和“货物”，再进行批量补充！", 1)
        return
      }
      //存货的 异常服务
      if(ssflbm == '10'){
        this.$refs.ywlx.init(false)
      }else{
        this.$refs.ywlx.init(true)
      }
    },
    ywlxSuccess(e){
      if(!e){
        return
      }
      this.selects.map(item=>{
        item.subjectId = e.subjectId
        item.fzhsId = e.fzhsId
        item.itemId = e.itemId
        item.itemType = e.itemType
        item.subjectName = e.subjectName
        item.itemName = e.itemName
      })
    },
    //完成
    handleAddSure(){
      //保存
      if(this.activeName == "批量补充"){
        if(this.qc == 0){//上次在相同项目去重上
          this.oldList.map(v=>{
            this.list.map(x=>{
              if(v.name == x.name && v.taxRate == x.taxRate){
                v.taxId = x.taxId
                // v.taxRate = x.rate
                v.subjectId = x.subjectId
                v.fzhsId = x.fzhsId
                v.itemId = x.itemId
                v.itemType = x.itemType
              }
            })
          })
        } else if(this.qc == 1){
          this.oldList.map(v=>{
            this.list.map(x=>{
              if(v.name == x.name && v.spec == x.spec){
                v.taxId = x.taxId
                v.taxRate = x.rate
                v.subjectId = x.subjectId
                v.fzhsId = x.fzhsId
                v.itemId = x.itemId
                v.itemType = x.itemType
              }
            })
          })
        }
        let status = false
        this.oldList.map(v=>{
          if(!v.taxId || !v.subjectId || !v.itemId ){
            status = true
            return
          }
        })
        if(status){
          this.$confirm('存在未补充完整的项目，是否继续保存？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.loading = true
            outBcSaveTwo(this.oldList).then(res=>{
              this.loading = false
              if(res.data.msg == "success"){
                this.$emit("success")
                this.dialogVisible = false
              }
            })
          });
        }else{
          this.loading = true
          outBcSaveTwo(this.oldList).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$emit("success")
              this.dialogVisible = false
            }
          })
        }
      } else if(this.activeName == "自动补充"){
        if(this.zdbc.subjectId == 0){
          this.$qzfMessage("请选择业务类型", 1)
          return
        }
        if(this.zdbc.type == 1 && !this.zdbc.inventoryId){
          this.$qzfMessage("请选择存货", 1)
          return
        }
        if((this.zdbc.type == 2 || this.zdbc.type == 3) && !this.zdbc.inventorySubjectId){
          this.$qzfMessage("请选择存货科目", 1)
          return
        }
        this.loading = true
        outAutomaticBc(this.zdbc).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            if(res.data.data.msg.match('进行中')){
              this.$qzfMessage(res.data.data.msg)
            }
            this.$emit("success")
            this.$store.dispatch("commons/getSubjects")
            this.$store.dispatch("commons/getInventory")
            this.$store.dispatch("commons/getBusinessType")
            this.$bus.emit('voucherUpdate')

            this.dialogVisible = false
          }
        })
      }
    },
    //自动补充
    changeZdbcType(){
      this.zdbc.inventorySubjectId = 0
      this.zdbc.subjectId = 0
      this.zdbc.fzhsId = 0
    },
    changeZdbcType2(){
      this.zdbc.inventorySubjectId = 0
    },
    // 
    querySearch(queryString, cb){
      var restaurants = this.specList;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
  }
}
</script>
<style lang="less" scoped>
.top{
  margin-bottom: 10px;
}
.bg-purple-light{
  float: right;
}
.subject-select-cell-box {
  display: flex;
}
</style>