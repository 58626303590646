<template>
  <!-- <el-tabs tab-position="left" style="height: 100%;" type="border-card" v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="进项" name="first">
      <invoice-income ref="income"></invoice-income>
    </el-tab-pane>
    <el-tab-pane label="销项" name="second">
      <invoice-output ref="output"></invoice-output>
    </el-tab-pane>
    <el-tab-pane label="费用" name="third">
      <invoice-fee ref="fee"></invoice-fee>
    </el-tab-pane>
  </el-tabs> -->
  <div :style="{ height: contentStyleObj }">
    <qzf-tabs v-model:value="value">
      <qzf-tabs-item label="进项" name="1" :cache="false">
        <invoice-income ref="income"></invoice-income>
      </qzf-tabs-item>
      <qzf-tabs-item label="销项" name="2" :cache="false">
        <invoice-output ref="output"></invoice-output>
      </qzf-tabs-item>
      <qzf-tabs-item label="费用" name="3" :cache="false">
        <invoice-fee ref="fee"></invoice-fee>
      </qzf-tabs-item>
      <qzf-tabs-item label="发票影像" name="4" :cache="false">
        <invoicePdf ref="invoicePdf"></invoicePdf>
      </qzf-tabs-item>
    </qzf-tabs>
  </div>
</template>

<script>
import invoiceIncome from "./income.vue";
import invoiceOutput from "./output.vue";
import invoiceFee from "./fee.vue";
import invoicePdf from "./invoicePdf.vue"
export default {
  name: "invoice",
  components: { invoiceIncome, invoiceOutput, invoiceFee ,invoicePdf },
  data() {
    return {
      value: "1",
      contentStyleObj: {}, //高度变化
    };
  },
  deactivated() {
    if (!this.$getCachedViews("invoice")) {
      //销毁 mitt bus
      this.$bus.off("invioceInUpdate");
      this.$bus.off("invioceOutUpdate");
      this.$bus.off("invioceFeeUpdate");
    }
  },
  created() {
    this.contentStyleObj = this.$getHeight(190);
    this.initBus();
  },
  methods: {
    initBus() {
      this.$bus.on("invioceInUpdate", (val) => {
        if (this.$refs.income) {
          this.$refs.income.getList();
        }
      });
      this.$bus.on("invioceOutUpdate", (val) => {
        if (this.$refs.output) {
          this.$refs.output.getList();
        }
      });
      this.$bus.on("invioceFeeUpdate", (val) => {
        if (this.$refs.fee) {
          this.$refs.fee.getList();
        }
      });
    },
  },
};
</script>

<style></style>
